import React from "react";
import Button from "../../../sharedComponents/Button";
import "./RemontManage.scss";

const RemoteManage = () => {
	return (
		<div className="lg:max-w-[960px] xl:max-w-[1320px]">
			<div className="lg:text-center">
				<div className="mb-8px">
					<p className="subtitle font-semibold text-[#3798D4]">
						Acses Manager
					</p>
				</div>
				<div className="mb-[43px] justify-center lg:flex">
					<h2 className="lg:w-[585px] xl:w-[767px]">
						Gestion à distance avec notre logiciel Acses
						Manager
					</h2>
				</div>
				<div className="hidden gap-y-[20px] md:mb-[60px] md:block lg:mb-[73px] lg:flex lg:flex-col lg:items-center xl:mb-[102px]">
					<p className=" mb-[5px]lg:w-[475px] font-normal text-[#506F83] md:font-medium lg:font-normal xl:w-[869px]">
						{" "}
						Acses vous offre une interface unique qui vous
						permet de piloter l’ensemble des fonctionnalités
						à distance. Les données de votre consigne et de
						l’interface sont actualisées{" "}
						<span className="lg:font-semibold">
							en temps réel
						</span>
						, pas d’erreur possible !
					</p>
					<p className=" mb-[5px]lg:w-[475px] font-normal text-[#506F83] md:font-medium lg:font-normal xl:w-[869px]">
						Le nombre d'utilisateurs et d'échanges de clés
						sont absolument{" "}
						<span className="lg:font-semibold">
							illimités.
						</span>
					</p>
					<p className=" mb-[5px] font-normal text-[#506F83] md:font-medium lg:w-[952px] lg:font-normal xl:w-[869px]">
						Nous vous proposons différents modes de
						connexion :{" "}
						<span className="lg:font-semibold">
							{" "}
							RJ45 / Wifi / 4G.
						</span>
					</p>
				</div>
			</div>
			<div className="mb-[40px] flex flex-wrap justify-center gap-[40px] md:mb-[60px] lg:gap-[32px]">
				<div className="tinyShadow relative flex h-[448px] w-[288px] flex-col items-center justify-end gap-[67px] overflow-hidden p-[32px] md:h-[320px] md:w-[696px] md:flex-row lg:h-[448px] lg:w-[624px] lg:flex-col lg:gap-[32px] xl:mb-0 xl:w-[869px] xl:flex-row-reverse xl:p-[60px]">
					<div className="blurEffect absolute -top-[40px] -right-[60px] md:right-[33.33%] "></div>
					<div className="relative -left-[50px] w-[270px] md:hidden">
						<img
							className="z-[10] scale-[.93]"
							src="assets/images/howItWorks/laptop-phone-mobile.webp"
							alt="logiciel acses manager"
							width={299}
							height={254}
							loading="lazy"
						/>
					</div>
					<div className="hidden md:relative md:-left-[20px] md:flex md:justify-center xl:top-[25px] xl:left-[25px] xl:w-2/3">
						<img
							className="w-[1000px] lg:w-auto"
							src="assets/images/howItWorks/laptop-phone-tablette.webp"
							alt="logiciel acses manager"
							width={712}
							height={518}
							loading="lazy"
						/>
					</div>
					<div className="flex h-full flex-col justify-end xl:w-1/3">
						<p className="subtitle font-bold text-[#3798D4] xl:mb-[67px] xl:text-left">
							Interface responsive
						</p>
						<div className="xl:flex">
							<p className="small-text font-medium !leading-[20px] text-[#506F83] ">
								Une interface simple d’utilisation
								disponible sur ordinateur, tablette &
								smartphone
							</p>
						</div>
					</div>
				</div>
				<div className="tinyShadow relative flex h-[448px] w-[288px] flex-col justify-end gap-[30px] overflow-hidden p-[32px] md:w-[328px] lg:w-[296px] xl:mb-0 xl:w-[410px] xl:p-[60px]">
					<div className="blurEffect absolute -top-[40px] -left-[60px] "></div>
					<div className="relative -right-[15px] w-full xl:-right-[46px] xl:top-[40px] ">
						<img
							className="z-[10] w-full scale-[1.2] xl:scale-[1.1]"
							src="assets/images/howItWorks/interface-snap-mobile.webp"
							alt="tableau de bord pour gérer vos clés"
							width={273}
							height={270}
							loading="lazy"
						/>
					</div>
					{/* <div className="hidden md:flex justify-center xl:relative xl:-bottom-[25px]">
						<img
							className="xl:w-[428px] "
							src="assets/images/howItWorks/interface-snap-tablette.svg"
							alt="dashboard"
						/>
					</div> */}
					<div>
						<p className="subtitle font-bold text-[#3798D4]">
							Dashboard
						</p>
						<p className="small-text font-medium !leading-[20px] text-[#506F83] ">
							Un tableau de bord pour visualiser rapidement
							toute votre activité
						</p>
					</div>
				</div>
				<div className="tinyShadow relative flex h-[448px] w-[288px] flex-col justify-end gap-[32px] overflow-hidden p-[32px] md:w-[328px] lg:w-[296px] xl:w-[418px] xl:p-[60px]">
					<div className="blurEffect absolute -top-[40px] -right-[60px]"></div>

					<div className="relative -right-[43px] flex justify-center  md:-right-[63px] xl:-right-[93px] xl:top-[30px]">
						<img
							className="z-[20] xl:scale-[1.2]"
							src="assets/images/howItWorks/intuitif.svg"
							alt="gestion facile et à distance de vos casiers "
							width={206}
							height={234}
							loading="lazy"
						/>
					</div>
					<div>
						<p className="subtitle font-bold text-[#3798D4]">
							Intuitif
						</p>
						<p className="small-text font-medium !leading-[20px] text-[#506F83] ">
							Une gestion facile de vos casiers : attribuez
							le casier que vous souhaitez à un objet donné
						</p>
					</div>
				</div>
				<div className="tinyShadow relative flex h-[448px] w-[288px] flex-col justify-end gap-[42px] overflow-hidden p-[32px] py-[36px] md:w-[328px] lg:w-[296px] xl:w-[418px] xl:p-[60px] ">
					<div className="blurEffect absolute -top-[40px] -left-[60px] "></div>

					<div className="relative -left-[15px] mb-[42px] xl:-left-[5px]  xl:top-[60px]">
						<img
							className="scale-[1.2] xl:scale-[1]"
							src="assets/images/howItWorks/roles-mobile.png"
							alt="casiers personnalisables aux couleurs de votre entreprise"
							width={303}
							height={211}
							loading="lazy"
						/>
					</div>
					{/* <div className="hidden md:flex relative -left-[30px] xl:-left-[110px] -bottom-[36px] w-[647px]">
						<img
							src="assets/images/howItWorks/roles-tablette.svg"
							alt=""
						/>
					</div> */}
					<div>
						<p className="subtitle font-bold text-[#3798D4]">
							Personnalisable
						</p>
						<p className="small-text font-medium !leading-[20px] text-[#506F83]">
							Une gestion avancée des rôles utilisateurs.
							Ajoutez, modifiez ou supprimez les droits d'un
							collaborateur en toute simplicité.
						</p>
					</div>
				</div>
				<div className="tinyShadow relative flex h-[448px] w-[288px] flex-col justify-end  overflow-hidden p-[32px] md:w-[328px] lg:w-[296px] xl:w-[410px] xl:p-[60px]">
					<div className="blurEffect absolute -top-[40px] -right-[60px] "></div>
					<div className="relative -right-[30px] mb-[85px] flex w-full justify-center md:-right-[42px] xl:-right-[58px]">
						<img
							className="z-[10] scale-[1.2]"
							src="assets/images/howItWorks/tracking-mobile.png"
							alt="traçabilité des clés via notre logiciel Acses Manager"
							width={250}
							height={140}
							loading="lazy"
						/>
					</div>

					<div>
						<p className="subtitle font-bold text-[#3798D4]">
							Traçabilité des mouvements
						</p>
						<p className="small-text font-medium !leading-[20px] text-[#506F83]">
							Un historique consultable à tout moment
						</p>
					</div>
				</div>
			</div>
			<a
				href="https://www.youtube.com/channel/UCDIjziFIowFLigsEd7F-NPw"
				target="_blank"
				rel="noopener noreferrer"
				className="flex items-center justify-center xl:col-span-6 "
			>
				<Button
					text="Découvrir notre interface de gestion en vidéos"
					idBtn="bouton-ccm-interface-video"
				/>
			</a>
		</div>
	);
};

export default RemoteManage;
