import React from "react";
import { v4 as uuidv4 } from "uuid";

const pHero = [
	{
		id: uuidv4(),
		text: (
			<>
				<p class="mb-[16px]">
					Vous souhaitez proposer une expérience unique à
					vos clients ? Une borne coupe-file ?
				</p>
				<p class="mb-[16px]">
					La gestion d’une flotte de véhicules est un défi
					quotidien qui peut être coûteux et chronophage.
					Au-delà de ces aspects, une gestion fluide
					constitue également une source de satisfaction
					client et de sérénité pour vos collaborateurs.
				</p>
				<p>
					Avec nos consignes connectées, démarquez-vous et
					offrez une solution innovante à vos clients et à
					vos équipes.
				</p>
			</>
		),
	},
];

const heroImgs = {
	mobile:
		"assets/images/activitySector/concessions-auto/header-2.webp",
	tablette:
		"assets/images/activitySector/concessions-auto/header-2.webp",
	desktop:
		"assets/images/activitySector/concessions-auto/header-2.webp",
	desktopLarge:
		"assets/images/activitySector/concessions-auto/header-2.webp",
};

const grayBlockImg = {
	mobile:
		"assets/images/activitySector/concessions-auto/mozaique-mobile-tablette.webp",
	tablette:
		"assets/images/activitySector/concessions-auto/mozaique-mobile-tablette.webp",
	desktop:
		"assets/images/activitySector/concessions-auto/mozaique-desktop.webp",
	desktopLarge:
		"assets/images/activitySector/concessions-auto/mozaique-desktop.webp",
};

const grayBlockP = {
	pBold:
		"Accessible à tout moment, nos consignes à clés sécurisées, permettent à vos clients de récupérer et de déposer en toute simplicité les clés de leur voiture ou d’un véhicule de courtoisie. Fini les files d'attente et les horaires contraignants !",
	pTag: [
		<p>
			Notre solution intelligente accompagne également vos
			équipes dans leur quotidien. Leur temps est ainsi
			optimisé au profit de tâches à forte valeur ajoutée.
		</p>,
	],
};

const managementContent = [
	// Put the same imgAlt of each object for all kinds of Image's device
	{
		id: uuidv4(),
		imgAlt: "concessionnaire automobile",
		imgSrcMobile:
			"assets/images/activitySector/concessions-auto/seller-desktop-large.jpg",
		imgSrcTablette:
			"assets/images/activitySector/concessions-auto/seller-desktop-large.jpg",
		imgSrcDesktop:
			"assets/images/activitySector/concessions-auto/seller-desktop-large.webp",
		imgSrcDesktopLarge:
			"assets/images/activitySector/concessions-auto/seller-desktop-large.webp",
		title: "Une gestion quotidienne facilitée",
		textContent: [
			<p>
				Grâce à notre logiciel disponible sur desktop,
				tablette et mobile, chef de centre et collaborateurs
				peuvent facilement prendre la main sur les
				réservations et les annulations 24/24h, 7/7J.
			</p>,
		],
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
	{
		id: uuidv4(),
		imgAlt:
			"armoires à clés sécurisées pour échange de clés",
		imgSrcMobile:
			"assets/images/activitySector/concessions-auto/interface-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/concessions-auto/interface-mobile-tablette.png",
		imgSrcDesktop:
		"assets/images/activitySector/concessions-auto/interface-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/concessions-auto/interface-desktop-large.jpg",
		title: "Une traçabilité de vos clés",
		textContent: [
			<p>
				Notre système logiciel & consigne connectée vous
				permet de tracer les clés en temps réel.
				Rationalisez le suivi de vos clés pour fluidifier
				les échanges et maîtriser votre planning !
			</p>,
		],
		reverse: true,
		widthBlock: "md:w-[452px]",
	},
	{
		id: uuidv4(),
		imgAlt:
			" gestion à distance de vos clés via un logiciel Acses",
		imgSrcMobile:
			"assets/images/activitySector/concessions-auto/api-locker-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/concessions-auto/api-locker-mobile-tablette.png",
		imgSrcDesktop:
			"assets/images/activitySector/concessions-auto/api-locker-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/concessions-auto/api-locker-desktop-large.jpg",
		title: "Une intégration logicielle à votre système",
		textContent: (
			<>
				<p className="mb-[10px]">
					Grâce à notre API, connectez facilement vos
					casiers connectés à votre système de réservation.
					Un chef de projet technique vous accompagne dans
					l’implémentation de notre solution avec vos outils
					existants.
				</p>
			</>
		),
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
];

export {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
};
