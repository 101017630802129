import React from "react";

const Articles = () => {
	return (
		<div className="xl:ml-auto">
			{" "}
			<div className="px-[36px] lg:px-0">
				<h1 className="mb-[48px]">Mentions légales</h1>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="preambule" className="mb-[16px]">
						Préambule
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							{" "}
							La plateforme de réservation de consigne
							intelligente ACSES, accessible par le réseau
							Internet, à l’adresse du site internet
							www.acses.io (ci-après le « Plateforme ») est
							un outil mis à disposition par la société
							ACSES, Société par actions simplifiée au
							capital de 5.000 euros, immatriculée au RCS de
							Paris sous le numéro 824 589 949, dont le
							siège social se situe au 10 quai de la Borde
							91130 Ris-Orangis (ci-après « ACSES »).
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Le Site est hébergé par la société OVH SAS au
							capital de 10.000.000 euros, immatriculée au
							RCS de Roubaix sous le numéro 424 761 419 et
							dont le siège social se situe sis 2 rue
							Kellermann 59100 Roubaix.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="objet" className="mb-[16px]">
						Objet
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							La Plateforme permet à tout utilisateur de
							réserver en ligne ou au travers de
							l’Application ou directement sur une borne de
							réservation, une consigne ACSES lui permettant
							de stocker ses propres clés afin de les mettre
							notamment à disposition d’un tiers (ci-après
							le « Tiers »), et ce dans tout lieu équipé
							d’une consigne ACSES (ci-après les « Services
							»). Par la souscription aux Services,
							l’Utilisateur reconnaît avoir pris
							connaissance des règles d’utilisation et de
							fonctionnement du Site et s’y inscrire en
							connaissance de cause. Il adhère également aux
							principes et objectifs poursuivis par ACSES.
							Par la souscription aux Services,
							l’Utilisateur reconnait avoir également pris
							connaissance des règles d’utilisation et de
							fonctionnement de la Plateforme accessible ici
							(ci-après les « CGU »).
						</p>
					</div>
				</div>

				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="definitions" className="mb-[16px]">
						Définitions
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							Les termes définis ci-dessous commençant par
							des majuscules et utilisés au singulier comme
							au pluriel, auront la signification suivante :
						</p>
						<p className="mb-[16px] lg:font-normal">
							« Offre » : désigne leprix à payer par
							l’Utilisateur en contrepartie du bénéfice des
							Services du Site. Il peut bénéficier d’un
							tarif calculé à partir de la durée de sa
							réservation ou d’abonnements mensuels et
							annuels qui lui permettent de réaliser
							plusieurs réservations pendant la période
							sélectionnées «
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Utilisateur » : désigne toute personne tant
							morale que physique âgée de 18 ans ou plus et
							disposant de la capacité juridique ayant
							souscrit à une Offre aux fins d’utiliser les
							Services au travers du Site. Si l’Utilisateur
							représente une personne morale, l’Utilisateur
							déclare détenir l’intégralité des
							autorisations et des pouvoirs nécessaires pour
							accepter les CGV au nom et pour le compte de
							la société, de l’association ou de l’entité au
							nom de laquelle il utilise les Services, si
							l’Utilisateur représente une personne mineure,
							l’Utilisateur reconnait rester et être seul
							responsabilité de tout acte, fait de ce
							mineure en lien avec l’usage des Services et
							de la Plateforme par cette personne mineure
							dont l’Utilisateur est intégralement
							responsable.{" "}
						</p>
						<p className="mb-[16px] lg:font-normal">
							« Tiers » : désigne toute personne tant morale
							que physique qui dépose ou retire des clés à
							l’invitation de l’Utilisateur.
						</p>
						<p className="mb-[16px] lg:font-normal">
							« Application » : désigne l’interface
							téléchargeable permettant à tout Utilisateur
							d’accéder sur différents Supports à la
							Plateforme et à l’ensemble des Services.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							« Compte » : désigne l’interface dans laquelle
							sont regroupés l’ensemble des données de
							l’Utilisateur, à savoir notamment les données
							à caractère personnel ainsi que les Offres
							choisies, accessible via les identifiants de
							l’Utilisateur
						</p>
						<p className="mb-[16px] lg:font-normal">
							«Consignes » : désignent les dispositifs
							matériels et logiciels de réservation
							constitués d’un écran tactile situés dans
							chaque établissement partenaire de ACSES
							permettant aux Utilisateurs d’accéder et
							d’utiliser les Services, et notamment de
							souscrire à une Offre, effectuer un dépôt ou
							un retrait de clés.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							« Services » : désigne l’ensemble des actions
							que l’Utilisateur peut réaliser grâce aux
							fonctionnalités de la Plateforme à partir des
							Supports ou des Consignes selon l’Offre
							souscrite et lesquels sont décrits à l’adresse
							URL suivante :www.acses.io
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							« Supports »: désigne tout dispositif de
							l’Utilisateur doté d’un navigateur Web
							compatible HTML5 et connecté à Internet et qui
							est compatible avec la Plateforme et les
							Services, notamment :{" "}
						</p>
						<p className="mb-[16px] lg:font-normal">
							les smartphones, tablettes tactiles, PDA,
							terminaux mobiles, équipés des systèmes
							d’exploitation iOS ou Android les ordinateurs
							équipés des systèmes d’exploitation et des
							logiciels suivants Windows 8 ou OS « Site
							Internet » ou « Site » : désigne tous les
							éléments et contenus accessibles à l’adresse
							URL suivante : www.acses.io à partir duquel
							tout internaute peut consulter les Services et
							l’Utilisateur peut accéder aux Services.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="objet2" className="mb-[16px]">
						Objet
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							Les présentes Conditions Générales de Vente
							(ci-après « CGV ») ont pour objet de définir
							les conditions dans lesquelles ACSES fournit
							les Services et consent aux Utilisateurs un
							droit d’utilisation personnel, non exclusif et
							non transférable de la Plateforme et des
							Bornes de réservation, le tout dans le cadre
							d’une obligation de moyens et sous réserve du
							respect par les Utilisateurs des CGV et des
							CGU.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="acceptation-et-modifications-des-cgv"
						className="mb-[16px]"
					>
						Acceptation et modifications des CGV
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							La souscription d’une Offre emporte l’adhésion
							pleine et entière aux présentes CGV, aux CGU
							et à la Politique de confidentialité;
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Ainsi, l’Utilisateur reconnait avoir lu,
							compris et accepté les présentes CGV, les CGU
							et la Politique de confidentialité{" "}
						</p>
						<p className="mb-[16px] lg:font-normal">
							ACSES se réserve le droit de modifier, à tout
							moment, les présentes CGV, ce que
							l’Utilisateur accepte.{" "}
						</p>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur devra régulièrement s’informer
							de la version des CGV à jour.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Il est précisé en particulier que lors de la
							reconduction d’une Offre, toute nouvelle
							version des CGV en vigueur à cette date
							s’appliquera pour la Période Reconduite.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="duree-entree-en-vigeur"
						className="mb-[16px]"
					>
						Durée - Entrée en vigueur
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							Les CGV prennent effet à compter de leur
							acceptation par ACSES qui se formalise par
							l’envoi de l’Email de Confirmation de
							souscription de l’Offre (ci-après la « Prise
							d’Effet »).
						</p>
						<p className="mb-[16px] lg:font-normal">
							Les CGV sont conclus pour la durée de l’Offre
							(ci-après la « Période Initiale »), sauf en
							cas de résiliation anticipée de l’Offre dans
							les conditions de l’article « Suspension /
							Résiliation ».
						</p>
						<p className="mb-[16px] lg:font-normal">
							A l’issue de la Période Initiale, l’Offre
							pourra être reconduite.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="description-des-services"
						className="mb-[16px]"
					>
						Description des services
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							Au travers de la Plateforme, ACSES met à
							disposition de tout Utilisateur les Services.
						</p>
						<p className="mb-[16px] lg:font-normal">
							Les Services consistent ; après souscription
							d’une Offre :
						</p>
						<p className="mb-[16px] lg:font-normal">
							à la réservation d’un casier d’une Consigne
							permettant le dépôt et le retrait de clés par
							saisie d’un mot de passe de 2 lettres et 4
							chiffres générés par ACSES et communiqué à
							l’Utilisateur (ci-après le « Mot de passe »).
							L’utilisateur doit partager son Mot de Passe
							et les détails associés à sa réservation
							(adresses de la consigne, horaires d’accès) au
							Tiers qui pourra récupérer les clés de
							l’Utilisateur en renseignant le Mot de passe
							via l’écran tactile d’une Consigne dans le
							temps imparti fonction de la durée de l’Offre
							souscrit par l’Utilisateur. Pendant toute la
							durée de l’Offre l’Utilisateur bénéficie d’un
							nombre illimité d’ouverture de son casier.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="fourniture-des-services"
						className="mb-[16px]"
					>
						Fournitures des services
					</h2>
					<div className="mb-[32px] font-medium">
						<h3 className="mb-[16px]">
							Fournitures des services
						</h3>
						<p className="mb-[16px] lg:font-normal">
							La fourniture des Services par ACSES et le
							respect de ses obligations contractuelles sont
							subordonnés au respect par l’Utilisateur des
							CGU et des CGV.
						</p>
						<p className="mb-[16px] lg:font-normal">
							ACSES ne fournit notamment pas les Supports,
							les connexions aux réseaux de
							télécommunication qui sont nécessaires pour
							accéder aux Services ni toute autre ressource
							et/ou matériel nécessaire, à l’exception des
							Consignes.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							L’Utilisateur reconnaît ainsi qu’il dispose de
							tous les moyens et les ressources nécessaires
							pour accéder et utiliser les Services.
						</p>
					</div>
					<div className="mb-[32px] font-medium">
						<h3 className="mb-[16px]">Disponibilité</h3>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur reconnaît et accepte que les
							caractéristiques techniques du réseau Internet
							ne permettent pas d’assurer un accès ou un
							fonctionnement de la Plateforme sans
							dysfonctionnements, ni discontinuité ni perte
							de Fichiers.
						</p>
						<p className="mb-[16px] lg:font-normal">
							De plus, l’Utilisateur est informé, et
							accepte, que ACSES puisse interrompre,
							ponctuellement, l’accès de la Plateforme
							notamment pour des raisons de maintenance.
						</p>
					</div>
					<div className="mb-[32px] font-medium">
						<h3 className="mb-[16px]">Sécurité</h3>
						<p className="mb-[16px] lg:font-normal">
							ACSES s’efforce de mettre en œuvre un niveau
							de sécurité conforme aux règles de l’art.
						</p>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur reconnaît être informé qu’en
							l’état de la technique, en particulier
							concernant le réseau Internet, il est
							impossible de garantir entre autres : (i)
							l’absence d’intrusion non autorisée dans la
							Plateforme (ii) l’absence de destruction ou
							d’altération des Fichiers et données
							personnelles de l’Utilisateur (iii) l’absence
							d’utilisation malveillante par tout personne
							qui peut altérer, détruire et/ou modifier
							et/ou télécharger le contenu de la Plateforme.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							L’Utilisateur veille à ce que les mesures de
							sécurité et de comportement prévues dans les
							CGV et les CGU soient respectées.
							L’Utilisateur s’engage à signaler tout fait ou
							évènement qui serait susceptible de porter
							atteinte à la sécurité de la Plateforme et/ou
							des Consignes.
						</p>
					</div>
					<div className="mb-[32px] font-medium">
						<h3 className="mb-[16px]">
							Facteurs extérieurs
						</h3>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur reconnaît que des facteurs et
							évènement extérieurs, notamment ceux liés au
							réseau Internet ainsi que ceux affectant les
							fournisseurs de ACSES, ont une nécessaire
							incidence notamment sur la disponibilité et la
							sécurité de la Plateforme et des Consignes.
						</p>
					</div>
					<div className="mb-[32px] font-medium">
						<h3 className="mb-[16px]">
							Maintenance de la plateforme
						</h3>
						<p className="mb-[16px] lg:font-normal">
							ACSES ou tout prestataire désigné par ACSES
							assurera des opérations de maintenance de la
							Plateforme et de son environnement. Dans la
							mesure du possible, ACSES informera
							préalablement les Utilisateurs de la conduite
							de ces opérations de maintenance par le biais
							d’un email et/ou d’un affichage sur la
							Plateforme.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Il est entendu que la responsabilité de ACSES
							ne pourra être engagée pour tout
							ralentissement de la Plateforme et/ou
							indisponibilité partielle ou complète de la
							Plateforme et/ou des Consignes résultant de
							ces opérations de maintenance.
						</p>
					</div>
					<div className="mb-[32px] font-medium">
						<h3 className="mb-[16px]">
							Évolutions des services
						</h3>
						<p className="mb-[16px] lg:font-normal">
							ACSES se réserve le droit de faire évoluer ses
							Services ainsi que la Plateforme, notamment
							pas la mise à disposition de nouvelles
							fonctionnalités ou par la modification et/ou
							la suppression de fonctionnalités à ce jour
							proposées aux Utilisateurs.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="souscription-aux-services"
						className="mb-[16px]"
					>
						Souscription aux services
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							Pour accéder aux Services, l’Utilisateur devra
							suivre les étapes suivantes :
						</p>
						<p className="mb-[16px] lg:font-normal">
							1. Création du Compte : l’Utilisateur doit
							créer un Compte personnel et à cette fin,
							renseigner le formulaire d’inscription
							accessible à l’adresse suivante : www.acses.io
						</p>
						<p className="mb-[16px] lg:font-normal">
							Lors de la création du Compte, l’Utilisateur
							devra créer des identifiants qui sont
							constitués d’un email et d’un code d’accès
							(ci-après les « Identifiants »). Ces
							Identifiants sont une mesure de protection du
							Compte. Ces Identifiants sont, en conséquence,
							strictement confidentiels et il est de la
							seule responsabilité de l’Utilisateur d’en
							assurer leur confidentialité. L’email
							renseigné sera celui qu’utilisera ACSES pour
							communiquer avec l’Utilisateur.
						</p>
						<p className="mb-[16px] lg:font-normal">
							2. Souscrire à une Offre en choisissant une
							Offre via la Plateforme sur la page de
							réservation des Services. L’Utilisateur est
							habilité à utiliser les Services uniquement
							pendant la durée de l’Offre souscrit tel que
							défini à l’article « Durée » et à conditions
							de respecter les CGV et les CGU.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							3. Paiement de l’Offre choisi : l’Utilisateur,
							après avoir pu vérifier l’ensemble des
							informations relatives à l’Offre choisi,
							effectue le paiement dans les conditions et
							modalités de paiement prévues à la rubrique «
							Offre » et dans le respect de l’article «
							Paiement » de présentes CGV{" "}
						</p>
						<p className="mb-[16px] lg:font-normal">
							4. Réception d’un email de confirmation de la
							souscription à l’Offre aux Services choisis
							(ci-après l’« Email de Confirmation») à
							l’adresse mail communiquée sur la Plateforme
							lors de la création du Compte.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="engagement-au-titre-de-l'utilisation-des-services"
						className="mb-[16px]"
					>
						Engagement au titre de l’utilisation des
						services
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							L’ Utilisateur reconnait au titre des
							présentes CGV notamment :
						</p>
						<p className="mb-[16px] lg:font-normal">
							– utiliser les Services conformément aux
							règles énoncées dans les CGU, les CGV, ainsi
							que dans le cadre des dispositions légales en
							vigueur ; être propriétaire des clés ou à
							défaut disposer d’un droit d’usage sur les
							clés, avec toute autorité pour les déposer
							dans une consigne ACSES.
						</p>
						<p className="mb-[16px] lg:font-normal">
							– indemniser ACSES de toutes les conséquences
							financières relatives à une action,
							réclamation, demande ou instance d’un tiers en
							lien avec un manquement aux obligations de
							l’Utilisateur dans le cadre notamment des CGV
							et des CGU ;
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="engagement-au-titre-de-l'utilisation-des-services"
						className="mb-[16px]"
					>
						Engagement au titre de l’utilisation des
						services
					</h2>
					<div className="font-medium">
						<h3 className="mb-[16px]">Offres</h3>
						<p className="mb-[16px] lg:font-normal">
							Les prix sont indiqués en euros. Ils
							comprennent la taxe sur la valeur ajoutée
							(TVA) applicable au jour de la souscription de
							l’Offre.
						</p>
						<p className="mb-[16px] lg:font-normal">
							-Le montant de l’offre à la durée se calcule
							sur la base du nombre de jours réservés. -Le
							montant de l’abonnement mensuel se calcul sur
							la base d’un forfait. Un mois correspond à 30
							jours glissants. L’abonnement se renouvelle
							automatiquement à la date anniversaire sauf si
							l’Utilisateur demande la résiliation de son
							abonnement a minima 10 jours avant la date de
							prélèvement. Toute résiliation d’abonnement
							entraine automatiquement la suppression de
							l’ensemble des réservations ayant cours
							au-delà de la date de fin d’abonnement. -Le
							montant de l’abonnement annuel se calcul sur
							la base d’un forfait payable intégralement à
							la souscription. Un an d’abonnement correspond
							à 365 jours glissants
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Modalités de paiement
						</h3>
						<p className="mb-[16px] lg:font-normal">
							Le paiement se fera exclusivement en euros par
							le module de paiement électronique tiers
							Cyberplus Paiement fourni par la société
							Banque Populaire., société de droit français.
							En utilisant le système de paiement
							électronique Cyberplus Paiement, l’Utilisateur
							accepte sans condition les Conditions
							Générales d’Utilisation de Cyberplus Paiement,
							accessibles à l’adresse
							https://www.cyberpluspaiement.com et reconnait
							que ACSES est un tiers au processus de
							paiement
						</p>
						<p className="mb-[16px] lg:font-normal">
							ACSES garantit l’Utilisateur qu’elle n’a
							jamais accès aux informations confidentielles
							relatives au moyen de paiement utilisé par ce
							dernier pour payer son Offre et ce, même si
							l’Utilisateur choisit de sauvegarder ses
							données de paiement sur la Plateforme. Cette
							transaction est sécurisée selon un procédé de
							cryptage et ce afin de renforcer l’ensemble
							des procédés de brouillage et de cryptage en
							optimisant la protection de toutes les données
							personnelles liées à ce moyen de paiement. Le
							coût du paiement par carte bancaire est
							entièrement à la charge de l’Utilisateur.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Il sera procédé à une autorisation de
							prélèvement du montant de l’Offre au moment de
							la réservation et communiqué dans l’Email de
							Confirmation telles que prévues dans les
							présentes CGV. Le prélèvement effectif de
							cette somme interviendra dans les 24 heures de
							la réception de l’Email de confirmation.
							L’Utilisateur pourra régler les Offres par
							carte bancaire CB, VISA, MasterCard.
							L’Utilisateur garantit à ce titre qu’il
							communique à ACSES des coordonnées et
							informations bancaires exactes. Dans le cadre
							de souscription à l’abonnement mensuel,
							l’Utilisateur sera de nouveau prélevé à la
							date anniversaire de la souscription sauf s’il
							demande la résiliation de son abonnement.
							Cette demande doit être effectuée par email a
							minima 10 jours avant la date de prélèvement.{" "}
						</p>
						<p className="mb-[16px] lg:font-normal">
							Dans le cadre de la lutte contre les fraudes
							sur Internet, les informations relatives aux
							données bancaires pourront être transmises à
							tout tiers habilité par la loi ou désigné par
							ACSES aux seules fins de vérification de
							l’identité de l’Utilisateur et de lutte contre
							la fraude.{" "}
						</p>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur s’engage à assurer le paiement
							de l’Offre choisi et reconnait et accepte à ce
							titre que le prix de l’Offre est du peu
							importe que l’Utilisateur utilise ou non les
							Services.
						</p>

						<p className="mb-[16px] lg:font-normal">
							En cas de défaut de paiement ou paiement
							partiel de toute somme qui serait due, ou en
							cas de fraude ou tentative de fraude relative
							à l’utilisation de la Plateforme ou des
							Services, ACSES se réserve le droit de
							suspendre ou de résilier la Commande ou
							l’Offre et l’accès aux Services.
						</p>
						<p className="mb-[16px] lg:font-normal">
							A toutes fins utiles, il est rappelé à
							l’Utilisateur que ACSES ne pourra être tenu
							responsable de tout dommage résultant du
							processus de paiement et notamment de l’échec
							du paiement, défaut de cryptage des données
							bancaires, faille de sécurité, montant débité
							erroné, etc.
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">Facturation</h3>
						<p className="mb-[16px] lg:font-normal">
							Une facture peut être téléchargée sur le
							Compte de l’Utilisateur.
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">Révision</h3>
						<p className="mb-[16px] lg:font-normal">
							Les conditions financières telles qu’indiquées
							sur la Plateforme pourront être révisées par
							ACSES, une fois par an. ACSES informera
							l’Utilisateur de ces nouvelles conditions
							financières par affichage sur la Plateforme.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="paiement" className="mb-[16px]">
						Paiement
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							Les prix sont indiqués en euros. Ils
							comprennent la taxe sur la valeur ajoutée
							(TVA) applicable au jour de la souscription de
							l’Offre. -Le montant de l’offre à la durée se
							calcule sur la base du nombre de jours
							réservés. -Le montant de l’abonnement mensuel
							se calcul sur la base d’un forfait. Un mois
							correspond à 30 jours glissants. L’abonnement
							se renouvelle automatiquement à la date
							anniversaire sauf si l’Utilisateur demande la
							résiliation de son abonnement a minima 10
							jours avant la date de prélèvement. Toute
							résiliation d’abonnement entraine
							automatiquement la suppression de l’ensemble
							des réservations ayant cours au-delà de la
							date de fin d’abonnement. -Le montant de
							l’abonnement annuel se calcul sur la base d’un
							forfait payable intégralement à la
							souscription. Un an d’abonnement correspond à
							365 jours glissants
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="droit-de-retractation"
						className="mb-[16px]"
					>
						Droit de rétractation
					</h2>
					<div className="font-medium">
						<h3 className="mb-[16px]">Offres</h3>
						<p className="mb-[16px] lg:font-normal">
							Les prix sont indiqués en euros. Ils
							comprennent la taxe sur la valeur ajoutée
							(TVA) applicable au jour de la souscription de
							l’Offre.
						</p>
						<p className="mb-[16px] lg:font-normal">
							-Le montant de l’offre à la durée se calcule
							sur la base du nombre de jours réservés. -Le
							montant de l’abonnement mensuel se calcul sur
							la base d’un forfait. Un mois correspond à 30
							jours glissants. L’abonnement se renouvelle
							automatiquement à la date anniversaire sauf si
							l’Utilisateur demande la résiliation de son
							abonnement a minima 10 jours avant la date de
							prélèvement. Toute résiliation d’abonnement
							entraine automatiquement la suppression de
							l’ensemble des réservations ayant cours
							au-delà de la date de fin d’abonnement. -Le
							montant de l’abonnement annuel se calcul sur
							la base d’un forfait payable intégralement à
							la souscription. Un an d’abonnement correspond
							à 365 jours glissants
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Délai d’exercice du droit de rétractation
						</h3>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur dispose d’un délai de quatorze
							(14) jours francs pour exercer son droit de
							rétractation à compter de : la Prise d’Effet
							des présentes CGV pour rétracter la
							souscription à l’Offre, étant entendu que ce
							droit de rétractation n’est plus possible à
							compter de l’utilisation des Services
							conformément aux dispositions des articles L
							121-21 et suivants du code de la consommation.
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Modalité du droit de rétractation
						</h3>
						<p className="mb-[16px] lg:font-normal">
							Afin d’exercer son droit de rétractation,
							l’Utilisateur devra notifier son intention
							d’exercer son droit de rétractation à ACSES en
							contactant ACSES : par email à l’adresse
							électronique suivante : contact@acses.io;
							Toutefois, afin d’améliorer les Services,
							toute observation de la part de l’Utilisateur
							sera la bienvenue.
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Conséquences du droit de rétractation
						</h3>
						<p className="mb-[16px] lg:font-normal">
							L’exercice du droit de rétractation n’est
							soumis à aucune pénalité. Il donnera lieu au
							remboursement toute Offre qui aura déjà été
							payée par l’Utilisateur et non utilisée.
						</p>
						<p className="mb-[16px]">
							{" "}
							Ce remboursement sera effectué par virement
							bancaire sur le compte bancaire de
							l’Utilisateur et sur la base des coordonnées
							bancaires transmises lors dudit paiement.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Ce remboursement sera effectué dans un délai
							de quatorze (14) jours à compter de la
							réception par ACSES de la demande d’exercice
							du droit de rétractation.
						</p>
					</div>
				</div>

				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="responsabilite" className="mb-[16px]">
						Responsabilité
					</h2>
					<div className="font-medium">
						<h3 className="mb-[16px]">Offres</h3>
						<p className="mb-[16px] lg:font-normal">
							Les prix sont indiqués en euros. Ils
							comprennent la taxe sur la valeur ajoutée
							(TVA) applicable au jour de la souscription de
							l’Offre.
						</p>
						<p className="mb-[16px] lg:font-normal">
							-Le montant de l’offre à la durée se calcule
							sur la base du nombre de jours réservés. -Le
							montant de l’abonnement mensuel se calcul sur
							la base d’un forfait. Un mois correspond à 30
							jours glissants. L’abonnement se renouvelle
							automatiquement à la date anniversaire sauf si
							l’Utilisateur demande la résiliation de son
							abonnement a minima 10 jours avant la date de
							prélèvement. Toute résiliation d’abonnement
							entraine automatiquement la suppression de
							l’ensemble des réservations ayant cours
							au-delà de la date de fin d’abonnement. -Le
							montant de l’abonnement annuel se calcul sur
							la base d’un forfait payable intégralement à
							la souscription. Un an d’abonnement correspond
							à 365 jours glissants
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Responsabilité de l’abonné
						</h3>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur est responsable de la
							confidentialité permanente de ses identifiant,
							mot de passe ainsi que de toutes les
							informations renseignées lors de la création
							du Compte.
						</p>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur est responsable des données
							qu’il diffuse sur la Plateforme. L’Utilisateur
							reconnait utiliser les Services sous son
							entière responsabilité et conformément à
							l’éventuelle documentation jointe à ce(s)
							dernier(s).
						</p>
						<p className="mb-[16px] lg:font-normal">
							En outre, l’Utilisateur garantit ACSES contre
							toute action ou réclamation quels qu’en soit
							le fondement, la forme, ou la personne à
							l’origine de cette action causée en tout ou
							partie par un manquement à l’une de ses
							obligations et garantie prévues aux CGU, CGV
							(ci-après « l’Action »). En conséquence,
							l’Utilisateur prendra à sa charge, toutes les
							conséquences négatives découlant pour ACSES de
							l’Action, et en particulier ; le montant de
							toute condamnation prononcée contre ACSES en
							lien avec l’Action, ou le montant mis à sa
							charge dans le cadre d’un règlement amiable de
							l’Action, le montant de tout frais (y compris
							les frais d’expertise et d’avocat) exposés par
							ACSES pour présenter sa défense dans le cadre
							de l’Action.
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Responsabilité de Acses
						</h3>
						<p className="mb-[16px] lg:font-normal">
							En sa qualité d’intermédiaire technique, ACSES
							s’engage à faire ses meilleurs efforts afin de
							fournir les Services conformes à leur
							documentation et sa responsabilité est limitée
							aux seuls préjudices directs et prévisibles.
							Sa responsabilité ne pourra être engagée par
							l’Utilisateur qu’en cas de démonstration d’une
							faute contractuelle exclusivement imputable à
							ACSES.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							A ce titre, la responsabilité de ACSES ne
							saurait être engagée en cas d’utilisation des
							Services autres que les cas prévus dans les
							CGU et les présentes CGV et notamment en cas
							de dépôts d’objet dans les Consignes autre que
							des clés.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							De plus, la responsabilité de ACSES ne saurait
							être engagée notamment (i) dans l’hypothèse où
							les informations renseignées par l’Utilisateur
							s’avéreraient fausses ou incomplètes, (ii) en
							cas de défaut de paiement et en cas
							d’irrégularités sur le Compte. Dans ce
							contexte, ACSES se réserve le droit d’annuler
							l’Offre et/ou de supprimer le Compte (iii) en
							cas de négligence de l’Utilisateur dans
							l’utilisation des Services.
						</p>
						<p className="mb-[16px] lg:font-normal">
							Après expiration de la réservation, en cas de
							non récupération du contenu du casier et de
							non régularisation de la situation par le
							client sous un délais de 10 jours, pour des
							raisons de législation et d’assurance, ACSES
							se verra dans l’obligation de disposer du
							contenu du casier
						</p>
						<p className="mb-[16px] lg:font-normal">
							En tout état de cause, et dans l’hypothèse où
							la responsabilité de ACSES devrait être
							établie, celle-ci serait limitée toutes causes
							et dommages confondus, y compris pour ce qui
							concerne les garanties, au montant payé par
							l’Utilisateur au titre de l’Offre en cours au
							moment du fait générateur de responsabilité à
							l’exception du montant prévu à l’article «
							Engagement au titre de l’utilisation des
							Services ».
						</p>
						<p className="mb-[16px] lg:font-normal">
							Il est rappelé à l’Utilisateur qu’il lui
							appartient de prendre toutes les précautions
							nécessaires notamment contre les risques
							d’atteinte à la sécurité des Données et des
							clés déposées dans un casier de Consigne et de
							mettre en œuvre toute action de nature à
							éviter une aggravation de son préjudice en cas
							de sinistre.
						</p>
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Les Parties reconnaissent et acceptent que le
							prix de l’Offre reflète la répartition du
							risque entre les Parties et la limitation de
							responsabilité qui en résulte pour ACSES.
							Elles reconnaissent et acceptent également que
							le montant du plafond accepté entre elles dans
							les termes prévus au présent article n’est pas
							dérisoire et ne contredit pas la portée de
							l’obligation essentielle souscrite par ACSES
							au titre des CGV.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="suspension-resiliation"
						className="mb-[16px]"
					>
						Suspension /Résiliation
					</h2>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Suspension des services
						</h3>
						<p className="mb-[16px] lg:font-normal">
							ACSES se réserve le droit de suspendre sans
							indemnité la Commande en cours ou le Service,
							le cas échéant sans préavis, compte tenu de la
							gravité des faits, si l’Utilisateur manque à
							l’une de ses obligations au titre des CGV, y
							compris ses obligations souscrites dans le
							cadre des CGU qui complètent les CGV. Dans une
							telle hypothèse, ACSES adressera un email vous
							informant des motifs et modalités de cette
							suspension.
						</p>
						<p className="mb-[16px] lg:font-normal">
							À compter de la date de suspension indiquée
							dans l’email, l’Utilisateur dispose d’un délai
							de quinze (15) jours pour remédier à au
							manquement contractuel ou au règlement des
							sommes dues, à défaut ACSES aura la faculté de
							résilier l’Offre conformément à l’article
							Résiliation pour manquement.
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Responsabilité de l’abonné
						</h3>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur est responsable de la
							confidentialité permanente de ses identifiant,
							mot de passe ainsi que de toutes les
							informations renseignées lors de la création
							du Compte.
						</p>
						<p className="mb-[16px] lg:font-normal">
							L’Utilisateur est responsable des données
							qu’il diffuse sur la Plateforme. L’Utilisateur
							reconnait utiliser les Services sous son
							entière responsabilité et conformément à
							l’éventuelle documentation jointe à ce(s)
							dernier(s).
						</p>
						<p className="mb-[16px] lg:font-normal">
							En outre, l’Utilisateur garantit ACSES contre
							toute action ou réclamation quels qu’en soit
							le fondement, la forme, ou la personne à
							l’origine de cette action causée en tout ou
							partie par un manquement à l’une de ses
							obligations et garantie prévues aux CGU, CGV
							(ci-après « l’Action »). En conséquence,
							l’Utilisateur prendra à sa charge, toutes les
							conséquences négatives découlant pour ACSES de
							l’Action, et en particulier ; le montant de
							toute condamnation prononcée contre ACSES en
							lien avec l’Action, ou le montant mis à sa
							charge dans le cadre d’un règlement amiable de
							l’Action, le montant de tout frais (y compris
							les frais d’expertise et d’avocat) exposés par
							ACSES pour présenter sa défense dans le cadre
							de l’Action.
						</p>
					</div>
					<div className="font-medium">
						<h3 className="mb-[16px]">
							Effets de la résiliation
						</h3>
						<p className="mb-[16px] lg:font-normal">
							En cas de résiliation de tout ou partie des
							présentes CGV, l’Utilisateur payer à ACSES
							toute somme exigible au titre des Services et
							notamment l’Offre jusqu’à la prise d’effet de
							la résiliation.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 className="mb-[16px]">
						Données personnelles
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							ACSES collecte des données à caractère
							personnel (ci-après les « Données ») et
							l’Utilisateur est informé que ce traitement
							est mis en œuvre notamment pour la gestion du
							Compte et le traitement de des Abonnements.{" "}
						</p>
						<p className="mb-[16px] lg:font-normal">
							Les champs renseignés par un astérisque [*]
							dans les formulaires sont des champs
							obligatoires. A défaut de les renseigner toute
							souscription à une Offre, création de Compte
							ou toute autre demande formalisée via la
							Plateforme ne pourra être prise en compte par
							ACSES.
						</p>
						<p className="mb-[16px] lg:font-normal">
							Conformément à la loi n°78-17 du 6 janvier
							1978, relative à l’informatique, aux fichiers
							et aux libertés, modifiée, l’Utilisateur
							dispose d’un droit d’accès et de rectification
							sur les données à caractère personnel le
							concernant, ainsi que d’un droit d’opposition
							pour motif légitime à ce que les Données
							soient traitées. L’Utilisateur pourra exercer
							ces droits en justifiant de son identité (CNI
							ou passeport) en adressant sa demande à
							l’adresse mail suivante : contact@acses.io.
						</p>
						<p className="mb-[16px] lg:font-normal">
							ACSES est susceptible de transmettre les
							Données à ces partenaires dans le cadre de la
							gestion des Comptes et des Offres ainsi qu’à
							des fins de prospection si vous avez donné
							votre accord lors de la création du Compte.
							Les modalités et notamment d’information
							relative aux traitements de Données réalisés
							au travers de la Plateforme sont prévues plus
							précisément dans la Politique de
							confidentialité de la Plateforme.{" "}
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2
						id="assistances-reclamations"
						className="mb-[16px]"
					>
						Assistance et réclamations
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							ACSES met à disposition de l’Utilisateur un
							service d’assistance par téléphone et en
							ligne. Le numéro d’assistance téléphonique est
							indiqué dans tous les emails et sur toutes les
							machines. En cas de difficultés lors de
							l’usage du service, ACSES aucune réclamation
							ne pourra obtenir satisfaction si le service
							d’assistance n’a pas été contacté au moment où
							la difficulté a été rencontrée par
							l’Utilisateur ou le Tiers,
						</p>
						<p className="mb-[16px] lg:font-normal">
							Toute demande d’information, question ou
							réclamation devra être adressée au service
							client ACSES par :
						</p>
						<p className="mb-[16px] lg:font-normal">
							Téléphone : 0986317328 Courrier électronique à
							l’adresse suivante : contact@acses.io Courrier
							postal à : ACSES, Service client, 57bis rue du
							théâtre 75015 PARIS
						</p>
						<p className="mb-[16px] lg:font-normal">
							ACSES fera ses meilleurs efforts afin de
							répondre aux demandes, sans pouvoir garantir
							de délai de réponse ou de résultat quant au
							contenu de ses réponses.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="force-majeure" className="mb-[16px]">
						Force majeure
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							ACSES ne sera pas responsable de toute
							inexécution ou de tout retard dans l’exécution
							de ses obligations, si cette inexécution ou ce
							retard est dû(e) à un événement de force
							majeure, tel que défini par la jurisprudence
							des cours et tribunaux français, ainsi que les
							cas d’incendie, d’inondation, de tremblement
							de terre, de catastrophes naturelles, d’actes
							de guerre, de terrorisme, d’émeutes, de
							rébellions, de grèves, ou de conflits sociaux,
							retard ou manquement des services postaux ou
							tout autre organisme de transport public ou
							privé à fournir des produits ou des services,
							l’arrêt ou le blocage des moyens de
							communication et de télécommunications, panne
							générale des équipements ou du système
							informatique de ACSES ainsi que tout évènement
							hors du contrôle de ACSES, y compris les actes
							de ses sous-traitants.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="cession" className="mb-[16px]">
						Cession
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							ACSES se réserve le droit de transférer à
							toute autre société les droits et obligations
							découlant des présentes CGV. En revanche,
							l’Utilisateur ne peut pas transférer à un
							tiers les droits et obligations des présentes
							CGV.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="survivance" className="mb-[16px]">
						Survivance
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							ACSES se réserve le droit de transférer à
							toute autre société les droits et obligations
							découlant des présentes CGV. En revanche,
							l’Utilisateur ne peut pas transférer à un
							tiers les droits et obligations des présentes
							CGV.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="nulite" className="mb-[16px]">
						Nullité - Titre
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							Si une ou plusieurs stipulations des CGV sont
							tenues pour non valides ou déclarées telles en
							application d’une loi, d’un règlement ou à la
							suite d’une décision définitive d’une
							juridiction compétente, les autres
							stipulations garderont toute leur force et
							portée.
						</p>
					</div>
				</div>
				<div className="mb-[60px] lg:mb-[96px]">
					<h2 id="droit-applicable" className="mb-[16px]">
						Droit applicable et tribunaux compétents
					</h2>
					<div className="font-medium">
						<p className="mb-[16px] lg:font-normal">
							{" "}
							Les présentes CGV et les documents auxquels
							elles font référence sont régis par le droit
							français. Il en est ainsi pour les règles de
							fond, comme pour les règles de forme ainsi que
							pour les règles de compétences
							juridictionnelles.
						</p>

						<p className="mb-[16px] lg:font-normal">
							{" "}
							Dans l’hypothèse où un différend surviendrait,
							portant sur la validité, l’interprétation,
							l’exécution ou la fin pour quelle que cause
							que ce soit des présentes CGV, les Parties
							conviennent de rechercher toute solution
							amiable.
						</p>
						<p className="mb-[16px] lg:font-normal">
							En cas d’impossibilité de résolution amiable
							dudit différent dans les 30 jours suivants la
							réception par l’autre Partie de la demande
							écrite de conciliation amiable adressée par
							l’une ou l’autre des Parties, , TOUT LITIGE
							RELATIF A L’EXISTENCE, L’INTERPRETATION,
							L’EXECUTION OU LA RUPTURE DES CGU, MEME EN CAS
							DE PLURALITE DE DEFENDEURS SERA SOUMIS AU
							TRIBUNAL FRANÇAIS TERRITORIALEMENT COMPETENT.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Articles;
