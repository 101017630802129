import React, { useState, useEffect } from "react";
import DesktopNav from "./DesktopNav/DesktopNav";
import MobileNav from "./MobileNav/MobileNav";

const mediaQuery = "screen and (min-width: 1024px)";

const NavBar = () => {
  const mql = window.matchMedia(mediaQuery);
  const [showDesktopMenu, setShowDesktopMenu] = useState(mql.matches);
  useEffect(() => {
    const handleMediaChange = function () {
      setShowDesktopMenu(this.matches);
    };
    mql.addEventListener("change", handleMediaChange);
    setShowDesktopMenu(mql.matches);
    return () => {
      mql.removeEventListener("change", handleMediaChange);
    };
  }, [mql]);
  if (showDesktopMenu) {
    return <DesktopNav />;
  }
  return <MobileNav />;
};
export default NavBar;
