import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import "./NewFooter.css";

const NewFooter = () => {
	const year = new Date().getFullYear();
	const navigate = useNavigate();
	useEffect(() => {
	return () => {
	
		window.scrollTo(0, 0);
		}
	  }, []);
	return (
		<footer>
			<div className="footer-top">
				<Link to="/">
					<img
						src="/assets/images/new-footer/logo.png"
						alt="logo-acses"
						className="footer-logo"
						width={193}
						height={58}
						loading="lazy"
					/>
				</Link>
				<div className="footer-top-cta">
					<p className="text-[#FFFFFF]">
						Envie d’en savoir plus sur les solutions Acses ?
					</p>
					<div className="footer-btn-container">
						<Button
							text="Demander une démo"
							idBtn="bouton-footer-demande-une-demo"
							action={() => navigate("/demande-demo")}
						/>
					</div>
				</div>
			</div>
			<div className="footer-medium">
				<div className="footer-medium-div">
					<h3 className="footer-h3">Nous contacter</h3>
					<ul className="footer-medium-ul">
						<li className=".footer-li">
							<a href="tel:0980800551">09 80 80 05 51</a>
						</li>
						<li className=".footer-li">
							<a href="mailto:contact@acses.io">
								contact@acses.io
							</a>
						</li>
						<li className=".footer-li">
							21 Rue Médéric 92110, Clichy, France
						</li>
					</ul>
				</div>
				<div className="footer-medium-div">
					<h3 className="footer-h3">Nos solutions</h3>
					<ul className="footer-medium-ul">
						<li className=".footer-li">
							<Link
								to="/solutions-connectees"
								className="text-[#FFFFFF]"
							>
								Nos solutions connectées
							</Link>
						</li>
						<li className=".footer-li">
							<Link
								to="/comment-ca-marche"
								className="text-[#FFFFFF]"
							>
								Comment ça marches ?
							</Link>
						</li>
						<li className=".footer-li">
							<Link
								to="/consigne-personnalisee"
								className="text-[#FFFFFF]"
							>
								Personnaliser ma consigne
							</Link>
						</li>
					</ul>
				</div>
				<div className="footer-medium-div">
					<h3 className="footer-h3">
						Votre secteur d’activité
					</h3>
					<ul className="footer-medium-ul">
						<li className=".footer-li">
							<Link to="/gestionnaires-flotte-automobile">
								Gestionnaires de flotte automobile
							</Link>
						</li>
						<li className=".footer-li">
							<Link to="/concessionaires-automobiles">
								Concessionnaires Automobiles
							</Link>
						</li>
						<li className=".footer-li">
							<Link to="/hotellerie">Hôtellerie</Link>
						</li>
						<li className=".footer-li">
							<Link to="/immobilier">Immobilier</Link>
						</li>
						<li className=".footer-li">
							<Link to="/airbnb-locations-courte-duree">
								Airbnb et locations
							</Link>
						</li>
						<li className=".footer-li">
							<Link to="/service-aux-entreprises">
								Services aux entreprises
							</Link>
						</li>
					</ul>
				</div>
				<div className="footer-medium-div">
					<h3 className="footer-h3">Á propos</h3>
					<ul className="footer-medium-ul">
						<li className=".footer-li">
							<Link to="/notre-histoire">
								Notre histoire
							</Link>
						</li>
						<li className=".footer-li">
							<Link to="/blog">Blog</Link>
						</li>
						{/* <li className=".footer-li">
							<Link to="/">Presse</Link>
						</li> */}
					</ul>
				</div>
				<div className="footer-medium-div">
					<h3 className="footer-h3">Aides</h3>
					<ul className="footer-medium-ul">
						{/* <li className=".footer-li"><Link to="/">Centre d’aides</Link></li> */}
						<li className=".footer-li">
							<Link to="/contact">Contact</Link>
						</li>
						{/* <li className=".footer-li"><Link to="/">Recrutement</Link></li> */}
					</ul>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="footer-bottom-link">
					<ul>
						<li className=".footer-li">
							<Link to="/mentions-legales">
								Mentions légales
							</Link>
						</li>
						<li className=".footer-li">
							<Link to="/plan-site">Plan du site</Link>
						</li>
						{/* <li className=".footer-li">
							<Link to="/">Centre d’aide</Link>
						</li> */}
					</ul>
					<p>© {year} Acses. Tous droits réservés.</p>
				</div>
				<div className="footer-bottom-social">
					<a
						href="https://www.linkedin.com/company/acses-casiers-connect%C3%A9s/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="/assets/images/new-footer/linkedin.svg"
							alt="linkedin"
							width={25}
							height={25}
						/>
					</a>
					<a
						href="https://twitter.com/AcsesFrance"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="/assets/images/new-footer/twitter.svg"
							alt="twitter"
							width={30}
							height={25}
						/>
					</a>
					<a
						href="https://www.youtube.com/@acses867"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="/assets/images/new-footer/youtube.svg"
							alt="youtube"
							width={25}
							height={17}
						/>
					</a>
				</div>
			</div>
		</footer>
	);
};

export default NewFooter;
