import React from "react";
import { useNavigate } from "react-router-dom";
const ContactUs = () => {
	const navigate = useNavigate();
	
	return (
		<div className="relative flex w-full flex-col justify-center gap-[20px] overflow-hidden rounded-2xl bg-gradient-to-r from-[#2DBBED] to-[#2972B8] px-[20px] py-[36px] md:p-[60px] md:px-[60px] lg:max-w-[1320px]">
			<div className="">
				<h2 className="text-center text-[33px] leading-[48px] text-white md:font-semibold lg:w-[567px] lg:text-left lg:text-[41px]">
					Faire des économies avec une consigne connectée ?
				</h2>
				<p className="text-center font-semibold text-white lg:text-left">
					Discutons-en ensemble !
				</p>
			</div>

			<div className="flex justify-center lg:justify-start">
				<button
					onClick={() => navigate("/contact")}
					id="bouton-nsc-contacter-commercial"
					className="flex items-center justify-center gap-[17px] rounded-lg bg-white py-[16px] px-[33px]"
				>
					<div className="w-[29px] ">
						<img
							className="w-full"
							src="assets/images/pictos/phone-gradient-blue.svg"
							alt="Téléphone"
							width={29}
							height={29}
							loading="lazy"
						/>
					</div>
					<div>
						<p className="bg-gradient-to-r from-[#2DBBED] to-[#2972B8] bg-clip-text font-bold text-transparent ">
							{" "}
							Contacter un commercial
						</p>
					</div>
				</button>
			</div>
		</div>
	);
};

export default ContactUs;
