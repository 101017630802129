import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TagManager from 'react-gtm-module';

const Form = () => {
	const [messageForm, setMessageForm] = useState("");
	const [messageStyle, setMessageStyle] = useState("");
	
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm();

const formName = "contact-form";	

	const tagManagerArgs = {
		dataLayer: {
			event: 'submit_contact_form',
			form_name: formName
		},
		dataLayerName: 'PageDataLayer'
	}
	const onSubmit = async (data) => {
		await fetch("https://api.dev.acses.io/web/contact/source", {
			body: JSON.stringify({ source: "Contact Us", ...data, phone: Number(data.phone) }),
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
		}).then((res) => {
			if (res.status === 200) {
				console.log(res);
				setMessageStyle("text-[#33A620]");
				setMessageForm(
					"Votre message à bien été envoyé, nous vous recontacterons"
				);
			} else if (res.status === 500) {
				setMessageForm(
					"Une erreur est survenue, veuillez réessayer ultérieurement"
				);
			} else if (res.status === 400) {
				setMessageForm(
					res.data.propertyName
				);
			} else {
				setMessageForm(
					"Une erreur est survenue, veuillez réessayer ultérieurement"
				);
			}
			reset()

		});
		TagManager.datalayer(tagManagerArgs)
	};


	return (
		<section className="shadowBoxForm md:bg-white md:p-[36px] lg:order-3 lg:col-span-2 lg:row-span-2  lg:ml-auto lg:w-[582px] xl:order-2 xl:col-span-1 xl:-ml-[100px] xl:w-[758px] ">
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="flex flex-col gap-y-[16px] mb-[24px]"
				name="contact-form"
			>
				<div>
					<input
						style={{
							backgroundImage:
								errors.helpMotif &&
								"url('assets/images/pictos/error-form.svg')",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "calc(100% - 18px)",
						}}
						className={`${
							errors.helpMotif
								? "mb-[8px] rounded-[8px] bg-[#FCEFEF] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
								: "mb-[8px] rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
						} w-full text-[.74rem] md:text-[100%]`}
						{...register("helpMotif", {
							required: true,
						})}
						placeholder="Comment pouvons-nous vous aidez ? *"
					/>

					{errors?.helpMotif?.type === "required" && (
						<p className="text-[#EE2D2D]">
							Un motif doit être saisi
						</p>
					)}
				</div>
				<div className="grid gap-y-[16px] lg:grid-cols-2 lg:gap-x-[31px]">
					<div>
						<input
							style={{
								backgroundImage:
									errors.helpMotif &&
									"url('assets/images/pictos/error-form.svg')",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "calc(100% - 18px)",
							}}
							className={`${
								errors.lastName
									? "bg-[#FCEFEF] focus:outline-[#EE2D2D]"
									: "bg-[#F0F5FA] focus:outline-[#2DBCEE]"
							} mb-[8px] w-full rounded-[8px] px-[18px] py-[12px] text-[.74rem] md:text-[100%]`}
							{...register("lastName", {
								required: true,
								pattern: /^[A-Za-z]+$/i,
							})}
							placeholder="Nom*"
						/>
						{errors?.lastName?.type === "pattern" && (
							<p className="text-[#EE2D2D]">
								Le nom ne peut contenir que des lettres
							</p>
						)}
						{errors?.lastName?.type === "required" && (
							<p className="text-[#EE2D2D]">
								Veuillez indiquer votre nom
							</p>
						)}
					</div>
					<div>
						<input
							style={{
								backgroundImage:
									errors.helpMotif &&
									"url('assets/images/pictos/error-form.svg')",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "calc(100% - 18px)",
							}}
							className={`${
								errors.firstname
									? "mb-[8px] rounded-[8px] bg-[#FCEFEF] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
									: "mb-[8px] rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
							} w-full text-[.74rem] md:text-[100%]`}
							{...register("firstName", {
								required: true,
								pattern: /^[A-Za-z]+$/i,
							})}
							placeholder="Prénom*"
						/>

						{errors?.firstname?.type === "pattern" && (
							<p className="text-[#EE2D2D]">
								Le Prénom ne peut contenir que des lettres
							</p>
						)}

						{errors?.firstname?.type === "required" && (
							<p className="text-[#EE2D2D]">
								Veuillez indiquer votre prénom
							</p>
						)}
					</div>

					<div>
						<input
							style={{
								backgroundImage:
									errors.helpMotif &&
									"url('assets/images/pictos/error-form.svg')",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "calc(100% - 18px)",
							}}
							className={`${
								errors.firstname
									? "mb-[8px] rounded-[8px] bg-[#FCEFEF] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
									: "mb-[8px] rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
							} w-full text-[.74rem] md:text-[100%]`}
							{...register("email", {
								required: true,
								pattern:
									/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
							})}
							placeholder="Email*"
						/>
						{errors?.email?.type === "pattern" && (
							<p className="text-[#EE2D2D]">
								adresse email saisie n'est pas conforme
							</p>
						)}
						{errors?.email?.type === "required" && (
							<p className="text-[#EE2D2D]">
								Une addresse email doit être saisie
							</p>
						)}
					</div>
					<div>
						<input
							style={{
								backgroundImage:
									errors.helpMotif &&
									"url('assets/images/pictos/error-form.svg')",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "calc(100% - 18px)",
							}}
							className={`${
								errors.phone
									? "mb-[8px] rounded-[8px] bg-[#FCEFEF] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
									: "mb-[8px] rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
							} w-full text-[.74rem] md:text-[100%]`}
							{...register("phone", {
								required: true,
								pattern: /[0-9]/,
							})}
							placeholder="Téléphone*"
						/>
						{errors?.phone?.type === "pattern" && (
							<p className="text-[#EE2D2D]">
								Le numéro de téléphone ne peut contenir que
								des chiffres
							</p>
						)}
						{errors?.phone?.type === "required" && (
							<p className="text-[#EE2D2D]">
								Un numero de téléphone doit être saisi
							</p>
						)}
					</div>
				</div>
				<div>
					<input
						style={{
							backgroundImage:
								errors.helpMotif &&
								"url('assets/images/pictos/error-form.svg')",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "calc(100% - 18px)",
						}}
						className={`${
							errors.enterpriseName
								? "mb-[8px] rounded-[8px] bg-[#FCEFEF] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
								: "mb-[8px] rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
						} w-full text-[.74rem] md:text-[100%]`}
						{...register("enterpriseName", {
							required: true,
						})}
						placeholder="Votre société*"
					/>
					{errors?.enterpriseName?.type === "pattern" && (
						<p className="text-[#EE2D2D]">
							saisie n'est pas conforme
						</p>
					)}
					{errors?.enterpriseName?.type === "required" && (
						<p className="text-[#EE2D2D]">
							Veuillez indiquer le nom de votre société
						</p>
					)}
				</div>
				<div>
					<textarea
						className={`${
							errors.message
								? "mb-[8px] rounded-[8px] bg-[#FCEFEF] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
								: "mb-[8px] rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
						} h-[128px] w-full text-[.74rem] md:text-[100%]`}
						{...register("message", {
							required: true,
						})}
						placeholder="Votre message*"
					/>
					{errors?.message?.type === "required" && (
						<p className="text-[#EE2D2D]">
							Veuillez saisir votre message
						</p>
					)}
				</div>
				<div>
					<input
						style={{
							backgroundImage:
								errors.helpMotif &&
								"url('assets/images/pictos/error-form.svg')",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "calc(100% - 18px)",
						}}
						className={`${
							errors.howYouFindUs
								? "mb-[8px] rounded-[8px] bg-[#FCEFEF] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
								: "mb-[8px] rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
						} w-full text-[.74rem] md:text-[100%]`}
						{...register("howYouFindUs", {
							required: true,
						})}
						placeholder="Comment avez-vous découvert Acses ? *"
					/>
					{errors?.howYouFindUs?.type === "required" && (
						<p className="text-[#EE2D2D]">
							Ce champs est obligatoire
						</p>
					)}
				</div>
				<div className="mb-[26px] mt-[8px] flex items-start gap-x-[8px]">
					<input
						className={`${
							errors.newsletter
								? "mb-[8px] rounded-[8px] bg-[#FCEFEF] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
								: "mb-[8px] rounded-[8px] bg-[#F0F5FA] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
						} mt-[5px]`}
						{...register("newsletter")}
						type="checkbox"
					/>
					<p className="text-[0.8rem] leading-[24px] text-[#163F59]">
						Tenez-moi informé des dernières nouveautés, des
						événements, des mises à jour logiciel…
					</p>
				</div>
				<input
					type="submit"
					className="flex w-full items-center justify-center rounded-[8px] bg-[#FFA945] py-[12px] pl-[16px] pr-[20px] font-bold text-white md:font-medium"
				/>
			</form>
			<span className={messageStyle + " mb-[36px] font-semibold leading-[24px]"}>{messageForm}</span>
			<div className="mt-[8px]">
				<p className="small-text font-[400] italic text-[#163F59] md:font-[300] ">
					Les informations recueillies à partir de ce
					formulaire sont enregistrées et transmises aux
					employés habilités d’Acses en charge du traitement
					de votre message, conformément à notre politique
					de confidentialité. Conformément à la
					réglementation applicable en matière de données
					personnelles, vous disposez d’un droit d’accès, de
					modification, de rectification, de limitation,
					d'opposition, de restriction, de suppression et de
					portabilité des données qui vous concernent.
					Consultez nos mentions légales.
				</p>
			</div>
		</section>
	);
};

export default Form;
