import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const SitemapBlock = ({ title, linkContainer }) => {
	return (
		<>
			<hr className="mt-[6px] mb-[36px] text-[#F0F9FF]" />
			<div className="lg:flex lg:gap-x-[120px] xl:gap-[122px]">
				<h3 className="mb-[48px] text-[1.328rem] lg:w-1/3 lg:text-[1.25rem]">
					{title}
				</h3>

				<div className="flex flex-col gap-[36px] md:grid md:grid-cols-2 md:gap-x-[76px] lg:w-2/3 lg:gap-x-0 xl:grid-cols-3 xl:gap-x-[47px]">
					{linkContainer.map((el) => (
						<div key={uuidv4()}>
							<Link to={el.path}>
								<p className="text-[#2970B7]">{el.name}</p>
							</Link>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default SitemapBlock;
