import React from "react";
import { v4 as uuidv4 } from "uuid";

const Assistance = () => {
	const tools = [
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/headphones.svg",
			title: "Support client 7/7j",
			width: 33,
			height: 37,
		},
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/remote-hexagone.svg",
			title: "Évolution des fonctionnalités",
			width: 37,
			height: 44,
		},
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/tools.svg",
			title: "Contrat de maintenance",
			width: 49,
			height: 48,
		},
	];
	return (
		<div className="flex flex-col gap-[42px] md:flex-row md:justify-center lg:max-w-[1320px] xl:gap-[144px]">
			{tools.map((tool) => (
				<div
					key={tool.id}
					className="flex flex-col justify-center"
				>
					<div className="flex justify-center">
						<img
							src={tool.imgSrc}
							alt=""
							width={tool.width}
							height={tool.height}
							loading="lazy"
						/>{" "}
					</div>
					<div className="flex justify-center">
						<p className="w-[195px] text-center font-bold">
							{tool.title}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default Assistance;
