import { createContext, useReducer } from "react";

export const CookiesContext = createContext(null);
export const CookiesDispatchContext = createContext(null);


export function CookiesProvider({ children }) {
    
	const [cookiesSettings, dispatch] = useReducer(
		cookiesReducer,
		initialValue
	);

	return (
		<CookiesContext.Provider value={cookiesSettings}>
			<CookiesDispatchContext.Provider value={dispatch}>
				{children}
			</CookiesDispatchContext.Provider>
		</CookiesContext.Provider>
	);
}

function cookiesReducer(choice, action) {
	return (choice = action.payload);
}

const initialValue = true;
