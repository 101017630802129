import React from 'react'
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/fr";

const RecentPosts = ({posts}) => {
    return !posts
    ? "A venir"
    : posts.map((post, index) => {
            if (index === 0) {
                return (
                    <div
                        className="post col-span-1 row-span-2 md:col-span-2 md:max-w-[430px]"
                        key={post.id}
                    >
                        <Link
                            to={`/blog/${post.slug}`}
                            onClick={() =>
                                localStorage.setItem("slug", post.slug)
                            }
                        >
                            <div className="h-[75vw] overflow-hidden rounded-2xl md:h-[430px] md:w-[430px] lg:w-[455px] xl:w-[630px]">
                                <img
                                    className="h-full object-cover object-center"
                                    src={post.coverImage?.url}
                                    alt={post.altText}
                                />
                            </div>
                            <div>
                                <p className="datetime mt-6">
                                    {dayjs(post.publishedAt).format(
                                        "DD MMMM YYYY"
                                    )}
                                </p>
                            </div>
                            <div className="mt-3 md:mt-2">
                                <p className="article mb-0">
                                    {post.title}
                                </p>
                            </div>
                        </Link>
                    </div>
                );
            }
            return (
                <div
                    className="post col-span-1 md:max-w-[202px]"
                    key={post.id}
                >
                    <Link to={`/blog/${post.slug}`}>
                        <div className="h-[75vw] overflow-hidden rounded-2xl md:h-[202px] lg:w-[208px] xl:w-[285px]">
                            <img
                                className="h-full object-cover object-center"
                                src={post.coverImage?.url}
                                alt=""
                            />
                        </div>
                        <div>
                            <p className="datetime mt-6">
                                {post.date}
                            </p>
                        </div>
                        <div className="mt-3 md:mt-2">
                            <p className="article mb-0">
                                {post.title}
                            </p>
                        </div>
                    </Link>
                </div>
            );
      });
}

export default RecentPosts
