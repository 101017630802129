import React from "react";
import Form from "./components/Form";
import Hero from "./components/Hero.js";
import PartnersBanner from "./components/PartnersBanner";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ContactInfos from "../ContactUs/components/ContactInfos";
const Index = () => {
	return (
		<>
			<div className="flex justify-center pt-[36px] lg:pt-[60px] xl:pt-[72px] landingPageGradient" style={{backgroundImage:"url('assets/images/askDemo/background.webp')",backgroundRepeat: "no-repeat", backgroundSize:"cover"}}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Réservez une démo gratuite !</title>
					<meta
						name="description"
						content="Vous gérez un grand nombre de clés ? Vous avez besoin d'une solution sécurisée & gérable à distance ? Découvrez nos casiers connectés ! 👉 Réservez une démo."
					/>
				</Helmet>
				<section className="lg:grid lg:grid-cols-2 mx-[36px] lg:mx-[32px] w-full lg:max-w-[1024px] xl:max-w-[1320px] xl:mb-[40px]">
					<div className="mb-[36px] lg:mb-[48px] xl:mb-[56px] w-[120px] lg:w-[201px]">
						<Link to="/">
							<img
								className="w-full"
								src="assets/images/logos/logo_acses_with_word_blue.svg"
								alt="logo Acses"
							/>
						</Link>
						<div className="hidden lg:block lg:mt-[48px]">
							<Hero />
						</div>
					</div>
					<div className="lg:row-start-2 lg:hidden mb-[40px]">
						<Hero />
					</div>
					<div className=" lg:col-span-1 lg:order-3 xl:order-2 mb-[96px]">
						<Form />
					</div>
					<div className="lg:row-start-3 lg:-mt-[190px]">
						<ContactInfos />
					</div>
				</section>
			</div>
			<div className="flex justify-center bg-[#F6F8FC] py-[40px] px-[36px]">
				<PartnersBanner />
			</div>
		</>
	);
};

export default Index;
