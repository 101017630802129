import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Contact = () => {
	const navigate = useNavigate();
	const desktopScreen = useMediaQuery({
		query: "(min-width:1024px)",
	});
	return (
		<div className="lg:flex lg:max-w-[1320px] lg:gap-[32px]">
			<div className="mb-[20px] flex flex-col justify-center rounded-2xl bg-gradient-to-r from-[#2DBBED] to-[#2972B8] px-[20px] py-[40px] md:px-[85px] md:py-[60px] lg:h-[260px] lg:p-[36px] xl:h-[332px] xl:py-[80px] xl:px-[52px] ">
				<div className="mb-[20px] xl:mb-[36px]">
					<p className="text-center font-semibold text-white md:text-[20px] lg:text-[24.5px] lg:leading-[32px]">
						Vous souhaitez stocker des objets, des biens de
						proportions différentes et/ou plus importantes
						que des clés ?
					</p>
				</div>

				<div>
					<div className="flex justify-center">
						<button
							onClick={()=>navigate('/contact')}
							id="bouton-ccm-contacter-commercial"
							className="flex items-center justify-center rounded-lg bg-white py-[16px] px-[36px] md:h-[72px] md:w-[379px]"
						>
							<img
								className="mr-[9px] w-[29px]"
								src="assets/images/pictos/phone-gradient-blue.svg"
								alt="Téléphone"
								width={29}
								height={29}
								loading="lazy"
							/>

							<div>
								<p className="bg-gradient-to-r from-[#2DBBED] to-[#2972B8] bg-clip-text font-bold text-transparent">
									{" "}
									Contacter un commercial
								</p>
							</div>
						</button>
					</div>
				</div>
			</div>
			<div className="h-[260px] w-full rounded-2xl lg:max-w-[337px] xl:h-[332px] xl:max-w-[418px]">
				<div
					className="flex h-full w-full flex-col items-center justify-center rounded-2xl"
					style={{
						backgroundImage: desktopScreen
							? "url(assets/images/howItWorks/pexels-andrea-desktop.png)"
							: "url(assets/images/howItWorks/pexels-andrea.png)",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}
				>
					<div className="mx-[32px]">
						<h3 className="mb-[20px] text-center md:text-[21.25px] lg:text-[25px]">
							Découvrez nos études de cas client
						</h3>
					</div>
					<button
						onClick={() => navigate("/blog")}
						id="bouton-ccm-lire-nos-etudes-de-cas"
						className="flex items-center gap-[20px]"
					>
						<p className="font-semibold text-[#3798D4] md:text-[20px]">
							Lire nos études de cas{" "}
						</p>
						<img
							src="assets/images/pictos/arrow-blue-right.svg"
							alt=""
							width={11}
							height={20}
							loading="lazy"
						/>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Contact;
