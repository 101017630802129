import React from "react";

import Hero from "../components/Heros";
// import Partners from "../../Home/components/Partners";
import Management from "../components/Management";
import KeyCabinet from "../components/KeyCabinet";
import UiManagement from "../components/UiManagement";
import NewsLetterForm from "../../Home/components/NewsLetterForm";
import Custom from "../components/Custom";
import KnowMore from "../components/KnowMore";
import { Helmet } from "react-helmet";
import {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
} from "./airBnbContentPage";
import GrayBlock from "../components/GrayBlock";
import TagManager from 'react-gtm-module';

const Airbnb = (props) => {
	const tagManagerArgs = {
		dataLayer: {
			events: "info",
			page_category_1: props.path,
			page_category_2: '{{page_category_2}}'
		},
		dataLayerName: 'PageDataLayer'
	}
	TagManager.dataLayer(tagManagerArgs)
	return (
		{
			pHero,
			heroImgs,
			grayBlockImg,
			grayBlockP,
			managementContent,
		} && (
			<div className="pt-[81px] lg:pt-[200px] xl:pt-[240px]">
				<Helmet>
					<meta charSet="utf-8" />
					<title>
						Devenez un hôte exceptionnel ! 🏆 - Gestion
						sécurisée des clés
					</title>
					<meta
						name="description"
						content="Simplifiez le séjour de vos clients en leur donnant accès à une expérience automatisée de récupération de clés. Plus besoin d’être sur place ! "
					/>
				</Helmet>
				<section className="flex justify-center">
					<Hero
						bannerImg={heroImgs}
						nameSector="Airbnb & Locations"
						title="Facilitez le séjour des voyageurs"
						textContent={pHero}
					/>
				</section>

				{/* <section>
					<Partners />
				</section> */}
				<div className="mb-[60px] flex justify-center md:mb-[96px] lg:mb-[192px]">
					<div className="relative mb-[96px] w-full justify-center bg-[#F6F8FC] py-[40px] md:mx-[36px] md:rounded-[24px] lg:mx-[32px] lg:flex lg:min-h-[688px] lg:max-w-[960px] lg:py-[96px] lg:px-[80px] xl:mx-0 xl:max-w-[1320px]">
						<GrayBlock
							sectionName="Flexibilité"
							title="Optimisez votre temps & vos déplacements"
							img={grayBlockImg}
							textContent={grayBlockP}
						/>
					</div>
				</div>
				<section className="flex justify-center">
					<Management
						blueSpan="Plateforme de gestion"
						title="Une technologie pensée pour les locations de courte durée & Airbnb"
						blockContent={managementContent}
					/>
				</section>
				<section className="relative mb-[80px] bg-[#163F59] py-[60px] px-[36px] lg:mb-[128px] xl:mb-[192px]">
					<KeyCabinet
						blueSpan="Gamme Acses"
						title="Nos armoires à clés sécurisées"
						buttonId="bouton-location-obtenir-devis"
					/>
				</section>
				<section className="xl:flex xl:justify-center">
					<UiManagement buttonId="bouton-location-reserver-une-demo" />
				</section>
				<section className="mb-[32px] px-[36px] lg:flex lg:justify-center">
					<Custom />
				</section>
				<section className="mb-[40px] flex justify-center px-[36px] md:mb-[48px] lg:mb-[60pxh] xl:mb-[96px]">
					<KnowMore
						buttonId="bouton-location-contacter-un-commercial"
						buttonId2="lien-location-options-personnalisation"
					/>
				</section>
				<div>
					<NewsLetterForm />
				</div>
			</div>
		)
	);
};

export default Airbnb;
