import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../sharedComponents/Button";
import Typewriter from "../../../sharedComponents/Typewriter";
import TagManager from 'react-gtm-module'

const Hero = (props) => {
	const tagManagerArgs = {
		dataLayer: {
			event: 'click_obtenir-devis_cta',
			cta_name: 'Obtenir un devis',
			url: props.path,
		},
		dataLayerName: 'PageDataLayer'
	}
	const data = ["stockées", "sécurisées"];
	let navigate = useNavigate();
	return (
		<div className="flex w-full flex-col lg:max-w-[960px] lg:flex-row xl:max-w-[1320px] ">
			<div className="absolute left-0 top-0 hidden w-full max-w-[2500px] overflow-hidden xl:flex xl:h-auto 4xl:left-[7%]">
				<img
					className="relative -top-[55px] left-[61%] h-[80vh] lg:-top-[200px] xl:-top-[100px] xl:left-[40%] xl:h-auto xl:scale-[1] 2xl:-top-[100px] 3xl:-top-[270px]"
					src="/assets/images/home/homepage-hero-desktop-large2.webp"
					alt="- consigne à clés connectées"
					width={2203}
					height={1150}
					

				/>
			</div>

			<div className="w-full lg:max-w-[1320px]">
				<img
					className="w-full xs:hidden"
					src="/assets/images/home/homepage-hero-mobile3.webp"
					alt="- consigne à clés connectées"
					width={332}
					height={354.125}
					loading="lazy"

				/>
				<img
					className="relative top-[-60px] -z-50 hidden w-full xs:flex md:hidden"
					src="/assets/images/home/homepage-hero-tablette3.webp"
					alt="- consigne à clés connectées"
					width={503.156}
					height={432.75}
					

				/>

				{/* DESKTOP */}
				<div className="relative hidden items-center md:flex  xl:ml-0">
					<div className="absolute">
						<div className="flex flex-col justify-center px-[36px] text-center lg:px-0 xl:w-[530px]">
							<div className="mb-[20px] flex justify-center ">
								<h1 className="m-0 w-full text-left font-semibold text-[#163F59]">
									La meilleure solution <br /> pour que vos
									clés <br /> soient{" "}
									<Typewriter
										data={data}
										typeSpeed={10000}
									/>
								</h1>
							</div>
							<div className="mb-[24px] flex  justify-start">
								<p className="w-[50%] text-left font-medium text-[#506F83] lg:leading-[32px] xl:w-full xl:max-w-[500px]">
									Vous mettez à disposition des locaux, des
									biens, des véhicules mais n’êtes pas
									disponible pour la remise des clés ?
								</p>
							</div>
							<div className="flex">
								<Button
									text="Obtenir un devis"
									idBtn="bouton-home-obtenir-un-devis-header"
									action={() => {
										TagManager.dataLayer(tagManagerArgs);
										navigate('/demande-devis');
									}}
								/>
								{/*  */}
							</div>
						</div>
					</div>
					<div className="mb:hidden relative -z-50 flex w-full justify-end lg:h-[750px] xl:h-[800px]">
						<img
							className="relative -top-[95px] hidden w-[67%] md:flex lg:hidden"
							src="/assets/images/home/home-page-herotablette2.png"
							alt=""
							width={486}
							height={418}
							

						/>
						{/* <img
							className="hidden lg:flex xl:hidden relative -top-[85px] w-[70%]"
							src="/assets/images/heros/homepage-hero-desktop.svg"
							alt=""
						/> */}
					</div>
				</div>

				{/* =================================== */}
			</div>
			<div className="mx-[36px] flex-col sm:flex md:hidden md:w-[360px]">
				<div className="mb-[20px] ">
					<h1 className="m-0 w-full font-semibold text-[#163F59]">
						La meilleure solution <br /> pour que vos clés
						soient <br />
						<Typewriter data={data} typeSpeed={10000} />
					</h1>
				</div>
				<div className="md:justify mb-[24px] md:flex md:w-10/12">
					<p className="font-medium text-[#506F83]">
						Vous mettez à disposition des locaux, des biens,
						des véhicules mais n’êtes pas disponible pour la
						remise des clés ?
					</p>
				</div>
				<div className="flex">
					<button
						onClick={() => {
							TagManager.dataLayer(tagManagerArgs);
							navigate("/demande-devis");
						}}
						id="bouton-home-obtenir-un-devis"
						className="flex w-[217px] items-center justify-center rounded-[8px] bg-[#FFA945] py-[12px] pl-[16px] pr-[20px] font-bold text-white"
					>
						Obtenir un devis{" "}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Hero;
