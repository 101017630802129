import React from "react";

const Connected = () => {
	return (
		<div>
			<div className="justify-center text-left lg:flex">
				<h2 className="lg:max-w-[547px] xl:max-w-[845px]">
					Nos consignes connectées et modulaires
				</h2>
			</div>
			<div className="mb-[16px] lg:flex lg:justify-center">
				<p className="text-[20px] font-semibold leading-[28px] text-[#3798D4]  lg:text-[25px]">
					De combien de casiers avez-vous besoin ?{" "}
				</p>
			</div>
			<div className="md:flex md:gap-[36px] lg:flex lg:justify-center">
				<div className="lightblue-shadow mb-[16px] flex h-[109px] w-[223px] items-center justify-center rounded-[16px] bg-gradient-to-br from-[#3798D4] to-[#FFFFFF] p-[1px] ">
					<div className="flex h-full w-full flex-col items-center justify-center gap-y-[8px] rounded-[16px] bg-white">
						<p className="text-[28px] font-bold text-[#3798D4] ">
							5 à 150
						</p>
						<p className="text-[21px] font-semibold italic text-[#163F59]">
							casiers
						</p>
					</div>
				</div>
				<p className="z-10 text-[#506F83] md:w-[437px] md:font-normal lg:w-[384px] lg:leading-[32px]">
					Notre série de consignes KeyMaster est modulaire.
					Ajoutez jusqu’à 150 casiers supplémentaires à
					votre module de base KeyMaster 5.
				</p>
			</div>
		</div>
	);
};

export default Connected;
