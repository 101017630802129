import React from "react";
import { v4 as uuidv4 } from "uuid";

const Services = () => {
	const services = [
		{
			id: uuidv4(),
			imgScr: "assets/images/pictos/finger-print.svg",
			imgSize: "w-[36px] h-[44px]",
			widht: 36,
			height: 44,
			alt: "",
			text: "Expérience Utilisateur Unique",
		},
		{
			id: uuidv4(),
			imgScr: "assets/images/pictos/remote-hexagone.svg",
			imgSize: "w-[40px] h-[44px]",
			width: 40,
			height: 44,
			alt: "",
			text: "Interface de Gestion à Distance",
		},
		{
			id: uuidv4(),
			imgScr: "assets/images/pictos/padlock.svg",
			imgSize: "w-[32px] h-[44px]",
			width: 32,
			height: 44,
			alt: "",
			text: "Système Sécurisé",
		},
		{
			id: uuidv4(),
			imgScr: "assets/images/pictos/headphones.svg",
			imgSize: "w-[36px] h-[44px]",
			width: 36,
			height: 44,
			alt: "",
			text: "Support Client 7/7j",
		},
	];

	return (
		<div className="w-full lg:max-w-[976px] xl:max-w-[1320px]">
			<div className="flex justify-between gap-[46px] xl:gap-[min(100px,145px,169px)] ">
				{services.map((service) => (
					<div
						key={service.id}
						className="flex flex-col items-center justify-center xl:justify-start"
					>
						<div className={`${service.imgSize} mb-[18px]`}>
							{" "}
							<img
								className="w-full"
								src={service.imgScr}
								alt={service.alt}
								width={service.widht}
								height={service.height}
								loading="lazy"
							/>{" "}
						</div>
						<div className="">
							<p className="w-[200px] text-center font-semibold text-[#163F59]">
								{service.text}
							</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Services;
