import React from "react";

function Arrow({ changeDirection, isActive, ...rest }) {
	return (
		<button
			{...rest}
			className={`${
				isActive && "bg-[#FFA945]"
			} m-[7px] flex h-[42px] w-[42px] items-center justify-center rounded-[50%] border-[4px] border-[#FFA945]`}
		>
			{" "}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="18"
				fill="none"
				viewBox="0 0 18 18"
				transform-orgin="center"
				transform={changeDirection && "rotate(180)"}
			>
				<path
					fill={`${isActive ? "#FFFFFF" : "#FFA945"}`}
					d="M.748 8.697L12.28.353a.375.375 0 01.595.304v2.199c0 .478-.23.933-.619 1.214L5.441 9.001l6.815 4.931c.39.282.619.732.619 1.214v2.199c0 .305-.347.483-.595.305L.748 9.306a.377.377 0 010-.61z"
				></path>
			</svg>
		</button>
	);
}

export default Arrow;
