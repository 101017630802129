import React from "react";

import Hero from "../components/Heros";
// import Partners from "../../Home/components/Partners";
import Management from "../components/Management";
import KeyCabinet from "../components/KeyCabinet";
import UiManagement from "../components/UiManagement";
import NewsLetterForm from "../../Home/components/NewsLetterForm";
import Custom from "../components/Custom";
import KnowMore from "../components/KnowMore";
import {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
} from "./carDealerContentPage";
import GrayBlock from "../components/GrayBlock";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'

const CarDealer = (props) => {
	const tagManagerArgs = {
		dataLayer: {
			events: "info",
			page_category_1: props.path,
			page_category_2: '{{page_category_2}}'
		},
		dataLayerName: 'PageDataLayer'
	}
	TagManager.dataLayer(tagManagerArgs)
	
	return (
		{
			pHero,
			heroImgs,
			grayBlockImg,
			grayBlockP,
			managementContent,
		} && (
			<div className="pt-[81px] lg:pt-[200px] xl:pt-[240px]">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Solution innovante pour les concessionnaires automobiles | Acses</title>
					<meta name="description" content="Vous souhaitez proposer une expérience unique à vos clients ? Une borne coupe-file ? Découvrez nos consignes accessibles 24/7 👉 Cliquez-ici !"/>
				</Helmet>
				<section className="flex justify-center">
					<Hero
						bannerImg={heroImgs}
						nameSector="Concessionnaires Automobiles"
						title="Avec notre armoire à clé sécurisée, facilitez la vie de vos clients et de vos employés !"
						textContent={pHero}
						imgAlt="concessionnaire automobile"
					/>
				</section>

				{/* <section>
					<Partners />
				</section> */}
				<div className="flex justify-center mb-[60px] md:mb-[96px] lg:mb-[192px]">
					<div className="relative bg-[#F6F8FC] md:mx-[36px] lg:mx-[32px] xl:mx-0 py-[40px] lg:py-[96px] lg:px-[80px] mb-[96px] md:rounded-[24px] lg:flex justify-center w-full lg:max-w-[960px] xl:max-w-[1320px] lg:min-h-[688px]">
						<GrayBlock
							sectionName="Flexibilité"
							title="Optimisez votre temps et celui de vos clients"
							img={grayBlockImg}
							textContent={grayBlockP}
						/>
					</div>
				</div>
				<section className="flex justify-center">
					<Management
						blueSpan="Plateforme de gestion"
						title="Une technologie pensée pour les concessionnaires automobiles"
						blockContent={managementContent}
					/>
				</section>
				<section className="relative mb-[80px] lg:mb-[128px] xl:mb-[192px] py-[60px] px-[36px] bg-[#163F59]">
					<KeyCabinet
						blueSpan="Gamme Acses"
						title="Nos armoires à clés sécurisées"
						buttonId="bouton-concession-obtenir-devis"
					/>
				</section>
				<section className="xl:flex xl:justify-center">
					<UiManagement buttonId="bouton-concession-reserver-une-demo" />
				</section>
				<section className="lg:flex lg:justify-center px-[36px] mb-[32px]">
					<Custom />
				</section>
				<section className="flex justify-center px-[36px] mb-[40px] md:mb-[48px] lg:mb-[60pxh] xl:mb-[96px]">
					<KnowMore
						buttonId="bouton-concession-contacter-un-commercial"
						buttonId2="lien-concession-options-personnalisation"
					/>
				</section>
				<div>
					<NewsLetterForm />
				</div>
			</div>
		)
	);
};

export default CarDealer;
