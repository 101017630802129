import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useMediaQuery } from "react-responsive";

const Characteristics = () => {
	const displayTablette = useMediaQuery({
		query: "(min-width:768px)",
	});
	const characteristics = [
		"Acier inoxydable",
		"Serrures électroniques anti-effraction haute résistance",
		"Mise à jour à distance des données",
		"Gestion à distance des casiers",
		"Ecran tactile",
		"Traçabilité",
		"Assistance 7/7 J, de 9h à 23h",
		"Contrat de maintenance Préventive et Curative",
		"Made in France",
		"Personnalisation de votre consigne (en option)",
	];

	return (
		<div
			className={`flex flex-col gap-[24px] md:gap-[32px] md:p-[40px] lg:gap-[48px] lg:p-[60px] xl:flex-row ${
				displayTablette && "dropClair"
			}`}
		>
			<h3>Caractéristiques des Keymasters :</h3>
			<div className="grid gap-[28px] md:grid-cols-2 md:gap-[36px]">
				{characteristics.map((characteristic) => (
					<div
						key={uuidv4()}
						className="flex items-center gap-[12px]"
					>
						<img
							src="assets/images/pictos/square-checklist.svg"
							alt=""
							width={32}
							height={32}
							loading="lazy"
						/>
						<p className="font-medium leading-[24px]">
							{characteristic}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default Characteristics;
