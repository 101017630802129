import React from "react";
import { useMediaQuery } from "react-responsive";

const Card = ({ img, subtitle, title, children }) => {
	const tabletteScreen = useMediaQuery({
		query: "(min-width:768px)",
	});
	return (
		<div
			className={`${
				tabletteScreen && "tinyShadow rounded-[60px]"
			} border-b-2 border-[#D9D9D9] pb-[40px] md:border-b-0 lg:flex lg:pb-0 `}
		>
			<div className="lg:rounded-tb-none relative h-[128px] overflow-hidden rounded-[8px] md:h-[256px] md:rounded-bl-none md:rounded-br-none lg:h-auto lg:rounded-tr-none xl:w-1/2">
				<div className="relative lg:h-full">{img}</div>
				<div className="filterBlur absolute left-0 top-0 -z-10 h-[190px] w-[190px] md:-left-[200px]"></div>
				<div className="filterBlur-2 absolute right-0 -z-10 h-[190px] w-[190px]"></div>
				{/* <div className="filterBlur-red absolute top-[33.33%] md:right-[10%] right-0 w-[64px] md:w-[324px] lg:w-[111px] h-[64px] md:h-[324px] lg:h-[111px] -z-20"></div> */}
			</div>
			<div className="pt-[20px] md:px-[36px] md:py-[48px] lg:w-2/3 xl:w-1/2">
				<div>
					<p className="subtitle text-[#3798D4]">
						{subtitle}
					</p>
					<h2>{title}</h2>
				</div>
				{children}
			</div>
		</div>
	);
};

export default Card;
