import React from "react";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";

const WhyAcses = () => {
	const cardVariants = (duration) => ({
		offscreen: {
			y: -150,
			opacity: 0,
		},
		onscreen: {
			y: 0,
			opacity: 1,
			transition: {
				type: "spring",
				bounce: 0.4,
				duration: duration,
			},
		},
	});
	const fonctionalities = [
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/safe-locker.svg",
			imgStyle: "w-[44px] h-[34px]",
			alt: "",
			title: "Stockage en sécurité",
			body: "des clés et badges dans vos établissements en gestion",
			animDuration:1
		},
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/remote.svg",
			alt: "",
			title: "Programmation à distance",
			body: "via notre logiciel Acses Manager",
			animDuration:1.3
		},
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/shield.svg",

			alt: "",
			title: "Anti-effraction",
			body: "consignes en métal anti-effraction, fabriquées en France",
			animDuration:1.6
		},
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/tracking.svg",
			alt: "",
			title: "Traçabilité des clés",
			body: "et suivi en temps réel",
			animDuration:1.9
		},
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/plugin-fast.svg",
			alt: "",
			title: "Intégration rapide",
			body: "Une API ouverte facilite l’intégration à votre système",
			animDuration:2.1
		},
	];

	return (
		<div className="flex justify-center">
			{" "}
			<div className="section-content">
				<div className="mx-[36px] mb-[40px] md:mb-[36px] md:flex md:flex-col md:items-center md:text-center lg:mb-[12px]">
					<p className="subtitle mb-[8px] font-semibold text-[#3798D4]">
						Pourquoi Acses ?
					</p>
					<h2 className="font-semibold text-[#163F59] md:w-8/12 ">
						Pour automatiser la remise de vos clés 24/24h et
						7/7j
					</h2>
				</div>
				<div className="flex w-full flex-col gap-[24px] px-[36px] md:flex-row md:flex-wrap md:justify-center md:gap-[20px] xl:justify-between xl:px-0 3xl:mx-auto 3xl:w-9/12">
					{fonctionalities.map((fonctionality) => (
						<motion.div
							variants={cardVariants(fonctionality.animDuration)}
							id="card"
							key={fonctionality.id}
							initial="offscreen"
							whileInView="onscreen"
							viewport={{ once:true, amount: 1 }}
							className="shadowBox flex rounded-lg md:h-[271px] md:w-[214px] md:flex-col md:items-center md:px-[24px] md:pt-[40px] md:text-center lg:w-[243px] xl:py-[32px]"
						>
							<div className="mr-[24px] md:mr-0 md:mb-[20px] md:flex md:w-full md:justify-center">
								{" "}
								<img
									className="h-[44px] w-[44px]"
									src={fonctionality.imgSrc}
									alt={fonctionality.alt}
								/>{" "}
							</div>
							<div className="w-[199px]">
								<h3 className="mb-[5px] font-bold text-[#163F59]">
									{fonctionality.title}
								</h3>
								<p className="small-text text-[#506F83]">
									{fonctionality.body}
								</p>
							</div>
						</motion.div>
					))}
				</div>
			</div>
		</div>
	);
};

export default WhyAcses;
