import React from "react";
import Step1 from "../Step1";
import Step2 from "../Step2";
import Step3 from "../Step3";
import Step4 from "../Step4";


const StepsCustom = () => {


	return (
		<div className="flex flex-col gap-[40px] max-w-[960px] xl:max-w-[1320px]">
			<Step1 />
			<Step2 />
			<Step3 />
			<Step4 />
		</div>
	);
};

export default StepsCustom;
