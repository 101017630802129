import React, { useEffect, useState } from "react";
import hygraph from "../../@backend/hygraph";
import { RichText } from "@graphcms/rich-text-react-renderer";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import NewsLettersBlog from "./sharedComponents/NewsLettersBlog";
import SamePost from "./components/SamePost";
import { RWebShare } from "react-web-share";
import { useParams } from "react-router-dom";
dayjs.locale("fr");

export const Post = () => {
	const [post, setPost] = useState();
	const [sameTagsPosts, setSameTagsPosts]=useState();
	const {slug} = useParams()
	useEffect(() => {
		const fetchPost = async () => {
			const QUERY = `
        {
          posts(where: {slug: "${slug}"}) {
            id
            title
            tags
            slug
            date
            publishedAt
            createdAt
            coverImage {
              id
              fileName
              url
            }
            altText
            excerpt
            author {
              id
              name
              picture {
                fileName
                url
              }
            }
            content {
              json
            }
          }
        }
      `;
			const { posts } = await hygraph.request(QUERY);
			setPost(posts[0]);
		};
		fetchPost();

	}, []);

	useEffect(() => {
		const fetchSameTagsPost = async () => {
			const QUERY = `
        {
          posts(where: {tags: ${JSON.stringify(post.tags)}}) {
            id
            title
            tags
            slug
            date
            publishedAt
            createdAt
            coverImage {
              id
              fileName
              url
            }
            altText
            excerpt
            content {
              json
            }
          }
        }
      `;
			const { posts } = await hygraph.request(QUERY);
			setSameTagsPosts(posts);
		};
		post  && fetchSameTagsPost();

	}, [post]);

	

	

	const socialNetworks = [
		{
			name: "twitter",
			icon: "/assets/images/social-icons/twitter.png",
			link: "https://www.linkedin.com/in/c%C3%A9dric-schwartzenberger"
		},
		{
			name: "linkedin",
			icon: "/assets/images/social-icons/linkedin.png",
			link: "https://www.linkedin.com/in/c%C3%A9dric-schwartzenberger"
		},
		{
			name: "facebook",
			icon: "/assets/images/social-icons/facebook.png",
			link: "https://www.linkedin.com/in/c%C3%A9dric-schwartzenberger"
		},
		{
			name: "mail",
			icon: "/assets/images/social-icons/mail.png",
			link: "contact@acses.io"
		},
	];


	return (
		post && (
			<main className="flex flex-col items-center pt-[92px] lg:mt-20 overflow-hidden">
				<section className="w-full lg:max-w-[960px] xl:max-w-[1320px] px-[36px] lg:p-0">
					<div>
						<div>
							<p className="mb-4">
								{dayjs(post.publishedAt).format(
									"DD MMMM YYYY"
								)}
							</p>
							<h2>{post.title}</h2>
							<p>
								{"é".toUpperCase()}crit par{" "}
								{post.author.name}
							</p>
						</div>
						<div className="mt-9 flex w-[177.72px] justify-between lg:hidden">
							{socialNetworks.map((social) => (
								<RWebShare
									data={{
										text: "Social Network",
										url: social.link,
										title: social.name
									}}
									onClick={() =>
										console.log("shared successfully!")
									}
									className="cursor-pointer"
								>
									<img
										key={social.name}
										src={social.icon}
										alt={social.name}
										className="cursor-pointer"
									/>
								</RWebShare>
							))}
						</div>
					</div>
					<div className="mb-[60px] lg:flex lg:gap-[48px]">
						<article className="mt-[60px] max-w-[982px]">
							<div className="max-h-[411px] w-full max-w-[981px] overflow-hidden rounded-2xl">
								<img
									src={post.coverImage?.url}
									alt={post.alt}
									className="w-full object-cover"
								/>
							</div>
							<RichText
								content={post.content.json}
								renderers={{
									h5: ({ children }) => (
										<h2 className="text-blue mb-2 mt-12 text-lg font-normal">
											{children}
										</h2>
									),
									bold: ({ children }) => (
										<strong >{children}</strong>
									),
									a: ({ children, href }) => (
										<a
										  href={href}	
										  rel="noreferrer"
										  className="text-[#3798D4]"
										>
										  {children}
										</a>
									  ),
								}}
							/>
						</article>
						<div className="mt-[60px]">
							<div className="hidden w-[177.72px] justify-between lg:flex">
							{socialNetworks.map((social) => (
								<RWebShare
									data={{
										text: "Social Network",
										url: social.link,
										title: social.name
									}}
									onClick={() =>
										console.log("shared successfully!")
									}
									>
									<img
										key={social.name}
										src={social.icon}
										alt={social.name}
										className="cursor-pointer"
									/>
								</RWebShare>
							))}
							</div>
						</div>
					</div>
					<div className="mb-[96px]">
						<hr className="mb-[24px] border-[#D7EBF7] md:mb-[39px]" />
						<div className="flex gap-[22px]">
							<div className="h-[81px] w-[81px] overflow-hidden">
								<img
									src="/assets/images/blog/avatar.png"
									alt=""
								/>
							</div>
							<div>
								<h3>Cylia</h3>
								<p className="small-text text-[#506F83]">
									Responsable Marketing
								</p>
							</div>
						</div>
					</div>
				</section>
				<div className="px-[36px]">
				<NewsLettersBlog />
				</div>
				<div className="flex w-full justify-center bg-[#F6F8FC] px-[36px] lg:px-[32px] py-[60px]">
					<div className="w-full lg:max-w-[960px] xl:max-w-[1320px]">
						<h2 className="mb-[48px] md:mb-[60px] xl:mb-[48px]">
							Articles similaires
						</h2>
						<div className="flex flex-col flex-wrap gap-[48px] md:gap-y-[60px] lg:gap-[29px] xl:gap-[32px] md:flex-row md:justify-between lg:flex-nowrap">
							{sameTagsPosts?.map(post=> (<SamePost post={post} />))}
						</div>
					</div>
				</div>
			</main>
		)
	);
};
