import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { NavLink, useNavigate } from "react-router-dom";
import Arrow from "./Arrow";
import { gsap } from "gsap";
import { Draggable } from "gsap/dist/Draggable";

gsap.registerPlugin(Draggable);

const ActivityArea = () => {
	const carouselRef = useRef();
	const [carouselSlideCount, setCarouselSlideCount] =
		useState(0);
	const [activityImg, setActivityImg] = useState(
		"concessionnaire.webp"
	);

	const navigate = useNavigate();
	const activityArea = [
		{
			id: uuidv4(),
			imgSrc: "hotellerie.webp",
			title: "Hôtellerie",
			body: "Offrez plus de flexibilité à vos clients et à vos équipes ! Check-in et check-out sans contact 24/24h et 7/7J.",
			link: "/hotellerie",
			alt: "hôtellerie : check-in check-out 24/7",
		},
		{
			id: uuidv4(),
			imgSrc: "airbnb.webp",
			title: "Airbnb & location courte durée",
			body: "Simplifiez le séjour de vos clients en leur donnant accès à une expérience automatisée de récupération de clés.",
			link: "/airbnb-locations-courte-duree",
			alt: " airbnb : 'expérience automatisée de récupération de clés'",
		},
		{
			id: uuidv4(),
			imgSrc: "immobilier.webp",
			title: "Immobilier",
			body: "Optimisez à distance la gestion de votre parc immobilier.",
			link: "/immobilier",
			alt: "immobilier : gestion à distance de votre parc immobilier",
		},
		{
			id: uuidv4(),
			imgSrc: "services-aux-entreprises.webp",
			title: "Service aux entreprises",
			body: "Contrôlez les accès à vos locaux et tracez facilement les transferts de responsabilité.",
			link: "/service-aux-entreprises",
			alt: "services : contrôle d'accès sécurisé à vos locaux",
		},
		{
			id: uuidv4(),
			imgSrc: "concessionnaire.webp",
			title: "Concessionnaires automobiles",
			body: "Différenciez-vous en proposant un service 24/7 à vos clients.",
			link: "/concessionaires-automobiles",
			alt: "concessionnaires auto : suivi et traçabilité des clés pour votre flotte",
		},
		{
			id: uuidv4(),
			imgSrc: "gestionnaire-de-flotte.webp",
			title: "Gestionnaires de flotte automobile",
			body: "Pilotez à distance votre flotte automobile : réservations 24/7, traçabilité des clés et suivi des échanges.",
			link: "/gestionnaires-flotte-automobile",
			alt: "gestionnaires de flotte : traçabilité et suivi des échanges de clés",
		},
	];

	useEffect(() => {
		carouselRef.current &&
			Draggable.create(carouselRef.current, {
				type: "left,x",
				edgeResistance: 0.64,
				inertia: true,
				bounds: { width: 270, left: 0 },
			});
	}, [carouselRef]);

	return (
		<div className="flex relative w-full justify-center lg:max-w-[960px] xl:max-w-[1320px] xl:bg-transparent">
			<div className="w-full lg:max-w-[1320px] xl:hidden">
				<div className="mb-[20px] flex">
					<div className="md:w-1/2 lg:w-auto">
						<p className="subtitle mb-[8px] font-semibold text-[#3798D4]">
							Secteurs d’activité
						</p>
						<h2 className="font-semibold text-[#163F59]">
							Un outil pensé pour votre métier
						</h2>
					</div>
					<div className="mb-[20px] hidden items-end justify-end gap-[14px] pr-[32px] md:w-1/2 lg:flex">
						{" "}
						<Arrow
							isActive={carouselSlideCount > 0}
							onClick={() => {
								if (carouselSlideCount > 0) {
									setCarouselSlideCount(
										(prevState) => prevState - 1
									);
									gsap.to(carouselRef.current, {
										x: "+=300px",
									});
								}
							}}
						/>{" "}
						<Arrow
							changeDirection={true}
							isActive={carouselSlideCount <= 5}
							onClick={() => {
								if (carouselSlideCount <= 5) {
									setCarouselSlideCount(
										(prevState) => prevState + 1
									);
									gsap.to(carouselRef.current, {
										x: "-=300px",
									});
								}
							}}
						/>{" "}
					</div>
				</div>
				<div className="flex overflow-hidden">
					<div className="flex absolute" ref={carouselRef}>
						{activityArea.map((activity) => (
							<div
								key={activity.id}
								className="mr-[24px] w-[241px] md:w-[296px] lg:mr-[32px] lg:w-[337px]"
							>
								<div className="mb-[20px] md:hidden">
									<img
										src={`/assets/images/activitySector/MOBILE/${activity.imgSrc}`}
										alt=""
										width={241}
										height={318.7}
										loading="lazy"
									/>
								</div>
								<div className="mb-[20px] hidden md:flex lg:hidden">
									<img
										src={`/assets/images/activitySector/TABLETTE/${activity.imgSrc}`}
										alt=""
										width={296}
										height={391}
										loading="lazy"

									/>
								</div>
								<div className="mb-[20px] hidden lg:flex">
									<img
										src={`/assets/images/activitySector/MEDIUM/${activity.imgSrc}`}
										alt=""
										loading="lazy"

									/>
								</div>

								<div className="">
									<p className="mb-[8px] font-bold text-[#163F59]">
										{activity.title}
									</p>
									<p className="small-text mb-[20px] font-medium text-[#506F83]">
										{activity.body}
									</p>
									<button
										className="flex items-center font-bold text-[#3798D4]"
										onClick={() => navigate(activity.link)}
									>
										En savoir plus{" "}
										<svg
											className="ml-[10px]"
											width="6"
											height="10"
											viewBox="0 0 6 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1 9L5 5L1 1"
												stroke="#3798D4"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			{/* DESKTOP */}
			<div className="mx-auto hidden w-full justify-between lg:max-w-[1320px] xl:flex">
				<div className="w-full xl:max-w-[750px]">
					<div className="mb-[45px] xl:w-[634px]">
						<p className="subtitle mb-[8px] font-semibold text-[#3798D4]">
							Secteurs d’activité
						</p>
						<h2 className="font-semibold text-[#163F59] md:w-full">
							Un outil pensé pour votre métier
						</h2>
					</div>

					<div className="flex w-fit flex-wrap gap-x-[45px]">
						{activityArea.map((activity) => (
							<div
								onMouseEnter={() =>
									setActivityImg(activity.imgSrc)
								}
								key={activity.id}
								className="mr-[24px] mb-[36px] w-[304px] lg:mr-[32px]"
							>
								<div>
									<p className="mb-[8px] font-bold text-[#163F59] xl:leading-[24px]">
										{activity.title}
									</p>
									<p className="mb-[8px] font-medium text-[#506F83] xl:leading-[24px]">
										{activity.body}
									</p>
									<NavLink
										className="flex items-center font-bold text-[#3798D4] hover:text-[#3184B7]"
										to={activity.link}
									>
										En savoir plus{" "}
										<img
											className="ml-[10px]"
											src="assets/images/pictos/arrow-link-blue.svg"
											alt=""
											width={4}
											height={8}
										/>{" "}
									</NavLink>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="h-[806px] w-[531px]">
					<img
						className="h-full w-auto"
						src={`assets/images/activitySector/LARGE/${activityImg}`}
						alt="homme en costume"
						width={531}
						height={806}
						loading="lazy"

					/>
				</div>
			</div>
		</div>
	);
};

export default ActivityArea;
