import React from "react";
import "./App.css";
import Router from "./routeConfig/Router";
import TagManager from "react-gtm-module";
import { CookiesContext } from "CookiesContext";
import { useContext } from "react";
import CookiesBanner from "./sharedComponents/CookiesBanner/CookiesBanner";

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GTMID,
	// auth: 'HRlZRwHpRy0EDLVsf6_opg',
	// preview: 'env-10',
	dataLayerName: "PageDataLayer",
};

function App() {
	const cookiesChoice = useContext(CookiesContext);
	cookiesChoice
		? TagManager.initialize(tagManagerArgs)
		: TagManager.initialize({});
	return (
		<div className="App">
			<CookiesBanner />
			<Router />
		</div>
	);
}

export default App;
