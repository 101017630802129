import React from "react";
import Hero from "../components/Heros";
// import Partners from "../../Home/components/Partners";
import Management from "../components/Management";
import KeyCabinet from "../components/KeyCabinet";
import UiManagement from "../components/UiManagement";
import NewsLetterForm from "../../Home/components/NewsLetterForm";
import Custom from "../components/Custom";
import KnowMore from "../components/KnowMore";
import {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
} from "./hotellerieContentPage";
import GrayBlock from "../components/GrayBlock";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'
const Hotellerie = (props) => {
	const tagManagerArgs = {
		dataLayer: {
			events: "info",
			page_category_1: props.path,
			page_category_2: '{{page_category_2}}'
		},
		dataLayerName: 'PageDataLayer'
	}
	TagManager.dataLayer(tagManagerArgs)
	
	return (
		<div className="pt-[81px] lg:pt-[200px] xl:pt-[240px]">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Hôtellerie - Accueil 24/7 de vos clients
				</title>
				<meta
					name="description"
					content="Vous souhaitez augmenter la satisfaction de vos clients ? Vous différencier de la concurrence ? Découvrez nos solutions connectées et personnalisables !"
				/>
			</Helmet>
			<section className="flex justify-center">
				<Hero
					bannerImg={heroImgs}
					nameSector="Hôtellerie"
					title="Accueil 24/7 de vos clients"
					textContent={pHero}
				/>
			</section>
			{/* <section>
				<Partners />
			</section> */}
			<div className="section- flex justify-center ">
				<div className="relative bg-[#F6F8FC] md:mx-[36px] lg:mx-[32px] xl:mx-0 py-[40px] lg:py-[96px] lg:px-[80px] mb-[96px] md:rounded-[24px] lg:flex justify-center w-full lg:max-w-[960px] xl:max-w-[1320px]">
					<GrayBlock
						sectionName="Flexibilité"
						title="Arrivées tardives & last minute"
						img={grayBlockImg}
						textContent={grayBlockP}
					/>
				</div>
			</div>
			<section className="flex justify-center">
				<Management
					blueSpan="Plateforme de gestion"
					title="Une technologie pensée pour le secteur de l’hôtellerie"
					blockContent={managementContent}
				/>
			</section>
			<section className="relative mb-[80px] lg:mb-[128px] xl:mb-[192px] py-[40px] lg:py-[96px] xl:py-[128px] px-[36px] lg:px-[32px] bg-[#163F59]">
				<KeyCabinet
					blueSpan="Gamme Acses"
					title="Nos armoires à clés sécurisées"
					buttonId="bouton-hotellerie-obtenir-devis"
				/>
			</section>
			<section className="xl:flex xl:justify-center">
				<UiManagement buttonId="bouton-hotellerie-reserver-une-demo" />
			</section>
			<section className="flex justify-center px-[36px] mb-[32px] ">
				<Custom />
			</section>
			<section className="flex justify-center px-[36px] mb-[40px] md:mb-[48px] lg:mb-[60pxh] xl:mb-[96px]">
				<KnowMore
					buttonId="bouton-hotellerie-contacter-un-commercial"
					buttonId2="lien-hotellerie-options-personnalisation"
				/>
			</section>
			<div>
				<NewsLetterForm />
			</div>
		</div>
	);
};

export default Hotellerie;
