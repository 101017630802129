import React from 'react';
import { GraphQLClient } from 'graphql-request';

const hygraph = new GraphQLClient(
  process.env.REACT_APP_GRAPHQL_REQUEST,
  {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_GRAPHCMS_TOKEN}`
    }
  }
);

export default hygraph;