import React, { useRef } from "react";
import {
	motion,
	useScroll,
	useSpring,
} from "framer-motion";
import "./Blue.scss";

const BlueLine = () => {
	const ref = useRef(null);
	const { scrollYProgress } = useScroll({
		target: ref.current,
		offset: ["1300px start", "0.9 0.8"],
	});

	return (
		<motion.svg
			ref={ref}
			id="svg"
			xmlns="http://www.w3.org/2000/svg"
			width="108"
			height="3328"
			viewBox="0 0 108 3328"
			className="absolute left-[calc(50vw-92px)] right-0 -z-[1] h-full lg:left-[calc(50vw-72px)]"
		>
			<motion.path
				stroke="url(#paint0_linear_0_1)"
				strokeWidth="2"
				id="theLine"
				style={{ pathLength: scrollYProgress }}
				className="line translate-y-[120px] scale-x-[1] scale-y-[1.01] lg:translate-y-[-50px]"
				d="M56 0L56 3322"
			></motion.path>
			<path
				className="lg:-translate-y-[60px]"
				fill="url(#paint1_linear_0_1)"
				d="M0 0H107V192H0z"
			></path>
			<path
				fill="url(#paint2_linear_0_1)"
				d="M108 3328H215V3520H108z"
				transform="rotate(-180 108 3328)"
			></path>
			<path
				fill="url(#paint3_linear_0_1)"
				className="lg:translate-y-[30px]"
				d="M67.865 3269.78l-2.93-1.71-26.308 15.25V3301l12.733 7.24v-9.91l-2.96-1.73-1.278-.74v-8.02l7.145-4.2 6.915 4.18v8.04l-4.384 2.55v9.9l12.874-7.31v-17.65l-7.364-4.43 2.399-1.41 7.259 4.21v20.75l-17.814 10.37-17.818-10.37v-20.75l23.37-13.29 2.952-1.68-2.947-1.71-5.213-3.04L28 3277.04v30.13L54.152 3322l26.153-14.83v-30.15l-12.44-7.24zm9.47 35.67l-23.183 13.16-23.186-13.16v-26.71l23.53-13.35 2.288 1.32-23.42 13.32v24.12l20.788 12.09 20.784-12.09v-24.12l-10.216-5.91-2.924 1.71-2.348 1.36-2.893 1.69 10.152 6.09v14.33l-6.948 3.94v-3.17l4.394-2.55v-11.34l-9.863-5.97-10.138 5.95v11.38l4.247 2.47v3.15l-6.801-3.86V3285l23.342-13.53 12.394 7.23v26.75z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_0_1"
					x1="54.5"
					x2="79.439"
					y1="0"
					y2="0.187"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#2DBBED"></stop>
					<stop offset="1" stopColor="#2972B8"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_0_1"
					x1="53.5"
					x2="53.5"
					y1="0"
					y2="192"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.505" stopColor="#fff"></stop>
					<stop
						offset="1"
						stopColor="#fff"
						stopOpacity="0"
					></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_0_1"
					x1="161.5"
					x2="161.5"
					y1="3328"
					y2="3520"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.505" stopColor="#fff"></stop>
					<stop
						offset="1"
						stopColor="#fff"
						stopOpacity="0"
					></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_0_1"
					x1="80.305"
					x2="28"
					y1="3292"
					y2="3292"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#2970B7"></stop>
					<stop offset="1" stopColor="#2DBCEE"></stop>
				</linearGradient>
			</defs>
		</motion.svg>
	);
};

export default BlueLine;
