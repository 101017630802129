import React from "react";

const Genesis = () => {
	return (
		<>
			<div className="md:hidden">
				<h2 className="mb-[16px]">
					A l’origine de cette aventure
				</h2>
				<div>
					<p className="mb-[16px] font-bold text-[#163F59]">
						Fort d’une expérience de gestionnaire de projets
						Intégration Logiciel chez Capgemini, d’un MBA en
						stratégie d’entreprise, Cédric Schwartzenberger
						s'est rapidement intéressé à l'entreprenariat.
					</p>
					<p className="mb-[16px] font-medium text-[#163F59]">
						{" "}
						En 2015, il crée la start-up Monkey Locky, un
						service d’échange de clés, et connaît rapidement
						le succès. En 2019, il crée Acses qui en 2022
						commence son développement à l’international.
					</p>
					<p className="mb-[16px] font-medium text-[#163F59]">
						Cédric Schwartzenberger est attaché à des
						principes forts tels que la confiance,
						l’autonomie, le made in France et le respect de
						ses différents partenaires.
					</p>
				</div>
			</div>

			<div className="tinyShadow relative hidden bg-white md:relative md:-top-[200px] md:block md:p-[36px] lg:min-h-[472px] lg:max-w-[1320px] lg:overflow-hidden lg:pl-[193px] xl:min-h-[596px] xl:p-[80px] xl:pl-[563px]">
				<h2 className="mb-[16px] ">
					A l’origine de cette aventure
				</h2>
				<div className="absolute left-0 hidden lg:flex xl:-left-[1px] xl:-bottom-[2px]">
					<img
						className="xl:hidden"
						src="assets/images/ourStory/cedric.webp"
						alt="Cédric Schwartzenberger fondateur d'Acses"
						loading="lazy"
					/>
					<img
						className="hidden xl:flex"
						src="assets/images/ourStory/cedric-desktop-large.webp"
						alt="Cédric Schwartzenberger fondateur d'Acses"
						
					/>
				</div>
				<div>
					<p className="mb-[16px] font-bold">
						Fort d’une expérience de gestionnaire de projets
						Intégration Logiciel chez Capgemini, d’un MBA en
						stratégie d’entreprise, Cédric Schwartzenberger
						s'est rapidement intéressé à l'entreprenariat.
					</p>
					<p className="mb-[16px] font-medium">
						{" "}
						En 2015, il crée la start-up Monkey Locky, un
						service d’échange de clés, et connaît rapidement
						le succès. En 2019, il crée Acses qui en 2022
						commence son développement à l’international.
					</p>
					<p className="mb-[16px] font-medium">
						Cédric Schwartzenberger est attaché à des
						principes forts tels que la confiance,
						l’autonomie, le made in France et le respect de
						ses différents partenaires.
					</p>
				</div>
			</div>
		</>
	);
};

export default Genesis;
