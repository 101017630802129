import React from "react";
import { Link } from "react-router-dom";
import NewsLetterForm from "../Home/components/NewsLetterForm";

const Page404 = () => {
	return (
		<div
			style={{
				backgroundImage:
					"url('assets/images/page404/background.webp')",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
			className="pt-[116px] md:pt-[140px] lg:pt-[244px]"
		>
			<div className="mb-[60px] flex flex-col items-center md:mb-[145px] lg:mb-[128px] xl:mb-[264px]">
				<h1 className="text-center lg:w-[952px] xl:w-[864px]">
					La page que vous demandez est introuvable.
				</h1>
				<Link
					to="/plan-site"
					className="text-[17px] font-bold text-[#3798D4] lg:text-[20px]"
				>
					Consultez le plan du site
				</Link>
			</div>
			<div className="w-full">
				<NewsLetterForm />
			</div>
		</div>
	);
};

export default Page404;
