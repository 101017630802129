import React from "react";

export const ContactCards = ({
	imageStyle,
	imageScr,
	message,
	contactInfo,
	className,
}) => {
	return (
		<div className={`${className} border1 rounded-lg border-2`}>
			<div className="mr-[20px]">
				<img
					className={imageStyle}
					src={imageScr}
					alt=""
					width={38}
					height={45}
					loading="lazy"
				/>
			</div>
			<div>
				<p className="small-text text-[#506F83]">
					{message}
				</p>
				<p className=" font-semibold text-[#163F59]">
					{contactInfo}
				</p>
			</div>
		</div>
	);
};
