import React, { useContext, useState } from "react";
import Button from "../Button";
import "./CookiesBanner.css";
import { CookiesDispatchContext } from "CookiesContext";

const CookiesBanner = () => {
	const [display, setDisplay] = useState(false);
	const dispatch = useContext(CookiesDispatchContext)

	const handleAcceptCookies = () => {
		setDisplay(false);
		dispatch({payload:true})
	};

	const handleRefuseCookies = () => {
		setDisplay(false);
		dispatch({payload:false})

	};

	return (
		display && (
			<div className="overlay">
				<div className="banner">
					<h6>
						Notre site utilise des cookies pour garantir son
						bon fonctionnement, optimiser ses performances
						techniques et mesurer son audience. Vous pouvez
						à tout moment retirer votre consentement en
						cliquant sur "refuser".
					</h6>
					<div className="btnContainer">
						<div>
							<Button
								action={handleAcceptCookies}
								text="Accepter les cookies"
							/>
						</div>
						<div>
							<Button
								action={handleRefuseCookies}
								reverseStyle
								text="refuser"
							/>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default CookiesBanner;
