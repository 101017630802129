import React from "react";
import { v4 as uuidv4 } from "uuid";

const PartnersBanner = () => {
  const partners = [
    {
      id: uuidv4(),
      imgScr: "europcar.svg",
      alt: "Europecar",
      style: "w-[123px] h-[30px] place-self-center md:place-self-start",
    },
    {
      id: uuidv4(),
      imgScr: "renault.svg",
      alt: "Renault",
      style: "w-[100px] h-[36px]  place-self-center",
    },
    {
      id: uuidv4(),
      imgScr: "sncf.svg",
      alt: "sncf",
      style: "w-[82px] h-[43px] place-self-center",
    },
    {
      id: uuidv4(),
      imgScr: "la-poste.svg",
      alt: "Renault",
      style: "w-[100px] h-[36px] place-self-center md:hidden",
    },
    {
      id: uuidv4(),
      imgScr: "carrefour.svg",
      alt: "Carrefour",
      style: "w-[64px] h-[42px] place-self-center",
    },
    {
      id: uuidv4(),
      imgScr: "sixt.svg",
      alt: "Sixt",
      style: "w-[86px] h-[43px] place-self-center",
    },
    {
      id: uuidv4(),
      imgScr: "ratp.svg",
      alt: "Ratp",
      style: "w-[50px] h-[50px] place-self-center md:place-self-end",
    },
    {
      id: uuidv4(),
      imgScr: "maeva-home.svg",
      alt: "Renault",
      style: "w-[130px] h-[36px] place-items-center place-self-center md:hidden",
    },
  ];
  return (
    <div className="flex justify-center w-full lg:max-w-[960px] xl:max-w-[1320px]">
      <div className="grid grid-cols-2 gap-[36px] md:flex justify-between items-center w-full" >
        {partners.map((partner) => (
          <div key={partner.id} className={partner.style}>
            <img className="w-full" src={`assets/images/logos/partners/${partner.imgScr}`} alt={partner.alt} />{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersBanner;
