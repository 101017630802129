import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../sharedComponents/Button";

const KeyMaster = ({
	textButton,
	buttonId,
	title,
	ability,
	imgSrc,
	imgAlt,
	isFamous,
}) => {
	let navigate = useNavigate();
	return (
		<div className="relative md:w-[289px] md:py-[48px] lg:mb-0 lg:w-[296px] xl:w-[418px] ">
			<div className="relative mb-[26px] w-full">
				<img
					className="w-full"
					src={imgSrc}
					alt={imgAlt}
					width={289}
					height={178}
					loading="lazy"
				/>
			</div>
			<div className="mb-[4px] flex justify-center">
				{isFamous ? (
					<p className="h-[32px] rounded-[16px] bg-gradient-to-r from-[#2DBBED] to-[#2972B8] bg-clip-text text-center font-semibold text-transparent">
						Le + populaire
					</p>
				) : (
					<p className="h-[32px] rounded-[16px] bg-gradient-to-r from-[#2DBBED] to-[#2972B8] bg-clip-text text-center font-semibold text-transparent"></p>
				)}
			</div>
			<div className="mb-[16px] flex justify-center">
				<p className="mr-[10px] font-bold text-[#163F59]">
					{title}
				</p>
				<p className="text-[#163F59]">{ability}</p>
			</div>
			<div className="flex w-full justify-center">
				<div className="mb-[36px] flex w-[258px] justify-around">
					<div className="flex flex-col items-center justify-center">
						<p className="small-text text-[#8E9BA4]">
							Largeur
						</p>
						<p className="font-bold text-[#163F59] ">
							25cm
						</p>
					</div>
					<div className="flex flex-col items-center justify-center">
						<p className="small-text text-[#8E9BA4]">
							Hauteur
						</p>
						<p className="font-bold text-[#163F59]">70cm</p>
					</div>
					<div className="flex flex-col items-center justify-center">
						<p className="small-text text-[#8E9BA4]">
							Profondeur
						</p>
						<p className="font-bold text-[#163F59]">70cm</p>
					</div>
				</div>
			</div>
			<div className="flex justify-center">
				<Button
					text={textButton}
					idBtn={buttonId}
					action={() => navigate("/demande-devis")}
				/>
			</div>
		</div>
	);
};

export default KeyMaster;
