import React from "react";
import ContactInfos from "./components/ContactInfos";
import Form from "./components/Form";
import Hero from "./components/Hero.js";
import NewsLetterForm from "../Home/components/NewsLetterForm";
import { useMediaQuery } from "react-responsive";
import {Helmet} from "react-helmet"

const Index = () => {
  const tabletteScreen = useMediaQuery({ query: "(min-width:768px)" });
  const desktopScreen = useMediaQuery({ query: "(min-width:1024px)" });
  const desktopLargeScreen = useMediaQuery({ query: "(min-width:1376px)" });

  const backgroundStyle = {
    backgroundImage: `url("/assets/images/contact-us/${
      (desktopLargeScreen && "background-desktop-large.svg") ||
      (desktopScreen && "background-desktop.svg") ||
      (tabletteScreen && "background-tablette.svg") ||
      (!desktopScreen && !desktopLargeScreen && !tabletteScreen && "background-mobile.svg")
    }")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: desktopScreen || desktopLargeScreen ? "cover" : "contain",
  };
  return (
    <>
      <div className="flex justify-center pt-[124px] md:pt-[120px] lg:pt-[270px] bg-[center_top_75px] md:bg-[length:562.23px_491.62px] lg:bg-[length:1024px_auto] xl:bg-[length:auto_auto]  md:bg-[top_80px_right_-80px] lg:bg-[center_top_55px] xl:bg-[center_top_25px]" style={backgroundStyle}>
        <Helmet>
        <meta charSet="utf-8" />
					<title>
          Contactez-nous ! - Acses, gestion et protection de vos clés
					</title>
					<meta
						name="description"
						content="Start-up spécialisée dans le contrôle d'accès et les consignes sécurisées. Contactez-nous pour obtenir une démo ou un devis."
					/>
        </Helmet>
        <div className="lg:grid lg:grid-cols-3 xl:grid-cols-2 mx-[36px] lg:mx-[32px] lg:max-w-[960px] xl:max-w-[1320px]">
          <Hero />
          <Form />
          <ContactInfos />
        </div>
      </div>
      <NewsLetterForm parentStyleCustom="" />
    </>
  );
};

export default Index;
