import React from "react";

const PlugAndPlay = () => {
	return (
		<div className="lg:text-center lg:max-w-[960px] xl:w-[869px]">
			<div className="mb-[20px]">
				<h3 className="">
					Nos consignes plug & play vous permettent
					d’échanger des clés ou d’autres objets et de les
					gérer à distance en toute facilité.{" "}
				</h3>
			</div>
			<div>
				<p>
					De cette façon, votre client ou votre
					collaborateur peut, dans une plage horaire que
					vous aurez défini, récupérer des clés dans un
					casier choisi par vos soins. Définissez une plage
					horaire 7J/7 24h/24, envoyez lui un code d’accès …
					Le tour est joué !
				</p>
			</div>
		</div>
	);
};

export default PlugAndPlay;
