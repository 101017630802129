import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../sharedComponents/Button";

const NewsLetterForm = ({ parentStyleCustom }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const [messageForm, setMessageForm] = useState("");
	const [messageStyle, setMessageStyle] = useState("");

	const onSubmit = async (dataForm) => {
		const options = {
			method: "POST",
			headers: {
				accept: "application/json",
				"content-type": "application/json",
				"api-key":
					"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",
			},
			body: JSON.stringify({
				email: dataForm?.email,
				attributes: { FNAME: null, LNAME: null },
				emailBlacklisted: false,
				smsBlacklisted: false,
				listIds: [28],
				updateEnabled: false,
				smtpBlacklistSender: [],
			}),
		};

		fetch("https://api.sendinblue.com/v3/contacts", options)
			.then((response) => response.json())
			.then((response) => {
				if (
					response.code === "invalid_parameter" ||
					"duplicate_parameter"
				) {
					setMessageForm(response.message);
					setMessageStyle("text-[#EE2D2D]");
				}
				if (response.id) {
				
						setMessageForm(
							"Souscription effectuée avec succès !"
						);
						setMessageStyle("text-[#33A620]");
					
				}
			})
			.catch((err) => console.log(err));
		reset();
	};

	return (
		<div
			className={`flex justify-center  bg-[#F6F8FC] ${parentStyleCustom} `}
		>
			<div className="mx-[36px] w-full justify-between py-[24px] lg:mx-[32px] lg:flex lg:max-w-[960px] lg:py-[40px] xl:max-w-[1320px]">
				<div className="mb-[20px] flex items-center lg:mb-0 xl:mr-[211px]">
					<h3 className="text-center text-[21.25px] font-semibold text-[#163F59] md:text-left xl:text-[34px]">
						Abonnez-vous à la Newsletter
					</h3>
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="mb-[24px] flex flex-col items-center md:items-start md:flex-row lg:mb-0 xl:items-end gap-[16px] "
				>
					<div className="w-full">
						<input
						{...register("email", {
							required: true,
							pattern:
								/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
						})}
						className=" border[#163F59] mb-[8px] flex h-[48px] w-full justify-center rounded-lg border pl-[16px] text-[#506F83] md:mb-0 md:mr-[8px] lg:min-w-[354px] xl:max-w-[426px]"
						placeholder="Entrez votre adresse mail"
					/>
					{errors?.helpMotif?.type === "required" && (
						<p className="text-[#EE2D2D]">
							L' adresse email n'est pas conforme
						</p>
					)}
						<span
					className={
						messageStyle +
						" mb-[36px] mt-[8px] font-semibold leading-[24px]"
					}
				>
					{messageForm}
				</span>
					</div>
					

					<Button
						type="submit"
						text="Envoyer"
						idBtn="footer-newsletter"
						addStyle="w-full md:max-w-[103px] lg:mb-auto"
					/>
				</form>
				<span
					className={
						messageStyle +
						"mb-[36px] mt-[8px] font-semibold leading-[24px] lg:hidden"
					}
				>
					{messageForm}
				</span>
			</div>
		</div>
	);
};

export default NewsLetterForm;
