import React from "react";
import { v4 as uuidv4 } from "uuid";

const Management = ({ blueSpan, title, blockContent }) => {
	return (
		<div className="w-full lg:max-w-[960px] xl:max-w-[1320px]">
			<p className="subtitle text-[#3798D4] md:text-center font-semibold mb-[8px] px-[36px] lg:p-0">
				{blueSpan}
			</p>
			<h2 className="lg:mx-auto lg:w-[776px] xl:w-[674px] md:mb-[40px] lg:mb-[60px] md:text-center px-[36px] lg:px-0">
				{title}
			</h2>
			<div className="flex flex-col gap-[40px] xl:gap-[80px] ">
				{blockContent.map((item) => (
					<div
						className={`md:flex  px-[32px] lg:px-0`}
						key={item.title}
					>
						<div
							className={`md:flex md:w-full md:gap-[36px] ${
								item.reverse && "md:flex-row-reverse"
							} lg:gap-0 lg:justify-between xl:gap-[80px] `}
						>
							{" "}
							<div className="mb-[16px] rounded-[24px] lg:mb-0 lg:w-[446px]  xl:w-1/2 ">
								{/* <div className="bg-blue-500 w-full h-[192px] lg:h-full">
                </div> */}
								<img
									className="w-[256px] md:hidden rounded-[16px]"
									alt={item.imgAlt}
									src={item.imgSrcMobile}
									width={484}
									height={333}
								/>
								<img
									className="hidden md:flex lg:hidden rounded-[20px]"
									src={item.imgSrcTablette}
									alt={item.imgAlt}
									width={484}
									height={333}
								/>
								<img
									className="hidden lg:flex xl:hidden rounded-[25px]"
									src={item.imgSrcDesktop}
									alt={item.imgAlt}
									width={617}
									height={521}
								/>
								<img
									className="hidden xl:flex rounded-[25px]"
									src={item.imgSrcDesktopLarge}
									alt={item.imgAlt}
									width={617}
									height={521}
								/>
							</div>
							<div className="md:w-1/2 lg:w-[446px] xl:w-1/2 lg:flex flex-col justify-center">
								<h3 className="text-transparent bg-clip-text bg-gradient-to-r from-[#2DBBED] to-[#2972B8] font-bold lg:mb-[16px]">
									{item.title}
								</h3>
								<div className="">{item.textContent}</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Management;
