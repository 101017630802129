import React from "react";

const Help = () => {
	return (
		<div className="lg:max-w-[960px]">
			<div className="">
				{" "}
				<p className="subtitle md:text-center text-[#3798D4] selection:font-semibold mb-[8px] lg:min-w-[250px]">
					Assistance Acses
				</p>
				<div className="flex justify-center ">
					<h2 className="text-[33.2px] leading-[40px] lg:text-center lg:w-[947px] font-semibold lg:pl-[36px]">
						Notre équipe vous accompagne 7/7j à travers un
						numéro de hotline multilingue
					</h2>
				</div>
				<div className="mb-[36px]">
					<p className="hidden md:flex lg:text-center">
						Nous procédons à la résolution immédiate de
						votre problème par télédépannage avec prise en
						main à distance du matériel et du logiciel.
					</p>
				</div>
			</div>
			<div className="tinyShadow lg:flex lg:flex-row-reverse p-[20px] md:p-[48px] lg:gap-[54px] ">
				<div className="mb-[8px] md:mb-[20px]">
					<img
						className="md:hidden w-full lg:w-1/2"
						src="assets/images/myCustomMasterKey/woman-help.webp"
						alt=""
						loading="lazy"
					/>
					<img
						className="hidden md:flex lg:hidden w-full lg:w-1/2"
						src="assets/images/myCustomMasterKey/woman-help-tablette.png"
						alt=""
						loading="lazy"
					/>
					<img
						className="hidden lg:flex w-full lg:w-1/2"
						src="assets/images/myCustomMasterKey/woman-help-desktop.png"
						alt=""
						loading="lazy"
					/>
				</div>
				<div className="flex flex-col justify-center lg:w-1/2">
					<h3 className="text-[21.25px] lg:text-[1.25rem] leading-[28px] lg:leading-[32px] lg:mb-[20px] md:w-[360px] lg:w-[400px]">
						Et pour plus de tranquillité... Optez pour le{" "}
						<span className="text-[#2970B7]">
							contrat confort
						</span>{" "}
					</h3>
					<p className="text-[17px] lg:text-[1rem] lg:mb-[20px]">
						Nous vous proposons un contrat de maintenance
						Préventive et Curative ainsi qu'une surveillance
						monitorée du parc machine avec système
						d'alertes.
					</p>
					<p className="text-[17px] lg:text-[1rem]">
						Nous assurons l’intervention physique d'un
						technicien agréé sous 48h sur site.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Help;
