import React from "react";
import Blog, { Post } from '../pages/Blog';
import  Home from "../pages/Home"
import ContactUs from "../pages/ContactUs" ;
import AskForDemo from "../pages/AskForDemo" ;
import Solutions from '../pages/Solutions' ;
import HowItWorks from "../pages/HowItWorks" ;
import Hotellerie from "../pages/ActivityAreas/hotellerie/Hotellerie" ;
import Automobile from "../pages/ActivityAreas/automobile/Automobile" ;
import Services from "../pages/ActivityAreas/services-enterprises/Services" ;
import CarDealer from "../pages/ActivityAreas/car-deadler/CarDealer" ;
import Immobilier from "../pages/ActivityAreas/immobilier/Immobilier" ;
import AirBnB from "../pages/ActivityAreas/airbnb-&-rental/AirBnB" ;
import OurStory from "../pages/OurStory/" ;
import LegalNotice from "../pages/LegalNotice" ;
import MyCustomKeyMaster from "../pages/MyCustomKeyMaster" ;
import AskQuotation from "../pages/AskQuotation" ;
import Sitemap from "../pages/Sitemap" ;
import Page404 from "../pages/Page404";
// add the path and component(page) to render

 const allRoutes=[
 {path:"/", layoutName:"MainLayout",component: <Home path="Page d'accueil"/>},
 {path:"/contact", layoutName:"MainLayout",component: <ContactUs/>},
 {path:"/demande-demo", layoutName:"FooterOnly",component: <AskForDemo/>},
 {path:"/demande-devis", layoutName:"FooterOnly",component: <AskQuotation/>},
 {path:"/solutions-connectees", layoutName:"MainLayout",component: <Solutions/>},
 {path:"/comment-ca-marche", layoutName:"MainLayout",component: <HowItWorks/>},
 {path:"/gestionnaires-flotte-automobile", layoutName:"MainLayout",component: <Automobile/>},
 {path:"/hotellerie", layoutName:"MainLayout",component: <Hotellerie/>},
 {path:"/immobilier", layoutName:"MainLayout",component: <Immobilier/>},
 {path:"/concessionaires-automobiles", layoutName:"MainLayout",component: <CarDealer/>},
 {path:"/service-aux-entreprises", layoutName:"MainLayout",component: <Services/>},
 {path:"/airbnb-locations-courte-duree", layoutName:"MainLayout",component: <AirBnB/>},
 {path:"/notre-histoire", layoutName:"MainLayout",component: <OurStory/>},
 {path:"/mentions-legales", layoutName:"MainLayout",component: <LegalNotice/>},
 {path:"/consigne-personnalisee", layoutName:"MainLayout",component: <MyCustomKeyMaster/>},
 {path:"/plan-site", layoutName:"MainLayout",component: <Sitemap/>},
 {path:"/blog", layoutName:"MainLayout",component: <Blog/>},
 {path:"blog/:slug", layoutName:"MainLayout",component: <Post/>},
 {path:"/*", layoutName:"MainLayout",component: <Page404/>},
]

export default allRoutes