import React from "react";

const Hero = () => {
	return (
		<div className="relative mx-[36px] mb-[45px] md:flex md:flex-col md:items-center md:text-center lg:max-w-[960px] lg:items-center lg:text-center xl:max-w-[1320px]">
			<p className="subtitle mb-[8px] w-full text-[#3798D4] ">
				Nos solutions connectées
			</p>
			<h1 className="">
				Une consigne Acses adaptée à chaque entreprise
			</h1>
			<h3 className="mb-[20px] lg:text-[24px]">
				La gestion de vos clés et moyens d’accès relèvent du
				défi logistique ?{" "}
			</h3>
			<p className="leading-[32px] text-[#506F83]">
				Acses vous propose une solution simple, sûre et
				efficace pour sécuriser et digitaliser la gestion de
				vos clients et de vos collaborateurs. Quel que soit
				votre secteur d’activité, nous nous adaptons à votre
				métier.
			</p>
		</div>
	);
};

export default Hero;
