import React from "react";

const Story = () => {
	return (
		<div className="text-white lg:flex lg:max-w-[1320px] lg:items-stretch lg:gap-[32px] ">
			<div className="lg:w-1/2">
				<p className="mb-[16px] font-bold text-white ">
					Acses est une start-up de 20 ingénieurs issus de
					la french tech qui ont pour objectif commun
					d'aider les entreprises à simplifier et optimiser
					leur parcours client. Spécialisée dans le secteur
					du contrôle d’accès, avec une solution de casiers
					intelligents connectés, et pilotée par un logiciel
					SaaS, Acses a déployé depuis sa création en 2019
					plus de +500 consignes pour ses partenaires.
				</p>
				<p className="mb-[16px] font-medium text-white ">
					A l’origine d’une technologie IOT innovante, et
					développée en interne par une équipe
					pluri-disciplinaire talentueuse, Acses propose des
					produits et services qui allient un cahier des
					charges strict dans lequel la sécurité, la
					traçabilité et la connectivité sont au coeur de
					chaque projet.
				</p>
			</div>
			<div className="lg:w-1/2">
				<p className="mb-[16px] font-medium text-white ">
					Nouvelle étape ! La société parisienne a depuis
					peu aménagé dans de nouveaux ateliers
					redimensionnés, pour lui permettre
					l’industrialisation de la solution et
					d’accompagner le déploiement à l’échelle
					européenne des projets de nos clients.
				</p>
				<p className="mb-[16px] font-medium text-white ">
					{" "}
					Notre professionnalisme a été sélectionné à
					travers les appels d’offres Européens de Grands
					acteurs des marchés automobile, immobilier et
					hôtelier, qui ont validé notre solution pour
					digitaliser et sécuriser leurs parcours
					utilisateur à un niveau international.
				</p>
				<p className="mb-[16px] font-medium text-white">
					Nous restons attachés aux fondements de notre
					société et considérons que la qualité de nos
					produits, l’innovation et la bienveillance envers
					nos clients et nos équipes sont au coeur de nos
					valeurs. Notre ambition est de répondre chaque
					jour aux besoins et aux nouveaux défis de nos
					clients à l’aide de notre technologie.{" "}
				</p>
			</div>
		</div>
	);
};

export default Story;
