import React from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect } from "react";
import { v4 as uuidv4 } from "uuid";
const Security = () => {
	gsap.registerPlugin(ScrollTrigger);
	let mm = gsap.matchMedia();
	const services = [
		{
			id: uuidv4(),
			imgScr:
				"assets/images/pictos/remote-hexagone-no-gradient.svg",
			style: "w-[38px] h-[44px]",
			alt: "Connexion à distance",
			title: "Gestion à distance",
			textContent:
				"Programmez et suivez à distance les accès à vos clés via notre interface de gestion.",
		},
		{
			id: uuidv4(),
			imgScr: "assets/images/pictos/locker-no-gradient.svg",
			style: "w-[34px] h-[44px]",
			alt: "Connexion à distance",
			title: "Sécurité",
			textContent:
				"Fabriquées en métal anti-effraction, nos casiers sont équipés de serrures électroniques connectées. Déposez vos clés ou vos objets en toute sécurité !",
		},
		{
			id: uuidv4(),
			imgScr:
				"assets/images/pictos/finger-print-no-gradient.svg",
			style: "w-[36px] h-[44px]",
			alt: "Connexion à distance",
			title: "Traçabilité",
			textContent:
				"Les ouvertures de vos casiers sont enregistrées en temps réel. Pas d’erreur possible !",
		},
	];

	useLayoutEffect(() => {
		mm.add("(max-width:768px)", () => {
			gsap.from("#animText", {
				x: "-10vw",
				duration: 1,
				opacity: 0,
				scrollTrigger: {
					trigger: "#animText",
					start: "bottom bottom",
				},
			});
			gsap.from("#items", {
				x: "5vw",
				duration: 1,
				opacity: 0,
				scrollTrigger: {
					trigger: "#animText",
					start: "center bottom",
				},
				stagger: 0.2,
			});
		});

		mm.add("(min-width:768px)", () => {
			gsap.from("#animText", {
				y: 100,
				duration: 1,
				opacity: 0,
				scrollTrigger: {
					trigger: "#animText",
					start: "top bottom",
					
				},
			});
			gsap.from("#items", {
				x: "10vw",
				duration: 1,
				opacity: 0,
				scrollTrigger: {
					trigger: "#animText",
					start: "top bottom",
				},
				stagger: 0.2,
			});
		});
		return () => mm.revert();
	}, []);

	return (
		<div className="lightBoxShadow w-full gap-[40px] py-[40px] px-[36px] md:flex md:rounded-[24px] md:px-[63px] lg:max-w-[960px] lg:gap-[78px] lg:p-[60px] xl:max-w-[1320px] xl:flex-col xl:gap-[48px]">
			<div
				id="animText"
				className="text-center md:flex md:flex-col lg:items-center"
			>
				<div className="lg:w-[256px] xl:w-[602px]">
					<h2 className="text-left lg:mb-[20px] lg:text-center">
						Sécurité, traçabilité & gestion à distance
					</h2>
				</div>

				<div className="justify-center xl:flex">
					<p className="mb-[36px] text-left text-[#506F83] lg:text-center lg:leading-[32px] xl:w-[870px]">
						Sécurité, traçabilité et gestion à distance
						constituent les trois volets essentiels de notre
						gamme de casiers intelligents.
					</p>
				</div>
			</div>

			<div>
				<div className="flex flex-col gap-y-[36px] md:flex-row md:flex-wrap md:gap-x-[53px] xl:justify-center">
					{services.map((service) => (
						<div
							id="items"
							key={service.id}
							className="flex gap-[20px] md:w-[329px] lg:w-auto xl:w-[305px]"
						>
							<div
								className={`${service.style} xl:hidden `}
							>
								<img
									className="w-full"
									src={service.imgScr}
									alt={service.alt}
									width={38}
									height={44}
								/>
							</div>
							<div className="w-11/12 pt-[6px]">
								<div className="items-center xl:mb-[18px] xl:flex">
									<div
										className={`${service.style} mr-[13px] hidden xl:block `}
									>
										<img
											className="w-full"
											src={service.imgScr}
											alt={service.alt}
											width={38}
											height={44}
										/>
									</div>
									<p className="subtitle leading-[20px] text-[#3798D4] lg:mb-[8px] xl:mb-0">
										{service.title}
									</p>{" "}
								</div>

								<p className="small-text !leading-[20px] text-[#506F83] xl:w-[305px]">
									{service.textContent}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Security;
