import React from "react";

const Hero = () => {
  return (
    <div className="lg:col-span-1 xl:col-span-1">
      {" "}
      <div className="flex flex-col justify-center xl:mx-0 lg:mb-[60px] xl:mb-[96px] text-center">
        <div className="flex justify-center mb-[20px]">
          <h1 className="font-semibold text-[#163F59] text-left w-full m-0">
            Contactez-nous !
          </h1>
        </div>
        <div className="flex justify-start mb-[40px] md:w-10/12 w-full xl:max-w-[419px]">
          <p className="font-medium text-left text-[#163F59]">
            Qu'il s'agisse de questions, de suggestions, de recommandations ou
            de coopération, nous aimerions avoir de vos nouvelles, laissez-nous
            vos coordonnées, on vous rappelle !
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
