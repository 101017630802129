import React from "react";

const Hero = ({
	bannerImg,
	nameSector,
	title,
	textContent,
	imgAlt
}) => {

	return (
		<div className="w-full lg:max-w-[960px] xl:max-w-[1320px]">
			<div className="flex justify-center items-center h-[192px] md:h-[256px] lg:h-[304px] pt-[81px] md:mx-[36px] lg:mx-0 md:rounded-[16px] overflow-hidden">
				<img
					className="relative w-full lg:hidden"
					alt={imgAlt}
					src={bannerImg.mobile}
					width={360}
					height={165}
				/>
				
				<img
					className="hidden lg:flex relative w-full"
					alt={imgAlt}
					src={bannerImg.desktopLarge}
					width={2652}
					height={1782}
				/>
				
				{/* <img className="hidden md:flex w-full md:rounded-[16px]" src={bannerImg.tablette} /> */}
			</div>
			<div className="relative md:flex md:flex-col md:items-center px-[36px] lg:px-[32px] pt-[36px] lg:pt-[60px] xl:pt-[80px] lg:text-center">
				<p className="subtitle text-[#3798D4] md:text-center font-semibold mb-[8px]">
					{nameSector}
				</p>
				<h1 className="">{title}</h1>
				<div className="md:text-center">
					{textContent.map((item) => (
						<div key={item.id}>{item.text}</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Hero;
