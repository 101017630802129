import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const pHero = [
	{
		id: uuidv4(),
		text: (
			<p>
				Devenez un hôte exceptionnel ! Simplifiez le séjour
				de vos clients en leur donnant accès à une
				expérience automatisée de récupération de clés. Plus
				besoin d’être sur place, vos invités récupèrent les
				clés dans le créneau horaire que vous aurez
				programmé. Surveillez en tant réel les arrivées et
				les départs de vos clients mais aussi de vos
				prestataires externes.
			</p>
		),
	},
];

const heroImgs = {
	mobile: "assets/images/activitySector/rbnb/header.jpg",
	tablette:
		"assets/images/activitySector/rbnb/header.jpg",
	desktop:
		"assets/images/activitySector/rbnb/header.jpg",
	desktopLarge:
		"assets/images/activitySector/rbnb/header.jpg",
};

const grayBlockImg = {
	mobile:
		"/assets/images/activitySector/hotellerie/mozaique-mobile.webp",
	tablette:
		"assets/images/activitySector/hotellerie/mozaique-tablette.png",
	desktop:
		"assets/images/activitySector/hotellerie/mozaique-desktop.png",
	desktopLarge:
		"assets/images/activitySector/hotellerie/mozaique-desktop-large2.png",
};

const grayBlockP = {
	pBold:
		" Vous ne pouvez pas vous déplacer ou vous n’êtes tout simplement pas disponible le jour de la remise des clés ?",
	pTag: [
		<p>
			La gestion de logements proposés à la location
			représente souvent un sujet de préoccupation pour les
			propriétaires. Optimisez la gestion de vos clés et de
			votre calendrier grâce à nos{" "}
			<Link to="/comment-ca-marche">
				<strong>consignes électroniques sécurisées</strong>
			</Link>{" "}
			. Notre logiciel vous permet de gérer à distance les
			entrées et sorties de vos visiteurs en toute
			simplicité. Plus besoin de vous déplacer !
		</p>,
	],
};

const managementContent = [
	{
		id: uuidv4(),
		imgAlt:"comment récupérer ses clés de location airbnb",
		imgSrcMobile:
			"assets/images/activitySector/rbnb/phone-interface-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/rbnb/phone-interface-mobile-tablette.png",
		imgSrcDesktop:
			"assets/images/activitySector/rbnb/phone-interface-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/rbnb/phone-interface-desktop-large.jpg",
		title: "Dépôts & retraits de clés simplifiés",
		textContent: [
			<p>
				Grâce à notre logiciel, générez un code pour un
				casier donné. Envoyez-le à la personne de votre
				choix. Invités & prestataires externes sont
				parfaitement autonomes pour récupérer ou rendre des
				clés <strong>24/24h</strong>,<strong> 7/7J</strong>.
				Vous n’avez plus à vous déplacer.
			</p>,
		],
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
	{
		id: uuidv4(),
		imgAlt:"armoire sécurisée pour échange de clés",
		imgSrcMobile:
			"assets/images/activitySector/rbnb/interface-lockers-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/rbnb/interface-lockers-mobile-tablette.png",
		imgSrcDesktop:
			"assets/images/activitySector/rbnb/interface-lockers-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/rbnb/interface-lockers-desktop-large.jpg",
		title: "Contrôle à distance des accès",
		textContent: [
			// lien de redirection vers page FAQ à faire sur phrase "consigne sécurisée" dans balise strong
			<p>
				Contrôlez à distance les accès à votre{" "}
				<strong>consigne sécurisée</strong>. Visualisez
				l’ensemble de votre activité sur le tableau de bord
				de notre logiciel Acses Manager.{" "}
				<strong>
					Ajoutez, modifiez ou supprimer les accès aux clés
				</strong>{" "}
				où que vous soyez via <strong>un ordinateur</strong>
				, <strong>une tablette</strong> ou encore{" "}
				<strong>un smartphone</strong>.
			</p>,
		],
		reverse: true,
		widthBlock: "md:w-[452px]",
	},
	{
		id: uuidv4(),
		imgAlt:"gérer facilement des clés à distance",
		imgSrcMobile:
			"assets/images/activitySector/rbnb/woman-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/rbnb/woman-mobile-tablette.png",
		imgSrcDesktop:
			"assets/images/activitySector/rbnb/woman-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/rbnb/woman-desktop-large.jpg",
		title: "Gestion en temps réel",
		// lien de redirection vers page FAQ à faire sur phrase "Votre armoire connectée est synchronisée" dans balise strong
		textContent: (
			<>
				<p className="mb-[10px]">
					Pilotez en temps réel vos casiers via notre
					logiciel Acses Manager.
					<strong>
						{" "}
						Votre armoire connectée est synchronisée
					</strong>{" "}
					avec l’interface de gestion. Générez un code pour
					un casier. Envoyez-le à la personne de votre
					choix. Cette dernière n’a plus qu’à entrer le code
					sur l’écran de la machine et à récupérer ou
					déposer les clés.
				</p>
			</>
		),
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
];

export {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
};
