import React from "react";
import MainLayout from "./allLayouts/MainLayout";
import FooterOnly from "./allLayouts/FooterOnly";

const Layout = ({ name, children }) => {
	const layoutToRender = {
		MainLayout,
		FooterOnly,
	};

	const Layout = layoutToRender[name];
	return <Layout>{children}</Layout>;
};

export default Layout;
