import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const pHero = [
	{
		id: uuidv4(),
		text: (
			<p>
				La gestion des clés représente un réel enjeu au sein
				de l’entreprise d’autant plus qu’elle est complexe
				et chronophage. Que vous souhaitiez échanger des
				clés, contrôler des accès ou suivre les transferts
				de responsabilité, une armoire connectée peut vous
				faire gagner du temps et réduire vos coûts de
				fonctionnement.
			</p>
		),
	},
];

const heroImgs = {
	mobile:
		"assets/images/activitySector/services-entreprises/header.jpg",
	tablette:
		"assets/images/activitySector/services-entreprises/header.jpg",
	desktop:
		"assets/images/activitySector/services-entreprises/header.jpg",
	desktopLarge:
		"assets/images/activitySector/services-entreprises/header.jpg",
};

const grayBlockImg = {
	mobile:
		"assets/images/activitySector/services-entreprises/mozaique-mobile-tablette.webp",
	tablette:
		"assets/images/activitySector/services-entreprises/mozaique-mobile-tablette.webp",
	desktop:
		"assets/images/activitySector/services-entreprises/mozaique-desktop.webp",
	desktopLarge:
		"assets/images/activitySector/services-entreprises/mozaique-desktop.webp",
};

const grayBlockP = {
	pBold:
		"Vous faîtes appel à un nombre important de prestataires externes ? Pour rester compétitives, il est devenu nécessaire pour les entreprises d’externaliser un certain nombre de tâches. Au-delà de la maintenance ou du nettoyage, ce sont une multitude de services qui sont aujourd’hui proposés aux entreprises.",
	pTag: [
		<p>
			Entre personnel interne et prestataires externes, les
			clés de votre bâtiment circulent de main en main et
			peuvent être source d’erreurs. Leur perte peut avoir
			des conséquences financières, sans parler des
			autorisations administratives et des délais d’attente.
			Ne perdez plus de temps et d’argent, optimisez la
			gestion des clés de votre entreprise.
		</p>,
	],
};

const managementContent = [
	{
		id: uuidv4(),
		imgAlt: "contrôle d'accès dans l'immobilier",
		imgSrcMobile:
			"assets/images/activitySector/services-entreprises/lockers-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/services-entreprises/lockers-mobile-tablette.png",
		imgSrcDesktop:
			"assets/images/activitySector/services-entreprises/lockers-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/services-entreprises/lockers-desktop-large.jpg",
		title: "Des casiers ultra-sécurisés",
		textContent: [
			<p>
				<Link to="/comment-ca-marche">
					<span className="text-[#3798D4]">
						{" "}
						Stockage de vos clés
					</span>
				</Link>{" "}
				dans des casiers connectés allant de 5 à 150
				compartiments individuels. Fabriquées en France en
				métal anti-effraction, nos consignes sont reliées à
				une interface de gestion pilotable à distance.
				Protégez & sécurisez votre activité !
			</p>,
		],
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
	{
		id: uuidv4(),
		imgAlt:
			"contrôle d'accès et transfert des responsabilités",
		imgSrcMobile:
			"assets/images/activitySector/services-entreprises/notif-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/services-entreprises/notif-mobile-tablette.png",
		imgSrcDesktop:
			"assets/images/activitySector/services-entreprises/notif-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/services-entreprises/notif-desktop-large.jpg",
		title: "Un transfert de responsabilité",
		textContent: [
			<p>
				Suivez facilement les échanges de clés et le
				transfert de responsabilité lorsque les clés
				changent de main. Notre système vous permettra
				d’identifier le détenteur des clés à un moment T, et
				ainsi de pouvoir les récupérer.
			</p>,
		],
		reverse: true,
		widthBlock: "md:w-[452px]",
	},
	{
		id: uuidv4(),
		imgAlt: "traçabilité des clés",
		imgSrcMobile:
			"assets/images/activitySector/services-entreprises/phone-code-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/services-entreprises/phone-code-mobile-tablette.png",
		imgSrcDesktop:
			"assets/images/activitySector/services-entreprises/phone-code-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/services-entreprises/phone-code-desktop-large.jpg",
		title: "Une traçabilité en temps réel",
		textContent: [
			// Crée un lien sur "tracez les accès" vers page centre d'aide quand elle sera créée
			<p className="mb-[10px]">
				Contrôlez et{" "}
				<span className="text-[#3798D4]">
					tracez les accès
				</span>{" "}
				à vos locaux. En un coup d’oeil, visualisez
				l'ensemble de votre activité présente et passée.
				Vous savez à tout moment qui détient les clés et
				depuis combien de temps.
			</p>,
		],
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
	{
		id: uuidv4(),
		imgSrcMobile:
			"assets/images/activitySector/services-entreprises/roles-mobile-tablette.png",
		imgSrcTablette:
			"assets/images/activitySector/services-entreprises/roles-mobile-tablette.png",
		imgSrcDesktop:
			"assets/images/activitySector/services-entreprises/roles-desktop-large.webp",
		imgSrcDesktopLarge:
			"assets/images/activitySector/services-entreprises/roles-desktop-large.webp",
		title: "Flexibilité",
		textContent: (
			<>
				<p className="mb-[10px]">
					Avec une gestion fine des droits utilisateur,
					votre superviseur comme vos employés peuvent gérer
					à distance les{" "}
					<Link to="/notre-histoire">
						<span className="text-[#3798D4]">
							échanges de clés
						</span>
					</Link>
					. Automatisez facilement les transmissions de clés
					pour répondre aux variations des besoins.
				</p>
			</>
		),
		reverse: true,
		widthBlock: "md:w-[452px]",
	},
];

export {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
};
