import React from 'react'
import { Link } from "react-router-dom";

const PostsList = ({postList}) => {
    return postList?.map((post) => {
        return (
            <div
                className="post col-span-1 md:max-w-[202px]"
                key={post.id}
            >
                <Link to={`/blog/${post.slug}`}>
                    <div className="h-[75vw] overflow-hidden rounded-2xl md:h-[202px] md:w-[202px] lg:w-[208px] xl:w-[285px]">
                        <img
                            className="h-full object-cover object-center"
                            src={post.coverImage?.url}
                            alt=""
                        />
                    </div>
                    <div className="mt-3 md:mt-2">
                        <p className="article mb-0">{post.title}</p>
                    </div>
                </Link>
            </div>
        );
    });
}

export default PostsList
