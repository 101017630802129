import React from "react";
import Help from "./components/Help";
import Hero from "./components/Hero";
import Security from "./components/Security";
import StepsCustom from "./components/StepsCustom/StepsCustom";
import NewsLetterForm from "../Home/components/NewsLetterForm";
import ContactUs from "./components/ContactUs";
import { Helmet } from "react-helmet";

const MyCustomKeyMaster = () => {
	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Consignes sécurisées pour la gestion des clés -
					contrôle à distance{" "}
				</title>
				<meta
					name="description"
					content="Personnalisez votre consigne sécurisée ! Nos produits sont en marque blanche, apposez vos couleurs et votre logo. >> Découvrez nos solutions intelligentes !"
				/>
			</Helmet>
			<section className="pt-[125px] md:pt-[185px] lg:pt-[257px] xl:pt-[272px]">
				<Hero />
			</section>
			<section className="flex justify-center px-[36px]">
				<StepsCustom />
			</section>
			<section className="flex justify-center bg-[#163F59] pt-[40px] pb-[168px] md:pt-[80px] lg:pb-[66px]">
				<Security />
			</section>
			<section className="flex justify-center px-[36px] lg:px-[32px]">
				<Help />
			</section>
			<section className="mb-[62px] flex justify-center px-[36px] lg:px-[32px]">
				<ContactUs />
			</section>
			<div>
				<NewsLetterForm />
			</div>
		</div>
	);
};

export default MyCustomKeyMaster;
