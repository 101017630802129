import React from "react";
import { v4 as uuidv4 } from "uuid";

const HowToDrive = () => {
	const manageInterface = [
		{
			id: uuidv4(),
			imgSrc:
				"assets/images/pictos/interface-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Une interface simple
					</p>{" "}
					<p className="small-text !leading-[20px]">
						d’utilisation disponible sur ordinateur,
						tablette & smartphone
					</p>
				</>
			),
		},
		{
			id: uuidv4(),
			imgSrc:
				"assets/images/pictos/tableau-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Un tableau de bord
					</p>
					<p className="small-text !leading-[20px]">
						pour visualiser rapidement toute votre activité
					</p>
				</>
			),
		},
		{
			id: uuidv4(),
			imgSrc:
				"assets/images/pictos/locker2-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Une gestion facile de vos casiers :
					</p>
					<p className="small-text !leading-[20px]">
						attribuez le casier que vous souhaitez à un
						objet donné
					</p>
				</>
			),
		},
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/roles-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Une gestion avancée des rôles utilisateurs.
					</p>{" "}
					<p className="small-text !leading-[20px]">
						{" "}
						Ajoutez, modifiez ou supprimez les droits d'un
						collaborateur en toute simplicité.
					</p>
				</>
			),
		},
		{
			id: uuidv4(),
			imgSrc: "assets/images/pictos/clock-bg-lightblue.svg",
			imgStyle: "",
			text: (
				<>
					<p className="font-semibold text-[#163F59]">
						Un historique
					</p>
					<p className="small-text !leading-[20px]">
						consultable à tout moment.
					</p>
				</>
			),
		},
	];

	return (
		<div className="relative">
			<div className="mb-[48px] md:mb-[36px] md:flex md:flex-col md:items-center md:text-center">
				<p className="subtitle mb-[8px] text-[#3798D4]">
					API, CRM & PMS
				</p>
				<h2 className="mb-[52px] font-semibold text-[#163F59] md:w-8/12 ">
					Comment nos casiers connectés sont-ils pilotés ?
				</h2>
			</div>
			<div className="md:py-[48px ] mb-[36px] md:relative md:-left-[36px] md:flex md:w-[100vw] md:overflow-hidden md:px-[36px] lg:left-0 lg:w-auto lg:max-w-[1320px] lg:flex-col lg:overflow-visible lg:rounded-3xl lg:py-[80px] lg:pl-[81px]">
				<div className="flex flex-wrap gap-[24px]">
					<div className="relative -left-[36px]">
						{" "}
						<img
							src="assets/images/solutions/interface-mobile.png"
							alt="logiciel de gestion à distance"
							loading="lazy"
						/>
					</div>
					<div className="z-[50]">
						<h3 className="mb-[36px] w-[285px] text-[#163F59] md:w-[356px] lg:text-center">
							Gérez vos casiers à distance via notre
							interface de gestion
						</h3>
						<div>
							{manageInterface.map((item) => (
								<div
									key={item.id}
									className="mb-[16px] flex gap-[20px] md:items-center lg:mb-[36px]"
								>
									<div className="w-[60px]">
										<img src={item.imgSrc} alt="picto" />
									</div>
									<div className="w-[220px] md:w-[320px]">
										{item.text}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-wrap gap-[24px] md:relative md:-left-[36px] md:w-[100vw] md:overflow-hidden md:px-[36px] md:py-[48px]  lg:left-0 lg:w-auto lg:max-w-[1320px] lg:overflow-visible lg:py-[80px]">
				<div className="relative -left-[36px]">
					<img
						src="assets/images/solutions/API-mobile.png"
						alt=" API Acses : intégration rapide à votre CRM PMS"
						loading="lazy"
					/>
				</div>
				<div className="w-[287px]">
					<h3 className="text-[#163F59]">
						Vous avez déjà votre logiciel de gestion ?
						Intégrez notre API !
					</h3>
					<div className="mb-[20px] lg:-ml-[108px] lg:flex lg:flex-row-reverse lg:justify-center">
						<div className="flex flex-col gap-[16px]">
							<p className="">
								Grâce à notre API ouverte et documentée,
								connectez votre armoire à clés à votre
								logiciel interne de gestion ou votre
								plateforme de réservations. Notre API est
								compatible avec tous les DMS et CRM. Notre
								équipe technique vous accompagne dans
								l'intégration de la solution avec vos outils
								existants.
							</p>
							<p>
								Notre API est compatible avec tous les DMS
								et CRM. Notre équipe technique vous
								accompagne dans l'intégration de la solution
								avec vos outils existants.
							</p>
						</div>
					</div>
					<button className="text-start font-bold text-[#3798D4]">
						Télécharger notre documentation technique
					</button>
				</div>
				<div className="flex w-full justify-center"></div>
			</div>
		</div>
	);
};

export default HowToDrive;
