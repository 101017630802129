import React from "react";
import APIDoc from "./components/APIDoc";
import Hero from "./components/Hero";
import InterfaceLockers from "./components/InterfaceLockers";
import PlugAndPlay from "./components/PlugAndPlay";
import PlugAndPlaySteps from "./components/PlugAndPlaySteps";
import RemoteManage from "./components/RemoteManage";
import Security from "./components/Security";
import Assistance from "./components/Assistance";
import Contact from "./components/Contact";
import NewslLetterForm from "../Home/components/NewsLetterForm";
import { Helmet } from "react-helmet";
const HowItWorks = () => {
	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Gestion à distance de casiers à clés connectés |
					Acses
				</title>
				<meta
					name="description"
					content="Sécurisez vos clés grâce à nos consignes Made in France. Découvrez comment fonctionnent nos casiers sécurisés et modulaires ! >> Cliquez-ici !"
				/>
			</Helmet>
			<section className="flex justify-center px-[36px] pt-[194px] lg:px-[32px]">
				<Hero />
			</section>
			<section>
				<div className="mb-[36px] flex justify-center">
					<InterfaceLockers />
				</div>
				<div className="mb-[38px] flex justify-center px-[36px] lg:px-[32px]">
					<PlugAndPlay />
				</div>
				<div className="justify-end px-[36px] lg:flex lg:px-[32px]">
					<PlugAndPlaySteps />
				</div>
			</section>
			<section className="relative bg-[#163F59] px-[36px] pt-[36px] pb-[60px] md:min-h-[436px] lg:px-[32px] lg:pt-[96px] xl:flex xl:min-h-[692px] xl:justify-center xl:py-[128px]">
				<APIDoc />
				<div id="img" className="absolute right-0 top-[100px] hidden md:flex lg:hidden">
					<img
						className="w-[355px]"
						src="assets/images/howItWorks/phone-code-tablette.webp"
						alt=""
						width={355}
						height={308}
						loading="lazy"
					/>
				</div>
			</section>
			<section className="px-[36px] lg:flex lg:justify-center lg:px-[32px]">
				<RemoteManage />
			</section>
			<section className="px-[36px] lg:flex lg:justify-center">
				<Security />
			</section>
			<section>
				{" "}
				<div className="px-[36px] pb-[36px] xl:flex xl:justify-center">
					<Assistance />
				</div>
				<div className="px-[36px] xl:flex xl:justify-center">
					<Contact />
				</div>
			</section>

			<div>
				<NewslLetterForm />
			</div>
		</div>
	);
};

export default HowItWorks;
