import React from "react";
import NewFooter from "../../sharedComponents/NewFooter"


const MainLayout = ({ children }) => {
  return (
    <>
        <div>{children}</div>
      <NewFooter/>
    </>
  );
};

export default MainLayout;
