import React from "react";
import {
	Carded,
	ContactCards,
} from "../../../sharedComponents";

const Contact = () => {
	return (
		<>
			<div className="md:hidden">
				<div className="mb-[40px]">
					<h3 className="font-semibold text-[#163F59]">
						Vous souhaitez <br /> adopter notre solution
						pour faciliter la gestion de votre activité ?
					</h3>
				</div>
				<div className="flex flex-col items-start gap-[20px]">
					<ContactCards
						imageScr="assets/images/pictos/box.svg"
						message="Envoyer-nous un email"
						contactInfo="contact@acses.io"
						className="flex justify-start items-center w-full p-[20px]"
						imageStyle="h-[36px]"
					/>
					<ContactCards
						imageScr="assets/images/pictos/headphones.svg"
						message="Appelez-nous"
						contactInfo="09 80 80 05 51"
						className="flex justify-start items-center w-full p-[20px]"
					/>
				</div>
			</div>
			<div className="flex w-full justify-center ">
				<Carded />
			</div>
		</>
	);
};

export default Contact;
