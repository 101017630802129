import React, { useEffect } from 'react'
import ActivityArea from "./components/ActivityArea";
import AdoptSolution from "./components/AdoptSolution";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";
import NewsLetterForm from "./components/NewsLetterForm";
import Partners from "./components/Partners";
import Services from "./components/Services";
import WhyAcses from "./components/WhyAcses";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';




const Home = (props) => {
	

	const tagManagerArgs = {
		dataLayer: {
			events: "info",
			page_category_1: props.path,
			page_category_2: '{{page_category_2}}'
		},
		dataLayerName: 'PageDataLayer'
	}
	TagManager.dataLayer(tagManagerArgs)
	return (
		<div
			style={{
				backgroundImage:
					"url('/assets/images/home/page-motif-background.webp')",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "bottom",
				backgroundSize: "contain",
			}}
			className="overflow-hidden relative"
		>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Sécurité, traçabilité et gestion à distance de vos
					clés !
				</title>
				<meta
					name="description"
					content="Vous souhaitez optimiser la gestion de vos clés ? Découvrez nos consignes connectées et sécurisées en cliquant ici !"
				/>
				<link
					rel="preload"
					fetchpriority="high"
					as="image"
					href="/assets/images/home/homepage-hero-desktop-large2.webp"
					type="image/webp"
				></link>
			</Helmet>
			<section className="flex justify-center md:pt-[90px] md:mb-[50px] lg:mb-auto">
				<div className="hidden lg:flex xl:hidden absolute overflow-hidden right-0 w-[70%] top-0">
					<img
						className="relative xl:h-auto"
						src="/assets/images/home/home-hero-desktop2.webp"
						alt="- consigne à clés connectées"
						width={936.594}
						height={846.391}
						loading="lazy"
					/>
				</div>
				<Hero path={props.path}/>
			</section>
			<section className="4xl:mt-[220px]">
				<Partners />
			</section>
			<section>
				<AdoptSolution />
			</section>
			<section>
				<WhyAcses />
			</section>
			<section className="lg:flex lg:justify-center pl-[36px] lg:pl-[32px] xl:pl-0 py-[40px] lg:py-[96px] bg-[#F6F8FC] h-[740px] md:h-[758px] lg:h-[982px] xl:bg-transparent">
				<ActivityArea />
			</section>
			<section>
				<div className="hidden lg:flex justify-center px-[36px] mb-[180px] lg:mb-[60px] ">
					<Services />
				</div>
				<div className="flex justify-center mb-[96px] md:mb-[20px] px-[36px] lg:px-[32px]">
					<HowItWorks />
				</div>
				<div className="px-[36px]">
					<Contact />
				</div>
			</section>
			<NewsLetterForm />
		</div>
	);
};

export default Home;
