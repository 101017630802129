import React from "react";
import { useNavigate } from "react-router-dom";

const HowItWorks = () => {
	let navigate = useNavigate();
	return (
		<div className="flex w-full justify-center lg:max-w-[960px] xl:max-w-[1320px]">
			<div className="flex w-full flex-col gap-[20px] md:flex-row md:justify-between  lg:justify-center">
				<div className="md:w-1/2">
					<button
						onClick={() =>
							navigate("/solutions-connectees")
						}
						className="relative flex w-full items-center justify-center overflow-hidden rounded-3xl"
					>
						{" "}
						<p className="pointer-events-none absolute z-[10] font-bold leading-[41px] text-white  lg:w-1/2 xl:text-[34px] 3xl:w-full">
							Vous avez un projet ?
						</p>{" "}
						<img
							className="w-full transition-all duration-700 hover:scale-[1.3]"
							src="assets/images/home/team-work.webp"
							alt="consigne à clés connectée et sécurisée"
							width={288}
							height={166}
							loading="lazy"

						/>{" "}
					</button>
				</div>
				<div className="md:w-1/2">
					<button
						onClick={() => navigate("/comment-ca-marche")}
						className="relative flex w-full items-center justify-center overflow-hidden rounded-3xl"
					>
						{" "}
						<p className="pointer-events-none absolute z-[10] font-bold  leading-[41px] text-white xl:text-[34px] 3xl:w-full">
							{" "}
							Comment ça marche ?
						</p>{" "}
						<img
							className="w-full transition-all duration-700 hover:scale-[1.3]"
							src="assets/images/home/carte-circuit.webp"
							alt="fonctionnement d'un casier à clés sécurisé"
							width={288}
							height={166}
							loading="lazy"

						/>{" "}
					</button>
				</div>
			</div>
		</div>
	);
};

export default HowItWorks;
