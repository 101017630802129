import React from "react";

const Hero = () => {
	return (
		<div>
			<div className="relative mb-[45px] px-[36px] pt-[36px] md:flex md:flex-col md:items-start lg:items-center lg:text-center">
				<p className="subtitle mb-[8px] font-semibold text-[#3798D4]">
					Créer mon keymaster personnalisé
				</p>
				<h1 className="text-[41.5px] lg:w-[864px] lg:text-[3rem] lg:font-bold lg:leading-[78px] ">
					Personnalisez votre consigne connectée !
				</h1>

				<p className="text-[17px] lg:w-[864px] lg:text-[20px] lg:leading-[32px]">
					Personnalisez votre consigne ! Nos produits sont
					en <strong>marque blanche</strong>, apposez vos
					couleurs et votre logo. Adaptez le parcours
					utilisateur des écrans de vos machines pour
					répondre à vos besoins métier. Enfin, gérez vos
					casiers depuis notre interface Acses Manager ou
					depuis votre propre logiciel via une connexion à
					notre API.
				</p>
			</div>
		</div>
	);
};

export default Hero;
