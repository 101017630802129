import React from "react";
import { v4 as uuidv4 } from "uuid";

const GrayBlock = ({ sectionName, title, img, textContent }) => {

  return (
    <div className="lg:grid lg:grid-cols-2 relative md:flex md:flex-col lg:gap-[78px] md:items-start lg:items-center lg:py-0 lg:text-center lg:w-full">
      <div className="hidden lg:flex absolute -top-[96px] right-0 overflow-hidden">  
      <img
            className="xl:hidden relative xl:-top-[20px]"
            src={img.desktop}
            alt=""
            width={298}
            height={658}
          />
      <img
            className="hidden xl:flex relative xl:-top-0"
            src={img.desktopLarge}
            alt=""
            width={334}
            height={657}
          />
      </div>
      
      <div className="place-self-start">
        <p className="subtite text-[#3798D4] lg:text-left font-semibold px-[36px] mb-[8px] lg:p-0">
          {sectionName}
        </p>
        <h2 className="xl:w-[674px] lg:text-left px-[36px] lg:px-0">
          {title}
        </h2>
        <div className="hidden lg:w-[381px] xl:w-[677px] lg:block xl:row-start-2 lg:px-0 lg:py-0 ">
          <p className="font-bold lg:text-left lg:text-[25px] xl:leading-[32px] lg:font-semibold mb-[20px] md:font-medium">
            {textContent.pBold}
          </p>

          {textContent.pTag.map((text) => (
            <div
              key={uuidv4()}
              className="text-[#506F83] lg:text-left mb-[20px]"
            >
              {text}
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="md:pl-[40px] lg:p-0 row-span-2">
          <img className="md:hidden w-full pl-[36px]" src={img.mobile} alt="mobile" width={360} height={226} loading="lazy" />
          <img
            className="hidden md:flex lg:hidden relative -right-[0px] w-[105vw]"
            src={img.tablette}
            alt=""
            width={656}
            height={287}
            loading="lazy"
          />
        </div>
      </div>
      <div className="xl:row-start-2 px-[36px] md:px-[40px] pt-[20px] lg:px-0 lg:py-0 lg:hidden ">
        <p className="font-bold lg:text-left mb-[10px] md:font-medium">
          {textContent.pBold}
        </p>
        <div className="flex flex-col gap-[20px]">
          {textContent.pTag.map((text) => (
            <div key={uuidv4()} className="text-[#506F83] lg:text-left">
              {text}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GrayBlock;
