import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const pHero = [
	{
		id: uuidv4(),
		text: (
			<p className="mb-[16px]">
				Maximisez la satisfaction de vos clients en leur
				offrant plus de liberté !
			</p>
		),
	},
	{
		id: uuidv4(),
		text: (
			<p>
				Avec une concurrence accrue et des attentes de plus
				en plus élevées de la part des visiteurs, il ne
				suffit plus de fournir aux clients un service de
				qualité. Il est essentiel que vous différenciez
				votre expérience d'accueil de celle de vos
				concurrents, que ce soit par la qualité de votre
				produit ou le niveau de service fourni.
			</p>
		),
	},
	{
		id: uuidv4(),
		text: (
			<p>
				Grâce à nos casiers sécurisés, différenciez-vous de
				la concurrence et simplifiez la vie de vos clients
				en leur permettant une arrivée et un départ 24/7.
			</p>
		),
	},
];

const heroImgs = {
	mobile:
		"assets/images/activitySector/hotellerie/header-mobile.webp",
	tablette:
		"assets/images/activitySector/hotellerie/hotellerie-tablette.png",
	desktop:
		"assets/images/activitySector/hotellerie/hotellerie-desktop.png",
	desktopLarge:
		"assets/images/activitySector/hotellerie/header.jpg",
};

const grayBlockImg = {
	mobile:
		"assets/images/activitySector/hotellerie/mozaique-mobile.webp",
	tablette:
		"assets/images/activitySector/hotellerie/mozaique-tablette.webp",
	desktop:
		"assets/images/activitySector/hotellerie/mozaique-desktop.webp",
	desktopLarge:
		"assets/images/activitySector/hotellerie/mozaique-desktop-large2.webp",
};

const grayBlockP = {
	pBold:
		" Économisez sur les coûts opérationnels et gagnez en efficacité grâce à notre logiciel de gestion à distance. Suivez en temps réel les check-in et les check-out de vos clients.",
	pTag: [
		<p className="leading-[24px] lg:leading-[32px]">
			Supervisez plus facilement vos équipes en 3x8. Les
			clés de votre établissement circulent de main en main
			et peuvent être source d’erreurs. Plus le nombre
			d’intervenants au sein de votre structure est
			important, plus leur gestion est complexe.
		</p>,
		<p className="leading-[24px] lg:leading-[32px]">
			Simplifiez la gestion des clés et permettez à vos
			équipes de se concentrer sur les tâches à forte valeur
			ajoutée.{" "}
		</p>,
	],
};

const managementContent = [
	{
		id: uuidv4(),
		imgAlt:
			"armoire à clés pour le secteur de l'hôtellerie",
		imgSrcMobile:
			"assets/images/activitySector/hotellerie/self-check-in.webp",
		imgSrcTablette:
			"assets/images/activitySector/hotellerie/self-check-in.webp",
		imgSrcDesktop:
			"assets/images/activitySector/hotellerie/self-check-desktop-large.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/hotellerie/self-check-desktop-large.jpg",
		title: "Self check-in / check-out",
		textContent: (
			<>
				<p className="mb-[8px]">
					Augmentez la satisfaction de vos clients en leur
					offrant plus de liberté ! Vos clients ne
					patienteront plus à la réception. Ils pourront
					récupérer ou déposer les clés de leur chambre,
					appartement ou maison, à n’importe quelle heure du
					jour et de la nuit. Nos consignes offrent un écran
					de navigation intuitif qui les guidera pas à pas.
				</p>{" "}
			</>
		),
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
	{
		id: uuidv4(),
		imgAlt: "casiers sécurisés pour gérer vos clés",
		imgSrcMobile:
			"assets/images/activitySector/hotellerie/check-in-key.webp",
		imgSrcTablette:
			"assets/images/activitySector/hotellerie/check-in-key.webp",
		imgSrcDesktop:
			"assets/images/activitySector/hotellerie/check-in-key.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/hotellerie/check-in-key.jpg",
		title: "Passation des clés automatisée",
		textContent: (
			<>
				<p className="mb-[10px]">
					Une clé pour combien d’intervenants ? Un
					établissement qui fonctionne 24h/24 doit permettre
					à ses employés et à ses prestataires d’accéder à
					différentes annexes. La perte des clés équivaut à
					un déficit de temps et d’argent.
				</p>

				<p className="mb-[10px]">
					Notre solution permet de conserver les clés sur
					place, dans des
					<Link to="/solutions-connectees">
						<strong>
							casiers individuels ultra sécurisés
						</strong>
					</Link>{" "}
					et dont , l’accès peut-être facilement donné à
					distance.
				</p>
			</>
		),
		reverse: true,
		widthBlock: "md:w-[452px]",
	},
	{
		id: uuidv4(),
		imgAlt: "logiciel de gestion à distance de vos clés",
		imgSrcMobile:
			"assets/images/activitySector/hotellerie/interface-mobile-tablette.webp",
		imgSrcTablette:
			"assets/images/activitySector/hotellerie/interface-mobile-tablette.webp",
		imgSrcDesktop:
			"assets/images/activitySector/hotellerie/interface.jpg",
		imgSrcDesktopLarge:
			"assets/images/activitySector/hotellerie/interface.jpg",
		title: "Traçabilité des mouvements",
		textContent: (
			<>
				<p className="mb-[10px]">
					Suivez les mouvements des clés de votre hôtel sans
					vous déplacer ! Qui a pris les clés ? Qui est
					parti avec ? A quelle heure et quel jour ? Notre
					logiciel vous donnera toutes ces réponses en temps
					réel.
				</p>
				<p className="mb-[10px]">
					Vous souhaitez récupérer des informations précises
					et adaptées à vos besoins, un interlocuteur Acses
					vous présentera toutes les options adéquates.
				</p>
			</>
		),
		reverse: false,
		widthBlock: "md:w-[456px]",
	},
];

export {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
};
