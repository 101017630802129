import React from "react";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
	const navigate= useNavigate()
	return (
		<div className="w-full rounded-2xl bg-gradient-to-r from-[#2DBBED] to-[#2972B8] px-[30px] py-[36px] md:flex md:flex-col md:items-start md:px-[40px] md:py-[60px] lg:max-w-[960px] lg:p-[80px] xl:max-w-[1320px]">
			<div className="mb-[20px]">
				<h3 className="text-center text-white md:mb-[20px] md:text-start md:text-[33.2px] md:font-semibold">
					Notre solution vous intéresse ?
				</h3>
				<p className="text-center text-[17px] text-white md:text-start">
					Discutons-en ensemble !
				</p>
			</div>

			<div className="flex justify-center">
				<button
					onClick={()=>navigate("/contact")}
					id="bouton-nsc-contacter-commercial"
					className="flex items-center justify-center rounded-lg bg-white py-[16px] px-[30px] md:w-[379px] "
				>
					<div className=" ">
						<img
							className="mr-[20px] w-[29.33px] lg:mr-[12px]"
							src="assets/images/pictos/phone-gradient-blue.svg"
							alt="Téléphone"
							loading="lazy"
						/>
					</div>
					<div>
						<p className="bg-gradient-to-r from-[#2DBBED] to-[#2972B8] bg-clip-text font-bold text-transparent  ">
							{" "}
							Contacter un commercial
						</p>
					</div>
				</button>
			</div>
		</div>
	);
};

export default ContactUs;
