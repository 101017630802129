import React, { useState } from "react";

import { useForm } from "react-hook-form";

const Form = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const [messageForm, setMessageForm] = useState("");
	const [messageStyle, setMessageStyle] = useState("");


	const onSubmit = async (data) => {
		await fetch("https://api.dev.acses.io/web/contact/source", {
			body: JSON.stringify({ source: "Demo", ...data }),
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
		}).then((res) => {
			if (res.status === 200) {
				console.log(res)
				setMessageStyle("text-[#33A620]")
				setMessageForm(
					"Votre message à bien été envoyé, nous vous recontacterons"
				);
			} else if (res.status === 500) {
				setMessageForm(
					"Une erreur est survenue, veuillez réessayer ultérieurement"
				);
			} else {
				setMessageForm(
					"Une erreur est survenue, veuillez réessayer ultérieurement"
				);
			}
		});
		reset()
	};
	return (
		<div className="shadowBoxForm lg:ml-auto md:bg-white md:p-[36px] lg:w-[456px] xl:w-[640px]">
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="flex flex-col gap-y-[16px] text-[16px] leading-[16px]"
			>
				<div className="grid lg:grid-cols-2 gap-[16px] ">
					<div>
						<input
							style={{
								backgroundImage:
									errors.lastName &&
									"url('assets/images/pictos/error-form.svg')",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "calc(100% - 18px)",
							}}
							className={`${
								errors.lastName
									? "bg-[#FCEFEF] rounded-[4px] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
									: "bg-[#F0F5FA] rounded-[4px] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
							} w-full text-[.74rem] md:text-[100%]`}
							{...register("lastName", {
								required: true,
								pattern: /^[A-Za-z]+$/i,
							})}
							placeholder="Nom*"
						/>
						{errors?.lastName?.type === "pattern" && (
							<p className="small-text text-[#EE2D2D]">
								Le nom ne peut contenir que des lettres
							</p>
						)}
						{errors?.lastName?.type === "required" && (
						<p className="small-text text-[#EE2D2D]">
								Veuillez indiquer votre nom
							</p>
						)}
					</div>
					<div>
						<input
							style={{
								backgroundImage:
									errors.firstname &&
									"url('assets/images/pictos/error-form.svg')",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "calc(100% - 18px)",
							}}
							className={`${
								errors.firstname
									? "bg-[#FCEFEF] rounded-[4px] px-[18px] py-[12px]  focus:outline-[#EE2D2D]"
									: "bg-[#F0F5FA] rounded-[4px] px-[18px] py-[12px]  focus:outline-[#2DBCEE]"
							} w-full text-[.74rem] md:text-[100%]`}
							{...register("firstName", {
								required: true,
								pattern: /^[A-Za-z]+$/i,
							})}
							placeholder="Prénom*"
						/>

						{errors?.firstname?.type === "pattern" && (
						<p className="small-text text-[#EE2D2D]">
								Le Prénom ne peut contenir que des lettres
							</p>
						)}

						{errors?.firstname?.type === "required" && (
							<p className="small-text text-[#EE2D2D]">
								Veuillez indiquer votre prénom
							</p>
						)}
					</div>

					<div>
						<input
							style={{
								backgroundImage:
									errors.email &&
									"url('assets/images/pictos/error-form.svg')",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "calc(100% - 18px)",
							}}
							className={`${
								errors.email
									? "bg-[#FCEFEF] rounded-[4px] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
									: "bg-[#F0F5FA] rounded-[4px] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
							} w-full text-[.74rem] md:text-[100%]`}
							{...register("email", {
								required: true,
								pattern:
									/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
							})}
							placeholder="Email*"
						/>
						{errors?.email?.type === "pattern" && (
							<p className="small-text text-[#EE2D2D]">
								adresse email saisie n'est pas conforme
							</p>
						)}
						{errors?.email?.type === "required" && (
							<p className="small-text text-[#EE2D2D]">
								Une addresse email doit être saisie
							</p>
						)}
					</div>
					<div>
						<input
							style={{
								backgroundImage:
									errors.phone &&
									"url('assets/images/pictos/error-form.svg')",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "calc(100% - 18px)",
							}}
							className={`${
								errors.phone
									? "bg-[#FCEFEF] rounded-[4px] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
									: "bg-[#F0F5FA] rounded-[4px] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
							} w-full text-[.74rem] md:text-[100%]`}
							{...register("phone", {
								required: true,
								pattern: /[0-9]/,
							})}
							placeholder="Téléphone*"
						/>
						{errors?.phone?.type === "pattern" && (
							<p className="small-text text-[#EE2D2D]">
								Le numéro de téléphone ne peut contenir que
								des chiffres
							</p>
						)}
						{errors?.phone?.type === "required" && (
							<p className="small-text text-[#EE2D2D]">
								Un numero de téléphone doit être saisi
							</p>
						)}
					</div>
				</div>
				<div>
					<input
						style={{
							backgroundImage:
								errors.enterpriseName &&
								"url('assets/images/pictos/error-form.svg')",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "calc(100% - 18px)",
						}}
						className={`${
							errors.enterpriseName
								? "bg-[#FCEFEF] rounded-[4px] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
								: "bg-[#F0F5FA] rounded-[4px] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
						} w-full text-[.74rem] md:text-[100%]`}
						{...register("enterpriseName", {
							required: true,
						})}
						placeholder="Votre société*"
					/>
					{errors?.enterpriseName?.type === "pattern" && (
						<p className="small-text text-[#EE2D2D]">
							saisie n'est pas conforme
						</p>
					)}
					{errors?.enterpriseName?.type === "required" && (
						<p className="small-text text-[#EE2D2D]">
							Veuillez indiquer le nom de votre société
						</p>
					)}
				</div>
				<div>
					<textarea
						style={{
							backgroundImage:
								errors.message &&
								"url('assets/images/pictos/error-form.svg')",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "calc(100% - 18px)",
						}}
						className={`${
							errors.message
								? "bg-[#FCEFEF] rounded-[4px] px-[18px] py-[12px] focus:outline-[#EE2D2D]"
								: "bg-[#F0F5FA] rounded-[4px] px-[18px] py-[12px] focus:outline-[#2DBCEE]"
						} w-full text-[.74rem] md:text-[100%] h-[128px]`}
						{...register("message", {
							required: true,
						})}
						placeholder="Votre message*"
					/>
					{errors?.message?.type === "required" && (
						<p className="small-text text-[#EE2D2D]">
							Veuillez saisir votre message
						</p>
					)}
				</div>
				<div>
					<input
						style={{
							backgroundImage:
								errors.howYouFindUs &&
								"url('assets/images/pictos/error-form.svg')",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "calc(100% - 18px)",
						}}
						className={`${
							errors.howYouFindUs
								? "bg-[#FCEFEF] rounded-[4px] px-[18px] py-[12px] mb-[8px] focus:outline-[#EE2D2D]"
								: "bg-[#F0F5FA] rounded-[4px] px-[18px] py-[12px] mb-[8px] focus:outline-[#2DBCEE]"
						} w-full text-[.74rem] md:text-[100%]`}
						{...register("howYouFindUs", {
							required: true,
						})}
						placeholder="Comment avez-vous découvert Acses ? *"
					/>
					{errors?.howYouFindUs?.type === "required" && (
						<p className="small-text text-[#EE2D2D]">
							Ce champs est obligatoire
						</p>
					)}
				</div>
				<div className="flex items-start gap-x-[8px] mb-[20px] mt-[8px]">
					<input
						className={`${
							errors.newsletter
								? "bg-[#FCEFEF] rounded-[4px] px-[18px] py-[12px] mb-[8px] focus:outline-[#EE2D2D]"
								: "bg-[#F0F5FA] rounded-[4px] px-[18px] py-[12px] mb-[8px] focus:outline-[#2DBCEE]"
						} mt-[5px]`}
						{...register("newsletter")}
						type="checkbox"
					/>
					<span className={messageStyle + " mb-[36px] font-semibold leading-[24px]"}>{messageForm}</span>

					<p className="text-[0.8rem] text-[#163F59] leading-[24px]">
						En soumettant ce formulaire, j’accepte que mes
						informations soient utilisées exclusivement dans
						le cadre de ma demande et de la relation
						commerciale éthique et personnalisée qui
						pourrait en découler si je le souhaite.
					</p>
				</div>
				<input
					type="submit"
					className="flex justify-center items-center w-full py-[12px] pl-[16px] pr-[20px] text-white font-bold md:font-medium rounded-[4px] bg-[#FFA945]"
				/>
			</form>
		</div>
	);
};

export default Form;
