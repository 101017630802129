import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './typewriter.scss';

class Typewriter extends Component {
  constructor(props) {
    super(props)
    this.data = this.props.data;
    this.state = {
      index: 0,
      txt: '',
      isDeleting: false,
    }
    this.typeSpeed = this.props.typeSpeed || 2000;
  }
  
  componentDidMount() {
    this.tick();
  }
  tick() {
    const i = this.state.index % this.data.length;
    const fullTxt = this.data[i];
    if (this.state.isDeleting) {
      this.setState({ txt: fullTxt.substring(0, this.state.txt.length - 1) })
    } else {
      this.setState({ txt: fullTxt.substring(0, this.state.txt.length + 1) })
    }
    const that = this;
    let delta = 300 - Math.random() * 100;
    
    if(this.state.isDeleting) { delta /= 1.5 }
    if (!this.state.isDeleting && this.state.txt === fullTxt) {
      delta = this.props.typeSpeed;
      this.setState({isDeleting: true})
    } else if (this.state.isDeleting && this.state.txt === '') {
      this.setState({isDeleting: false, index: i + 1})
      delta = 2000;
    }
    
    setTimeout(function() {
      that.tick()
    }, delta);
  };
  
  render () {
    return (
      <span className="wrap" style={{color: "#2970B7"}}>
        {this.state.txt}
      </span>
    )
  }
}

Typewriter.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  typeSpeed: PropTypes.number,
};

Typewriter.defaultProps = {
  data: null,
  typeSpeed: 100
};

export default Typewriter;