import React from "react";
import { useNavigate } from "react-router-dom";

const Totem = () => {
	let navigate = useNavigate();
	return (
		<div className="relative">
			<div className="absolute left-[20%] -top-[30px] z-[20] mb-[20px] w-[180px] md:left-[5%] md:w-[263px] lg:-top-[70px] lg:w-[416px] xl:left-[7%]">
				<img
					className="w-full md:hidden "
					src="assets/images/solutions/totems.webp"
					alt=""
					width={664}
					height={770}
					loading="lazy"
				/>
				<img
					className="hidden w-full md:inline lg:hidden"
					src="assets/images/solutions/totems-tablette.webp"
					alt=""
					width={264}
					height={328}
					loading="lazy"
				/>
				<img
					className="hidden w-full lg:inline"
					src="assets/images/solutions/totems-desktop.webp"
					alt=""
					width={418}
					height={519}
					loading="lazy"
				/>
			</div>
			<div className="relative flex h-[436px] flex-col justify-end overflow-hidden rounded-[16px] bg-[#E7F3FA] p-[20px] md:h-[252px] md:flex-row md:gap-[81px] md:py-[36px] lg:h-[384px] lg:px-[80px] lg:py-[66px]">
				<div className="filterBlur absolute -bottom-[130px] left-[100px] z-[10] h-[270px] w-[270px] md:bottom-0 md:left-0 xl:opacity-[0.5]"></div>
				<div className="mr-[36px] md:w-[292px] lg:w-[418px] lg:pl-[70px] xl:w-[531px] xl:pl-0">
					<div className="md:flex md:flex-col">
						<h2 className="z-[50] font-semibold text-[#163F59]">
							Évitez l’accroche murale avec les totems Acses
						</h2>
					</div>
					<button
						onClick={() => navigate("/contact")}
						id="bouton-nsc-nous-contacter"
						className="relative z-[80] flex w-[185px]  items-center gap-[17px] rounded-[8px] bg-white p-[12px] text-left lg:mb-[67px] lg:h-[74px] lg:w-[241px] lg:gap-[20px] lg:p-[20px]"
					>
						<p className="rounded-[8px] bg-gradient-to-r from-[#2DBBED] to-[#2972B8] bg-clip-text font-bold text-transparent">
							Nous contacter
						</p>
						<img
							src="assets/images/pictos/arrow-rigth-gradient.svg"
							alt="totem à clés Acses"
							width={9}
							height={16}
							loading="lazy"
						/>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Totem;
