import React from "react";
import Hero from "../components/Heros";
// import Partners from "../../Home/components/Partners";
import Management from "../components/Management";
import KeyCabinet from "../components/KeyCabinet";
import UiManagement from "../components/UiManagement";
import NewsLetterForm from "../../Home/components/NewsLetterForm";
import Custom from "../components/Custom";
import KnowMore from "../components/KnowMore";
import {
	pHero,
	heroImgs,
	grayBlockImg,
	grayBlockP,
	managementContent,
} from "./automobileContentPage";
import GrayBlock from "../components/GrayBlock";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'

const Automobile = (props) => {
	const tagManagerArgs = {
		dataLayer: {
			events: "info",
			page_category_1: props.path,
			page_category_2: '{{page_category_2}}'
		},
		dataLayerName: 'PageDataLayer'
	}
	TagManager.dataLayer(tagManagerArgs)
	
	return (
		<div className="pt-[81px] lg:pt-[200px] xl:pt-[240px]">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Gestionnaires de flotte automobile : tracez vos clés !</title>
				<meta name="description" content="Vous manquez de visibilité sur les dépôts et les retraits de clés ? Découvrez nos casiers connectés et gérés à distance  >> cliquez-ici !"/>
				
			</Helmet>
			<section className="flex justify-center">
				<Hero
					bannerImg={heroImgs}
					nameSector="Gestionnaires de flotte automobile"
					title="Avec notre armoire à clés connectée, optimisez la gestion de votre flotte automobile"
					textContent={pHero}
				/>
			</section>

			{/* <section>
				<Partners />
			</section> */}
			<div className="flex justify-center mb-[60px] md:mb-[96px] lg:mb-[192px]">
				<div className="relative bg-[#F6F8FC] md:mx-[36px] lg:mx-[32px] xl:mx-0 py-[40px] lg:py-[96px] lg:px-[80px] mb-[96px] md:rounded-[24px] lg:flex justify-center w-full lg:max-w-[960px] xl:max-w-[1320px]">
					<GrayBlock
						sectionName="Gestion & suivi"
						title="Reprenez le contrôle de vos clés !"
						img={grayBlockImg}
						textContent={grayBlockP}
					/>
				</div>
			</div>
			<section className="flex justify-center">
				<Management
					blueSpan="Plateforme de gestion"
					title="Une technologie pensée pour le secteur de l’automobile"
					blockContent={managementContent}
				/>
			</section>
			<section className="relative mb-[80px] lg:mb-[128px] xl:mb-[192px] py-[60px] px-[36px] bg-[#163F59]">
				<KeyCabinet
					blueSpan="Gamme Acses"
					title="Nos armoires à clés sécurisées"
					buttonId="bouton-flotte-auto-obtenir-devis"
				/>
			</section>
			<section className="xl:flex xl:justify-center">
				<UiManagement buttonId="bouton-flotte-auto-reserver-une-demo" />
			</section>
			<section className="lg:flex lg:justify-center px-[36px] mb-[32px]">
				<Custom />
			</section>
			<section className="flex justify-center px-[36px] mb-[40px] md:mb-[48px] lg:mb-[60pxh] xl:mb-[96px]">
				<KnowMore
					buttonId="bouton-flotte-auto-contacter-un-commercial"
					buttonId2="lien-flotte-auto-options-personnalisation"
				/>
			</section>
			<div>
				<NewsLetterForm />
			</div>
		</div>
	);
};

export default Automobile;
