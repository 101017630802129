import React from "react";
import { useNavigate } from "react-router-dom";

const Tailored = () => {
	let navigate = useNavigate();
	return (
		<div className="justify-top relative flex h-[436px] flex-col gap-[21px] overflow-hidden rounded-[16px] bg-[#E7F3FA] p-[20px] md:h-[252px] md:flex-row md:justify-start md:gap-[36px] md:py-[36px] lg:h-[384px] lg:gap-0 lg:px-[80px] lg:py-[66px]">
			<div className="md:w-[292px] lg:w-[440px]">
				<div className="md:flex md:flex-col">
					<h2 className="z-[10] font-semibold text-[#163F59] ">
						Demandez-nous ce que vous ne trouvez pas
						ailleurs !
					</h2>
				</div>
				<button
					onClick={() => navigate("/contact")}
					id="bouton-nsc-discutons-en-ensemble"
					className="relative z-[10] flex items-center justify-between gap-[17px] rounded-[8px] bg-white p-[12px] text-left lg:mb-[67px] lg:h-[74px] lg:p-[20px]"
				>
					<p className="rounded-[8px] bg-gradient-to-r from-[#2DBBED] to-[#2972B8] bg-clip-text font-bold text-transparent ">
						Discutons-en ensemble{" "}
					</p>
					<img
						className="mr-[6px]"
						src="assets/images/pictos/arrow-rigth-gradient.svg"
						alt=""
						width={9}
						height={16}
						loading="lazy"
					/>
				</button>
			</div>
			<div className="z-[10] -mb-[100px] -ml-[20px] w-[285px] md:ml-0 md:-mr-[160px] md:w-[374px] lg:relative lg:-top-[50px] lg:w-[430px] xl:-top-[30px] xl:-right-[240px] xl:w-[488px]">
				<img
					className="w-full drop-shadow-md lg:hidden"
					src="assets/images/solutions/locker-tailored.webp"
					alt="consigne personnalisée et sécurisée"
					width={328}
					height={357}
					loading="lazy"
				/>
				<img
					className="hidden w-full drop-shadow-md lg:inline xl:hidden"
					src="assets/images/solutions/tailored-desktop.webp"
					alt="consigne personnalisée et sécurisée"
					width={409}
					height={357}
					loading="lazy"
				/>

				<img
					className="hidden w-full xl:inline"
					src="assets/images/solutions/tailored-desktop-large.webp"
					alt="consigne personnalisée et sécurisée"
					width={488}
					height={427}
					loading="lazy"
				/>
			</div>
			<div className="filterBlur absolute  -bottom-[130px] z-[1] h-[270px] w-[270px] md:bottom-0 md:right-0 lg:-top-[50px]"></div>
		</div>
	);
};

export default Tailored;
