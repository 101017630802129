import React, { useRef } from "react";
import Genesis from "./components/Genesis";
import Hero from "./components/Hero";
import Story from "./components/Story";
import Timeline from "./components/Timeline";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import Button from "../../sharedComponents/Button";
import { useNavigate } from "react-router-dom";

const OurStory = () => {
	const ref = useRef(null);
	const navigate = useNavigate();
	const desktopScreen = useMediaQuery({
		query: "(min-width:1024px)",
	});
	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Acses - Contrôle d'accès & consignes sécurisées{" "}
				</title>
				<meta
					name="description"
					content="Start-up spécialisée dans le contrôle d’accès et les casiers connectés. Découvrez comment nous pouvons aider votre entreprise à gérer ses clés !"
				/>
			</Helmet>
			<div className="pt-[64px] lg:pt-[210px]">
				<Hero />
			</div>
			<div
				style={{
					backgroundImage: desktopScreen
						? "url('assets/images/ourStory/background.png')"
						: "",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "right center",
					backgroundSize: "40% 100%",
				}}
				className="mb-[67px] flex justify-center bg-[#163F59] p-[36px] md:pt-[60px] md:pb-[178px] lg:px-[38px] lg:pt-[80px] xl:pt-[128px]"
			>
				<Story />
			</div>
			<div className="mb-[128px] flex justify-center px-[36px]">
				<Genesis />
			</div>
			<div className="px-[36px] lg:pr-0">
				<Timeline getRef={ref} />
			</div>

			<div className="mb-[60px] flex flex-col items-center px-[36px] md:mb-[80px] lg:mb-[96px]">
				<h2 className="my-[36px] md:mt-[24px] md:mb-[49px] text-center">
					Rejoignez l’aventure Acses
				</h2>
				<div className="lightBoxShadow relative overflow-hidden rounded-[16px] p-[16px] md:h-[255px] md:w-full md:p-[48px] lg:p-[40px] xl:h-[256px] lg:max-w-[960px] xl:max-w-[1320px]">
					<h2 className="">Vous avez un projet ?</h2>
					<Button
						text="Nous contacter"
						action={() => navigate("/contact")}
						addStyle="w-full md:max-w-[181px]"
					/>

					<img
						className="absolute right-0 top-0 -z-[1] hidden h-[256px] md:flex"
						src="assets/images/ourStory/man-with-phone.png"
						alt="Un homme avec une cravate"
						loading="lazy"
					/>
				</div>
			</div>
		</div>
	);
};

export default OurStory;
