import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../sharedComponents/Button";

const AdoptSolution = () => {
	let navigate = useNavigate();

	return (
		<div className="flex justify-center">
			{" "}
			<div className="lg:max-w-[976px] xl:max-w-[1320px]">
				<div className="mx-[36px] md:flex md:flex-col md:items-center md:text-center">
					<p className="subtitle mb-[8px] font-semibold text-[#3798D4]">
						Solution connectée
					</p>
					<h2 className="font-semibold text-[#163F59] md:w-8/12 lg:mb-[36px] lg:w-[706px] ">
						Adoptez un nouveau mode de remise de clés
					</h2>
				</div>
				<div className="lg:flex lg:flex-col-reverse">
					<div className="mb-[20px] w-full overflow-hidden pl-[36px] md:hidden">
						<img
							className="w-full"
							src="assets/images/home/solutions-mobile.png"
							alt="Logiciel de gestion des clés "
							width={346}
							height={323}
							loading="lazy"

						/>
					</div>
					<div className="mb-[20px] hidden w-full pl-[36px] md:flex xl:hidden">
						<img
							className="w-full"
							src="assets/images/home/solutions-tablette.webp"
							alt="Logiciel de gestion des clés"
							width={1482}
							height={1084}
							loading="lazy"

						/>
					</div>
					<div className="mb-[20px] hidden w-full pl-[36px] xl:flex">
						<img
							className="w-full"
							src="assets/images/home/solutions-desktop.webp"
							alt="Logiciel de gestion des clés "
							width={1150}
							height={835}
							loading="lazy"

						/>
					</div>
					<div>
						<div className="mb-[24px] flex w-full justify-center px-[36px] lg:mb-[40px] lg:text-center">
							<p className="font-medium text-[#506F83] md:text-center lg:w-[953px] xl:w-[880px]">
								Des clés en circulation peuvent vite devenir
								un véritable casse tête et aboutir à des
								failles de sécurité, de contrôle ou de
								logistique dans une entreprise. Acses
								propose une solution simple, sûre, et
								efficace pour vous soulager dans vos
								activités quotidiennes et vous concentrer
								sur l'essentiel : votre coeur de métier.
							</p>
						</div>
						<div className="flex w-full px-[36px] md:justify-center">
							<div className="hidden xl:flex">
								<Button
									text="Voir la démo"
									idBtn="bouton-navbar-demande-une-demo-section-solution-connectee"
									action={() => navigate("/demande-demo")}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdoptSolution;
