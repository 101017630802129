import React from "react";

const Hero = () => {
  return (
    <div className="md:w-[690px] lg:w-[458px] xl:w-[526px]">
      {" "}
      <div className="flex flex-col justify-center xl:mx-0 lg:mb-[60px] xl:mb-[96px] text-center">
        <div className="flex justify-center">
          <h1 className="text-left text-[#163F59]">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#2DBBED] to-[#2972B8]">
              Gérez vos clés
            </span>{" "}
            à distance en toute sécurité !
          </h1>
        </div>
        <div className="flex justify-start">
          <p className="font-medium text-left text-[#163F59] xl:w-[420px]">
            Demandez une démonstration et découvrez comment Acses peut faciliter
            le quotidien de vos équipes et de vos clients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
