import React, { useEffect, useState } from "react";
import clsx from "clsx";
import "./MobileNav.scss";
import { Link } from "react-router-dom";

const MobileNav = () => {
	const [menu, setMenu] = useState(false);
	const [scroll, setScroll] = useState(false);
	const [solution, setSolution] = useState(false);
	const [secteur, setSecteur] = useState(false);
	const [about, setAbout] = useState(false);
	// const links = [
	// 	{
	// 		icon: "search.svg",
	// 		title: "Rechercher",
	// 	},
	// 	{
	// 		icon: "help-center.png",
	// 		title: "Centre d'aide",
	// 	},
	// ];

	useEffect(() => {
		scrollOn();
		window.addEventListener("scroll", scrollOn);
	});
	useEffect(() => {
		if (menu) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}
	});

	const menuStatus = () => {
		return setMenu((prevState) => !prevState);
	};
	const scrollOn = () => {
		if (window.scrollY > 0) {
			return setScroll(true);
		}
		return setScroll(false);
	};
	const openDropdown = (link) => {
		switch (link) {
			case "solution":
				setSecteur((prevState) => false);
				setAbout((prevState) => false);
				return setSolution((prevState) => !prevState);
			case "secteur":
				setSolution((prevState) => false);
				setAbout((prevState) => false);
				return setSecteur((prevState) => !prevState);
			case "about":
				setSecteur((prevState) => false);
				setSolution((prevState) => false);
				return setAbout((prevState) => !prevState);
			default:
				return null;
		}
	};
	return (
		<>
			<header
				id="header"
				className={clsx(
					"px-36 py-24",
					scroll && "bg-nav p-shadow-1"
				)}
			>
				<div className="logo">
					<Link to="/">
						<img
							src="/assets/images/logos/logo_acses_with_word_blue.svg"
							alt="logo de l'entreprise ACSES avec le text en fond bleu"
							width={120}
							height={35}
						/>
					</Link>
				</div>
				<nav className="nav">
					<button
						onClick={menuStatus}
						className={clsx(
							"toggle-menu",
							menu && "active"
						)}
					>
						<span></span>
					</button>
				</nav>
			</header>
			<div
				id="menu"
				className={clsx("flow", menu && "open")}
			>
				<nav className="main-nav">
					<ul>
						<li
							className="item has-submenu"
							onClick={() => openDropdown("solution")}
						>
							<Link
								to="/solutions-connectees"
								className="flex justify-start items-center w-full"
							>
								Nos solutions
								<span className="pl-6">
									<img
										src="/assets/images/pictos/arrow.svg"
										alt=""
										className={clsx(
											"arrow",
											solution
												? "rotate-reverse-arrow"
												: "rotate-arrow"
										)}
										loading="lazy"
									/>
								</span>
							</Link>
							<ul
								className={clsx(
									solution
										? "submenu-active dropdown_menu"
										: "submenu"
								)}
							>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/comment-ca-marche">
										Comment ça marche?
									</Link>
								</li>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/solutions-connectees">
										Nos solutions connectées
									</Link>
								</li>
							</ul>
						</li>
						<li
							className="item has-submenu"
							onClick={() => openDropdown("secteur")}
						>
							<Link
								to="#"
								className="flex justify-start items-center w-full"
							>
								Vos secteurs d'activités
								<span className="pl-6">
									<img
										src="/assets/images/pictos/arrow.svg"
										alt="flèche animé au"
										className={clsx(
											"arrow",
											secteur
												? "rotate-reverse-arrow"
												: "rotate-arrow"
										)}
									/>
								</span>
							</Link>
							<ul
								className={clsx(
									secteur
										? "submenu-active dropdown_menu"
										: "submenu"
								)}
							>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/hotellerie">Hôtellerie</Link>
								</li>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/airbnb-locations-courte-duree">
										Airbnb & location courte durée
									</Link>
								</li>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/immobilier">Immobilier</Link>
								</li>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/service-aux-entreprises">
										Services aux entreprises
									</Link>
								</li>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/gestionnaires-flotte-automobile">
										Concessionnaires automobiles
									</Link>
								</li>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/concessionaires-automobiles">
										Gestionnaires de flotte automobile
									</Link>
								</li>
							</ul>
						</li>

						<li
							className="item has-submenu"
							onClick={() => openDropdown("about")}
						>
							<Link
								to="#"
								className="flex justify-start items-center w-full"
							>
								À propos
								<span className="pl-6">
									<img
										src="/assets/images/pictos/arrow.svg"
										alt=""
										className={clsx(
											"arrow",
											about
												? "rotate-reverse-arrow"
												: "rotate-arrow"
										)}
										loading="lazy"
									/>
								</span>
							</Link>
							<ul
								className={clsx(
									about
										? "submenu-active dropdown_menu"
										: "submenu"
								)}
							>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/notre-histoire">
										Notre histoire
									</Link>
								</li>
								<li
									className="subitem"
									onClick={menuStatus}
								>
									<Link to="/blog">
										Blog
									</Link>
								</li>
							</ul>
						</li>
						<li onClick={menuStatus}>
							<Link to="/contact">
								Contact
							</Link>
						</li>
						<li onClick={menuStatus}>
							<Link to="/consigne-personnalisee">
							Personnaliser ma consigne
							</Link>
						</li>
					</ul>
				</nav>
				<div className="menu-footer">
					<nav className="footer-nav">
						<div className="py-16">
							<button className="btn-text primary-btn w-full">
								Demander une démo
							</button>
						</div>
						{/* <ul
							role="list"
							className="divide-y-2 divide-gray-100"
						>
							{links.map((link) => (
								<li
									key={link.title}
									className="flex items-center py-8"
								>
									<img
										className="h-10 w-10 rounded-full"
										src={`/assets/images/pictos/${link.icon}`}
										alt=""
									/>
									<p className=" ml-4 text-sm font-medium text-gray-900">
										{link.title}
									</p>
								</li>
							))}
						</ul> */}
					</nav>
				</div>
			</div>
		</>
	);
};

export default MobileNav;
