import { v4 as uuidv4 } from "uuid";

const socialNetworks = [
	{
		id: uuidv4(),
		style: "w-[9.72px] h-[20px]",
		url: "assets/images/logos/social-networks/facebook-dark.svg",
		link:""
	},
	{
		id: uuidv4(),
		style: "w-[20px] h-[20px]",
		url: "assets/images/logos/social-networks/linkedin-dark.svg",
		link:""
	},
	{
		id: uuidv4(),
		style: "w-[20px] h-[20px]",
		url: "assets/images/logos/social-networks/instagram-dark.svg",
		link:""
	},
	{
		id: uuidv4(),
		style: "w-[24.6px] h-[20px]",
		url: "assets/images/logos/social-networks/twitter-dark.svg",
		link:""
	},
	{
		id: uuidv4(),
		style: "w-[29.98px] h-[20px]",
		url: "assets/images/logos/social-networks/youtube-dark.svg",
		link:""
	},
];

export default socialNetworks;
