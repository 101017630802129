import React, { useEffect, useState } from "react";
import "./Blog.scss";
import hygraph from "../../@backend/hygraph";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import NewsLettersBlog from "./sharedComponents/NewsLettersBlog";
import { useMediaQuery } from "react-responsive";
import RecentPosts from "./components/RecentPosts";
import PostsList from "./components/PostsList";
import ContactInfos from "./components/ContactInfos";
dayjs.locale("fr");
const Blog = () => {
	const [recentPosts, setRecentPosts] = useState();
	const [postsList, setPostsList] = useState();
	const [firstCursor, setFirstCursor] = useState();
	const [pageInfo, setPageInfo] = useState();

	const [tagFilter, setTagFilter] = useState([
		"Etude de cas",
		"Actualités",
		"Evenements",
	]);

	const [activeFilter, setActiveFilter] = useState({
		caseStudy: false,
		news: false,
		events: false,
		lastArticles: true,
	});

	const largeScreen = useMediaQuery({
		query: "(min-width:1376px)",
	});
	const mediumScreen = useMediaQuery({
		query: "(min-width:1024px)",
	});
	const tabletteScreen = useMediaQuery({
		query: "(min-width:768px)",
	});

	const displayBackgroundImage = () => {
		return largeScreen
			? "url('/assets/images/blog/background-large.svg')"
			: mediumScreen
			? "url('/assets/images/blog/background-medium.svg')"
			: tabletteScreen &&
			  "url('/assets/images/blog/background-tablette.svg')";
	};
	const handlePrevPage = async (cursor) => {
		const QUERY = `
		{
			posts(last:2 before:"${cursor}" orderBy: createdAt_DESC  where:{ tags_contains_some: ${JSON.stringify(
			tagFilter
		)} }) {
			  id
			  title
			  tags
			  slug
			  date
			  publishedAt
			  createdAt
			  coverImage {
				id
				fileName
				url
			  }
			  altText
			  excerpt
			  author {
				id
				name
				picture {
				  fileName
				  url
				}
			  }
			}
			postsConnection(last:2 before:"${cursor}",orderBy: createdAt_DESC where:{ tags_contains_some: ${JSON.stringify(
			tagFilter
		)}}){
			  pageInfo{
				hasNextPage
				hasPreviousPage
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			  }
		  }
		  }
	  `;
		const { posts, postsConnection } =
			await hygraph.request(QUERY);
		setPostsList(posts);
		setPageInfo(postsConnection.pageInfo);

	};
	const handleNextPage = async (cursor) => {
		const QUERY = `
		{
		  posts(first:2,after:"${cursor}",orderBy: createdAt_DESC where:{ tags_contains_some: ${JSON.stringify(
			tagFilter
		)}}) {
			
			id
			title
			tags
			slug
			date
			publishedAt
			createdAt
			coverImage {
			  id
			  fileName
			  url
			}
			altText
			excerpt
			author {
			  id
			  name
			  picture {
				fileName
				url
			  }
			}
		  }
		  postsConnection(first:2,after:"${cursor}",orderBy: createdAt_DESC where:{ tags_contains_some: ${JSON.stringify(
			tagFilter
		)}}){
			pageInfo{
			  hasNextPage
			  hasPreviousPage
			  hasNextPage
			  hasPreviousPage
			  startCursor
			  endCursor
			}
		}
		}
	  `;
		const { posts, postsConnection } =
			await hygraph.request(QUERY);
		setPostsList(posts);
		setPageInfo(postsConnection.pageInfo);
	
	};

	function changeFilter(filterValue) {
		setActiveFilter({
			[filterValue]: true,
			[!filterValue]: false,
		});
		const filterName = {
			caseStudy: ["Etude de cas"],
			news: ["Actualités"],
			events: ["Evenements"],
			lastArticles: [
				"Etude de cas",
				"Actualités",
				"Evenements",
			],
		};
		setTagFilter(filterName[filterValue]);
	}

	useEffect(() => {
		const fetchRecentsPosts = async () => {
			const QUERY = `
        {
          posts(first: 3, orderBy: createdAt_DESC  where:{ tags_contains_some: ${JSON.stringify(
						tagFilter
					)}}
) {
            id
            title
            tags
            slug
            date
            publishedAt
            createdAt
            coverImage {
              id
              fileName
              url
            }
            altText
            excerpt
            author {
              id
              name
              picture {
                fileName
                url
              }
            }
          }
        }
      `;
			const { posts } = await hygraph.request(QUERY);
			setRecentPosts(posts);
			posts && setFirstCursor(posts[posts.length - 1].id);
		};
		fetchRecentsPosts();
	}, [tagFilter]);

	useEffect(() => {
		const fetchAllPosts = async () => {
			const QUERY = `
			{
				posts(first:2 after: "${firstCursor}" orderBy: createdAt_DESC where:{ tags_contains_some: ${JSON.stringify(
				tagFilter
			)}}) {
				  id
				  title
				  tags
				  slug
				  date
				  publishedAt
				  createdAt
				  coverImage {
					id
					fileName
					url
				  }
				  altText
				  excerpt
				  author {
					id
					name
					picture {
					  fileName
					  url
					}
				  }
				}
				postsConnection(first:2 after:"${firstCursor}" orderBy: createdAt_DESC where:{ tags_contains_some: ${JSON.stringify(
				tagFilter
			)}} ){
				  pageInfo{
					hasNextPage
					hasPreviousPage
					startCursor
					endCursor
				  }
			  }
			  }
		  `;
			const { posts, postsConnection } =
				await hygraph.request(QUERY);
			posts && setPostsList(posts);
			postsConnection &&
				setPageInfo(postsConnection.pageInfo);
		};

		fetchAllPosts();
	}, [tagFilter, firstCursor]);

	return (
		<main
			style={{
				backgroundImage: displayBackgroundImage(),
				backgroundRepeat: "no-repeat",
				backgroundSize: "100%",
				backgroundPosition: "0 80vh",
			}}
			className="pt-[92px] lg:mt-20 lg:flex lg:flex-col lg:items-center"
		>
			<section className="flex w-full flex-col items-center px-[36px] lg:max-w-[960px] lg:p-0 xl:max-w-[1320px]">
				<div></div>
				<div className="grid w-full grid-cols-1 items-stretch justify-center lg:grid-cols-4">
					<div className="col-span-1 lg:col-span-1">
						<h1 className="m-0">Blog</h1>
					</div>
					<div className=" col-span-1 -mx-9 lg:col-span-3 lg:mr-0 lg:flex lg:items-center lg:justify-end xl:mx-0">
						<div className="px-9 lg:px-0">
							<p className="white-space-nowrap small-text">
								Trier par
							</p>
						</div>
						<div className="pt-2">
							<ul className="sort-by flex min-h-[45px] gap-2 overflow-y-hidden overflow-x-scroll px-9 lg:min-h-[55px] lg:pr-0">
								<li>
									<button
										onClick={() =>
											changeFilter("lastArticles")
										}
										className={`p-shadow-3 small-text whitespace-nowrap rounded-full py-2 px-20 ${
											activeFilter?.lastArticles &&
											"bg-blue-900 text-white"
										}`}
									>
										Derniers articles
									</button>
								</li>
								<li>
									<button
										onClick={() =>
											changeFilter("caseStudy")
										}
										className={`p-shadow-3 small-text whitespace-nowrap rounded-full py-2 px-20 ${
											activeFilter?.caseStudy &&
											"bg-blue-900 text-white"
										}`}
									>
										Etude de cas
									</button>
								</li>
								<li>
									<button
										onClick={() => changeFilter("news")}
										className={`p-shadow-3 small-text whitespace-nowrap rounded-full py-2 px-20 ${
											activeFilter?.news &&
											"bg-blue-900 text-white"
										}`}
									>
										Actualités
									</button>
								</li>
								<li>
									<button
										onClick={() => changeFilter("events")}
										className={`p-shadow-3 small-text whitespace-nowrap rounded-full py-2 px-20 ${
											activeFilter?.events &&
											"bg-blue-900 text-white"
										}`}
									>
										événements
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="my-6 w-full border-t border-t-sky-200"></div>
				<div className="grid w-full grid-cols-1 place-content-center place-items-center gap-[40px] md:grid-cols-3 md:place-items-start lg:grid-cols-4">
					<RecentPosts posts={recentPosts} />
				</div>
			</section>
			<section className="w-full px-[36px] lg:max-w-[960px] lg:p-0 xl:max-w-[1320px]">
				<div className="grid w-full grid-cols-1 place-content-center place-items-center gap-[40px] md:grid-cols-3 md:place-items-start lg:grid-cols-4">
					<PostsList postList={postsList} />
				</div>

				<div className="my-6 w-full border-t border-t-sky-200"></div>
				<div className="flex justify-end gap-[16px]">
					<div className="flex justify-end">
						<button
							className={`whitespace-nowrap ${
								pageInfo?.hasPreviousPage
									? "text-[#3798D4]"
									: "text-[#c3cdd2]"
							}`}
							disabled={!pageInfo?.hasPreviousPage}
							onClick={() => handlePrevPage(pageInfo?.startCursor)}
						>
							Page Précedente
						</button>
					</div>
					<div className="flex justify-end">
						<button
							className={`whitespace-nowrap ${
								pageInfo?.hasNextPage
									? "text-[#3798D4]"
									: "text-[#c3cdd2]"
							}`}
							disabled={!pageInfo?.hasNextPage}
							onClick={() => handleNextPage(pageInfo?.endCursor)}
						>
							Page suivante
						</button>
					</div>
				</div>
			</section>

			<NewsLettersBlog />
			<ContactInfos />
		</main>
	);
};

export default Blog;
