import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useMediaQuery } from "react-responsive";
import BlueLine from "./BlueLine";
import { motion } from "framer-motion";

const Timeline = ({getRef}) => {
	const desktopSize = useMediaQuery({
		query: "(min-width:1024px)",
	});

	const timelineEvents = [
		{
			imgSrc: "assets/images/ourStory/R&D.webp",
			alt: "recherche et développement en contrôle d'accès",
			date: "Novembre 2019",
			text: "R&D Nouveaux besoins clients et technologie en développement",
			rowReverse: true,
		},
		{
			imgSrc: "assets/images/ourStory/midas.webp",
			alt: "casiers",
			date: "Mars 2020",
			text: "Commercialisation de notre solution avec interface de gestion à distance",
		},
		{
			imgSrc: "assets/images/ourStory/interface.webp",
			alt: "",
			date: "Juin 2020",
			text: "Développement des fonctionnalités clients",
			rowReverse: true,
		},
		{
			imgSrc: "assets/images/ourStory/tech-event.webp",
			alt: "",
			date: "Avril 2021",
			text: "Participation aux événements Tech",
		},
		{
			imgSrc: "assets/images/ourStory/blonde.webp",
			alt: "",
			date: "Octobre 2021",
			text: "Partenariats Grands Comptes",
			rowReverse: true,
		},
		{
			imgSrc: "assets/images/ourStory/cedric-ratp.webp",
			alt: "",
			date: "Novembre 2021",
			text: "Développement des partenariats clients",
		},
		{
			imgSrc: "assets/images/ourStory/west-indus.webp",
			alt: "",
			date: "Février 2022",
			text: "Industrialisation de la solution et déploiement à l’international",
			rowReverse: true,
		},
		{
			imgSrc: "assets/images/ourStory/shahab.webp",
			alt: "",
			date: "Juin 2022",
			text: "Innovations technologiques pour accompagner les besoins clients",
		},
	];

	const cardVariants = (reverse) => ({
		offscreen: {
			x: reverse ? 250 : -250,
			opacity: 0,
		},
		onscreen: {
			x: 0,
			opacity: 1,
			transition: {
				type: "spring",
				bounce: 0.4,
				duration: 0.8,
			},
		},
	});

	return (
		<div className="relative pb-[148px]">
			<BlueLine getRef={getRef}/>
			{/* <div className="timelineGradient absolute left-[50%] h-full w-[4px] bg-gradient-to-t"></div> */}

			<div className="flex flex-col items-center justify-center">
				<p className="subtitle mb-[8px] font-semibold text-[#3798D4] lg:text-center ">
					Timeline
				</p>
				<h2 className="mb-[120px] md:mb-[99px] lg:mb-[140px] lg:text-center">
					Les dates clés
				</h2>
			</div>

			<div className=" relative mb-[36px] w-full p-[10px]">
				<div className="relative flex flex-col items-center gap-[52px]">
					{timelineEvents.map((event) => (
						<motion.div
							key={uuidv4()}
							className={`flex ${
								event.rowReverse
									? "justify-end"
									: "justify-start"
							} lg:w-full lg:max-w-[900px] `}
							initial="offscreen"
							whileInView="onscreen"
							variants={cardVariants(event.rowReverse)}
							viewport={{ once: true, amount: 0.8 }}
						>
							<div
								key={uuidv4()}
								className={`${
									desktopSize
										? event.rowReverse
											? "tinyShadowStoryL"
											: "tinyShadowStoryR"
										: "tinyShadow"
								} z-10 flex w-[288px] flex-col items-center bg-white px-[32px] py-[16px] text-center lg:h-[339px] lg:w-[368px] `}
							>
								<h3 className="mb-[8px]">{event.date}</h3>
								<p className="mb-[16px] text-[.8rem] font-normal text-[#506F83]">
									{event.text}
								</p>
								<div>
									<img
										className="lg:w-[306px]"
										src={event.imgSrc}
										alt={event.alt}
										loading="lazy"
									/>
								</div>
							</div>
						</motion.div>
					))}
					{/* <div
        className="hidden lg:flex absolute w-full  bg-gradient-to-l  from-[#2DBBED] via-[#2972B8
] h-[4px]"
      ></div> */}
				</div>
			</div>
			{/* <div className="flex w-full justify-center pt-[300px]">
				<img
					className="w-[52px]"
					src="assets/images/logos/logo_acses.webp"
					alt="logo Acses"
					loading="lazy"
				/>
			</div> */}
		</div>
	);
};

export default Timeline;
