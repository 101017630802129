import React from "react";

const Button = ({
	idBtn,
	text,
	picto,
	action,
	reverseStyle,
	addStyle,
}) => {
	const reverseClassProperties =
		"bg-white text-[#FFA945] lg:text-[.8rem] font-bold border-[2px] py-[10px] lg:py-[5px]  px-[14px] rounded-[8px]";

	return (
		<button
			id={idBtn}
			onClick={() => {
				action && action();
			}}
			className={`${
				reverseStyle
					? reverseClassProperties
					: "primary-btn"
			} flex items-center justify-center ${addStyle}`}
		>
			{text}
			{picto && (
				<img
					className="ml-[10px] lg:ml-[20px] lg:w-[15px]"
					src={picto}
					alt="picto"
				/>
			)}
		</button>
	);
};

export default Button;
